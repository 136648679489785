import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";

import CloseIcon from "@material-ui/icons/Close";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { paymentTermsDropdown } from "../../../../static/dropdowns";
import PriceListDialog from "./PriceListDialog";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const FormVendor = ({ isAdd, open, vendorId, handleClose, handleRefresh }) => {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState("");
  const [picName, setPicName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [paymentTerm, setPaymentTerm] = useState("");
  const [carNumbers, setCarNumbers] = useState("");
  const [buyPriceCsv, setBuyPriceCsv] = useState(null);
  const [sellPriceCsv, setSellPriceCsv] = useState(null);
  const [savedBuyPriceFileName, setSavedBuyPriceFileName] = useState("");
  const [savedSellPriceFileName, setSavedSellPriceFileName] = useState("");
  const [openPriceList, setOpenPriceList] = useState({
    status: false,
    type: null,
  });
  const [fieldsValid, setFieldsValid] = useState({
    companyName: true,
    address: true,
    phone: true,
    paymentTerm: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const handleOpenPriceList = (type) => {
    setOpenPriceList({ status: true, type });
  };

  const handleClosePriceList = () => {
    setOpenPriceList({ status: false, type: null });
  };

  const onBuyFileChange = (event) => {
    console.log(event.target.files);
    setBuyPriceCsv(event.target.files[0]);
  };

  const onSellFileChange = (event) => {
    console.log(event.target.files);
    setSellPriceCsv(event.target.files[0]);
  };

  const api_getVendorDetail = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/vendors/" + vendorId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setCompanyName(res.data.companyName);
        setPicName(res.data.picName);
        setAddress(res.data.address);
        setPhone(res.data.phone);
        setPaymentTerm(res.data.paymentTerm);
        setCarNumbers(res.data.carNumbers);
        setSavedBuyPriceFileName(res.data.buyPriceFileName);
        setSavedSellPriceFileName(res.data.sellPriceFileName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_createVendor = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    // newData.append("salesDate", salesDate.toISOString());
    newData.append("companyName", companyName);
    newData.append("picName", picName);
    newData.append("address", address);
    newData.append("phone", phone);
    newData.append("paymentTerm", paymentTerm);
    newData.append("carNumbers", carNumbers);

    // hit the API
    axios
      .post(process.env.REACT_APP_MBOXBE_URI + "/vendors", newData, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Informasi vendor berhasil ditambahkan",
        });
        setCompanyName("");
        setPicName("");
        setAddress("");
        setPhone("");
        setPaymentTerm("");
        setCarNumbers("");

        handleRefresh(res.data.vendors);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_updateVendor = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("companyName", companyName);
    newData.append("picName", picName);
    newData.append("address", address);
    newData.append("phone", phone);
    newData.append("paymentTerm", paymentTerm);
    newData.append("carNumbers", carNumbers);
    if (buyPriceCsv)
      newData.append("buyPriceList", buyPriceCsv, buyPriceCsv.name);
    if (sellPriceCsv)
      newData.append("sellPriceList", sellPriceCsv, sellPriceCsv.name);

    // hit the API
    axios
      .put(process.env.REACT_APP_MBOXBE_URI + "/vendors/" + vendorId, newData, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Perubahan informasi vendor berhasil disimpan",
        });
        setCompanyName("");
        setPicName("");
        setAddress("");
        setPhone("");
        setPaymentTerm("");
        setCarNumbers("");
        setBuyPriceCsv(null);
        setSellPriceCsv(null);

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      companyName: companyName !== "",
      address: address !== "",
      phone: phone !== "",
      paymentTerm: paymentTerm !== "",
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    if (!isAdd && vendorId) api_getVendorDetail();
  }, [vendorId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={isAdd ? "sm" : "sm"}
        fullWidth
        // fullScreen={!isAdd}
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {isAdd ? "Tambah Vendor Baru" : "Edit Vendor"}
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Typography className={classes.formSubsection}>
            Informasi vendor
          </Typography>
          <TextField
            variant="outlined"
            margin="dense"
            id="companyName"
            label="Nama perusahaan vendor"
            value={companyName}
            fullWidth
            onChange={(e) => setCompanyName(e.target.value)}
            required
            error={!fieldsValid.companyName}
            helperText={
              !fieldsValid.companyName ? "Field ini tidak boleh kosong" : null
            }
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="picName"
            label="Nama PIC vendor"
            value={picName}
            fullWidth
            onChange={(e) => setPicName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="address"
            label="Alamat vendor"
            value={address}
            fullWidth
            onChange={(e) => setAddress(e.target.value)}
            required
            error={!fieldsValid.address}
            helperText={
              !fieldsValid.address ? "Field ini tidak boleh kosong" : null
            }
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="phone"
            label="No. telp vendor"
            value={phone}
            fullWidth
            onChange={(e) => setPhone(e.target.value)}
            required
            error={!fieldsValid.phone}
            helperText={
              !fieldsValid.phone ? "Field ini tidak boleh kosong" : null
            }
          />
          <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Term pembayaran"
            id="paymentTerm"
            value={paymentTerm}
            onChange={(e) => setPaymentTerm(e.target.value)}
            required
            error={!fieldsValid.paymentTerm}
            helperText={
              !fieldsValid.paymentTerm ? "Field ini tidak boleh kosong" : null
            }
          >
            {paymentTermsDropdown.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            margin="dense"
            id="carNumbers"
            label="No. kendaraan vendor"
            helperText="Isi nomor kendaraan dengan pemisah tanda koma (,)"
            value={carNumbers}
            fullWidth
            onChange={(e) => setCarNumbers(e.target.value)}
          />
          {!isAdd ? (
            <div>
              <Typography className={classes.formSubsection}>
                Indeks harga
              </Typography>
              <div className={classes.flexCenter}>
                <input
                  id="upload-buy-index"
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={onBuyFileChange}
                />
                {/* <TextField
                  variant="outlined"
                  margin="dense"
                  label="Indeks harga beli"
                  value={buyPriceCsv ? buyPriceCsv.name : savedBuyPriceFileName}
                  style={{ marginRight: 16 }}
                  fullWidth
                /> */}
                <Typography style={{ marginRight: 16 }}>
                  Indeks harga beli
                </Typography>
                <Chip
                  label={buyPriceCsv ? buyPriceCsv.name : savedBuyPriceFileName}
                  style={{ flexGrow: 1, marginRight: 16 }}
                />
                <label htmlFor="upload-buy-index">
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload
                  </Button>
                </label>
                <Tooltip title="Lihat indeks harga beli">
                  <IconButton onClick={() => handleOpenPriceList("buy")}>
                    <LibraryBooksIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.flexCenter}>
                <input
                  id="upload-sell-index"
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={onSellFileChange}
                />
                <Typography style={{ marginRight: 16 }}>
                  Indeks harga jual
                </Typography>
                <Chip
                  label={
                    sellPriceCsv ? sellPriceCsv.name : savedSellPriceFileName
                  }
                  style={{ flexGrow: 1, marginRight: 16 }}
                />
                <label htmlFor="upload-sell-index">
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload
                  </Button>
                </label>
                <Tooltip title="Lihat indeks harga jual">
                  <IconButton onClick={() => handleOpenPriceList("sell")}>
                    <LibraryBooksIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
          <Button
            onClick={isAdd ? api_createVendor : api_updateVendor}
            variant="contained"
            color="primary"
          >
            {isAdd ? "Tambah Vendor" : "Simpan"}
          </Button>
        </DialogActions>
      </Dialog>
      <PriceListDialog
        open={openPriceList.status}
        vendorId={vendorId}
        type={openPriceList.type}
        handleClose={handleClosePriceList}
      />
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default FormVendor;
