import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// lighter: "#FCEFD7",
// main: "#C47335",
const borderColor = "black";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "#FCEFD7",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 16,
    textAlign: "center",
    fontWeight: "bold",
    flexGrow: 1,
  },
  description: {
    width: "48%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  price: {
    width: "11s%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  discount: {
    width: "14%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: "17%",
  },
});

const FkTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.description}>Item</Text>
    <Text style={styles.qty}>Jumlah</Text>
    <Text style={styles.price}>Hrg/pc</Text>
    <Text style={styles.discount}>Diskon</Text>
    <Text style={styles.amount}>Subtotal</Text>
  </View>
);

export default FkTableHeader;
