// list produksi yang sudah dilakukan
const listProduksi = [
  {
    id: 1,
    cust: "Pak Alan",
    length: 94,
    width: 35,
    height: 100,
    qty: 200,
    rawLength: 1940,
    rawWidth: 1350,
    kertas: "125K/125M/125M/125M/125M",
    flute: "BCF",
    rawQty: 78,
    harga: 1000, // harga bahan
    itemName: "Box UPS", // nama item box jadi
    salesDate: "08-Oct-2021",
    status: true,
  },

  {
    id: 3,
    cust: "Bintang maju",
    length: 190,
    width: 150,
    height: 49,
    qty: 150,
    rawLength: 1300,
    rawWidth: 1300,
    kertas: "125K/125M/125M",
    flute: "BF",
    rawQty: 55,
    harga: 2000, // harga bahan
    itemName: "Polo", // nama item box jadi
    salesDate: null,
    status: false,
  },
  {
    id: 4,
    cust: "CX Music",
    length: 130,
    width: 130,
    height: 40,
    qty: 220,
    rawLength: 1220,
    rawWidth: 950,
    kertas: "125K/125M/125M",
    flute: "BF",
    rawQty: 100,
    harga: 3000, // harga bahan
    itemName: "Kotak biola", // nama item box jadi
    salesDate: "08-Oct-2021",
    status: true,
  },
  {
    id: 5,
    cust: "Pak Alan",
    length: 122,
    width: 95,
    height: 20,
    qty: 350,
    rawLength: 1330,
    rawWidth: 1000,
    kertas: "150K/125M/125M/125M/125M",
    flute: "BCF",
    rawQty: 200,
    harga: 1000, // harga bahan
    itemName: "Box power supply", // nama item box jadi
    salesDate: null,
    status: false,
  },
];

// list pekerjaan produksi yang masih pending
const listPekerjaan = [
  {
    id: 1,
    cust: "Pak Alan",
    length: 94,
    width: 35,
    height: 100,
    qty: 200,
    rawLength: 1940,
    rawWidth: 1350,
    kertas: "125K/125M/125M/125M/125M",
    flute: "BCF",
    rawQty: 78,
    harga: 1000, // harga bahan
    itemName: "Box UPS", // nama item box jadi
    salesDate: "08-Oct-2021",
  },

  {
    id: 3,
    cust: "Bintang maju",
    length: 190,
    width: 150,
    height: 49,
    qty: 150,
    rawLength: 1300,
    rawWidth: 1300,
    kertas: "125K/125M/125M",
    flute: "BF",
    rawQty: 55,
    harga: 2000, // harga bahan
    itemName: "Polo", // nama item box jadi
    salesDate: "08-Oct-2021",
  },
  {
    id: 4,
    cust: "CX Music",
    length: 130,
    width: 130,
    height: 40,
    qty: 220,
    rawLength: 1220,
    rawWidth: 950,
    kertas: "125K/125M/125M",
    flute: "BF",
    rawQty: 100,
    harga: 3000, // harga bahan
    itemName: "Kotak biola", // nama item box jadi
    salesDate: "08-Oct-2021",
  },
  {
    id: 5,
    cust: "Pak Alan",
    length: 122,
    width: 95,
    height: 20,
    qty: 350,
    rawLength: 1330,
    rawWidth: 1000,
    kertas: "150K/125M/125M/125M/125M",
    flute: "BCF",
    rawQty: 200,
    harga: 1000, // harga bahan
    itemName: "Box power supply", // nama item box jadi
    salesDate: "08-Oct-2021",
  },
];
export { listProduksi };
