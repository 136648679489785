const historiMentah = [
  {
    id: 1,
    inout: "masuk",
    supplier: "Supplier A",
    cust: "Alan",
    panjang: 1940,
    lebar: 1350,
    kertas: "125K/125M/125M/125M/125M",
    flute: "BCF",
    qty: 254,
    harga: 1000,
    date: "08-Sep-2021",
  },
  {
    id: 2,
    inout: "keluar",
    supplier: "-",
    cust: "Alan",
    panjang: 1930,
    lebar: 1550,
    kertas: "125K/125M/125M",
    flute: "BF",
    qty: 300,
    harga: 2000,
    date: "01-Sep-2021",
  },
  {
    id: 3,
    inout: "masuk",
    supplier: "Supplier A",
    cust: "Budiman",
    panjang: 1300,
    lebar: 1300,
    kertas: "125K/125M/125M",
    flute: "BF",
    qty: 55,
    harga: 2000,
    date: "08-Sep-2021",
  },
  {
    id: 4,
    inout: "masuk",
    supplier: "Supplier A",
    cust: "Graha Jaya",
    panjang: 1220,
    lebar: 950,
    kertas: "125K/125M/125M",
    flute: "BF",
    qty: 286,
    harga: 3000,
    date: "06-Sep-2021",
  },
  {
    id: 5,
    inout: "manual",
    supplier: "Mulia Box",
    panjang: 1330,
    lebar: 1000,
    kertas: "150K/125M/125M/125M/125M",
    flute: "BCF",
    qty: 1978,
    harga: 1000,
    date: "08-Aug-2021",
  },
  {
    id: 6,
    inout: "masuk",
    supplier: "Supplier A",
    cust: "CX Music",
    panjang: 1940,
    lebar: 1350,
    kertas: "125K/125M/125M/125M/125M",
    flute: "BCF",
    qty: 120,
    harga: 1000,
    date: "10-Sep-2021",
  },
  {
    id: 7,
    inout: "keluar",
    supplier: "",
    cust: "CX Music",
    panjang: 1940,
    lebar: 1350,
    kertas: "125K/125M/125M/125M/125M",
    flute: "BCF",
    qty: 200,
    harga: 1000,
    date: "10-Sep-2021",
  },
];

export { historiMentah };
