import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from "@material-ui/icons/Refresh";

const RefreshButton = ({ handleClick, ...props }) => {
  return (
    <Tooltip title="Refresh" {...props}>
      <IconButton color="primary" size="small" onClick={handleClick}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RefreshButton;
