import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import format from "date-fns/format";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";

import CloseIcon from "@material-ui/icons/Close";

import ListProdukPO from "./ListProdukPO";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const FormPO = ({ isAdd, open, purchaseId, handleClose, handleRefresh }) => {
  const classes = useStyles();
  const [purchaseNo, setPurchaseNo] = useState("");
  const [purchaseDate, setPurchaseDate] = useState(new Date());
  const [vendor, setVendor] = useState({
    id: "",
    companyName: "",
    picName: "",
    address: "",
    phone: "",
    paymentTerm: "",
  });
  const [purchaser, setPurchaser] = useState({
    id: "",
    fullName: "",
  });
  const [ppn, setPpn] = useState(false);
  // const [mboxAddress, setMboxAddress] = useState("Jl. Terusan Pasirkoja");
  const [vendors, setVendors] = useState([]);
  const [purchasers, setPurchasers] = useState([]);
  const [fieldsValid, setFieldsValid] = useState({
    purchaseDate: true,
    vendor: true,
    purchaser: true,
    ppn: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const handleChangeVendor = (event) => {
    if (vendors.length > 0) {
      var id = event.target.value;
      var vend = vendors.find((v) => v.id === id);
      setVendor(vend);
    }
  };

  const handleChangePurchaser = (event) => {
    if (purchasers.length > 0) {
      var id = event.target.value;
      var purch = purchasers.find((p) => p.id === id);
      setPurchaser(purch);
    }
  };

  const api_getPurchaseDetail = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/purchases/" + purchaseId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        var vend = vendors.find((v) => v.id === res.data.vendorId);
        var purch = purchasers.find((p) => p.id === res.data.purchaserId);
        setVendor(vend);
        setPurchaseNo(res.data.purchaseNo);
        setPurchaseDate(new Date(res.data.purchaseDate));
        setPurchaser(purch);
        setPpn(res.data.ppn);
        // setPaymentTerm(res.data.paymentTerm); --> done after setVendor
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_createPurchase = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("purchaseDate", format(purchaseDate, "yyyy-MM-dd"));
    newData.append("vendorId", vendor.id);
    newData.append("purchaserId", purchaser.id);
    newData.append("ppn", ppn);
    // newData.append("paymentTerm", vendor.paymentTerm);

    // hit the API
    axios
      .post(process.env.REACT_APP_MBOXBE_URI + "/purchases", newData, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Purchase Order berhasil dibuat",
        });
        setPurchaseDate(new Date());
        setVendor({
          id: "",
          companyName: "",
          picName: "",
          address: "",
          phone: "",
          paymentTerm: "",
        });
        setPurchaser({
          id: "",
          fullName: "",
        });
        handleRefresh(res.data.purchase);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_updatePurchase = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("vendorId", vendor.id);
    newData.append("purchaserId", purchaser.id);
    newData.append("ppn", ppn);
    // newData.append("paymentTerm", vendor.paymentTerm);

    // hit the API
    axios
      .put(
        process.env.REACT_APP_MBOXBE_URI + "/purchases/" + purchaseId,
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Perubahan pada Purchase Order berhasil disimpan",
        });
        setPurchaseNo("");
        setPurchaseDate(new Date());
        setVendor({
          id: "",
          companyName: "",
          picName: "",
          address: "",
          phone: "",
          paymentTerm: "",
        });

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getVendors = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/vendors", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setVendors(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getPurchasers = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/purchasers", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setPurchasers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      purchaseDate: purchaseDate != null,
      vendor: vendor.id !== "",
      purchaser: purchaser.id !== "",
      ppn: ppn != null,
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  useEffect(() => {
    api_getVendors();
    api_getPurchasers();
    if (!isAdd && purchaseId) api_getPurchaseDetail();
  }, [purchaseId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={!isAdd}
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {isAdd ? "Buat PO Baru" : `Detail PO (No. ${purchaseNo})`}
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Typography className={classes.formSubsection}>Tanggal PO</Typography>
          <TextField
            variant="outlined"
            margin="dense"
            id="poDate"
            // label="Tanggal PO"
            value={format(purchaseDate, "dd-MMM-yyyy")}
            fullWidth
          />
          <Typography className={classes.formSubsection}>
            Informasi vendor
          </Typography>
          <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Vendor"
            id="select-vendor"
            value={vendor.id}
            onChange={handleChangeVendor}
            required
            error={!fieldsValid.vendor}
            helperText={
              !fieldsValid.vendor ? "Field ini tidak boleh kosong" : null
            }
          >
            {vendors.map((item) => (
              <MenuItem value={item.id}>{item.companyName}</MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            margin="dense"
            id="vendorPic"
            label="Nama PIC vendor"
            value={vendor.picName}
            fullWidth
            disabled
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="vendorAddress"
            label="Alamat vendor"
            value={vendor.address}
            fullWidth
            disabled
          />
          <Typography className={classes.formSubsection}>Pembayaran</Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="paymentTerm"
                label="Term pembayaran"
                value={vendor.paymentTerm}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ppn}
                    onChange={(e) => setPpn(e.target.checked)}
                    name="includePPN"
                    color="primary"
                    size="small"
                  />
                }
                label="Tambah PPN (11%)"
                style={{ marginLeft: 2 }}
              />
            </Grid>
          </Grid>
          <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Pembelian atas nama"
            id="select-purchaser"
            value={purchaser.id}
            onChange={handleChangePurchaser}
            required
            error={!fieldsValid.purchaser}
            helperText={
              !fieldsValid.purchaser ? "Field ini tidak boleh kosong" : null
            }
          >
            {purchasers.map((item) => (
              <MenuItem value={item.id}>{item.fullName}</MenuItem>
            ))}
          </TextField>
          <Typography className={classes.formSubsection}>Total</Typography>
          {!isAdd && purchaseId ? (
            <ListProdukPO
              purchaseId={purchaseId}
              vendorId={vendor.id}
              includePPN={ppn}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
          <Button
            onClick={isAdd ? api_createPurchase : api_updatePurchase}
            variant="contained"
            color="primary"
          >
            {isAdd ? "Buat PO" : "Simpan"}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default FormPO;
