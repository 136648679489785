import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const HistoriTerimaBarang = ({ receiveHistory }) => {
  const classes = useStyles();

  const formatSizeString = (length, width) => {
    return ` (${length} x ${width})`;
  };

  return (
    <div style={{ marginTop: 16 }}>
      {receiveHistory.map(({ receiveDate, details }) => (
        <Card style={{ marginBottom: 8 }}>
          <CardContent>
            <Typography color="primary">
              <strong>
                {format(new Date(receiveDate), "dd-MMM-yyyy, HH:mm")}
              </strong>
            </Typography>
            <List dense>
              {details.map(
                ({
                  id,
                  cust,
                  paper,
                  fluteN,
                  length,
                  width,
                  recvQty,
                  returnQty,
                }) => (
                  <ListItem divider>
                    <ListItemText style={{ flex: 2 }} secondary={cust}>
                      {paper}
                      {" - "}
                      {fluteN}
                      {formatSizeString(length, width)}
                    </ListItemText>
                    <ListItemText style={{ flex: 3 }}>
                      <>
                        <Chip
                          label={<>Terima: {recvQty}</>}
                          color="primary"
                          style={{ marginRight: 16 }}
                        />
                        <Chip label={<>Retur: {returnQty}</>} />
                      </>
                      {/* <Typography color="primary">
                        Terima: {recvQty} | Retur: {returnQty}
                      </Typography> */}
                    </ListItemText>
                  </ListItem>
                )
              )}
            </List>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default HistoriTerimaBarang;
