import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router } from "react-router-dom";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      lighter: "#FCEFD7",
      main: "#C47335",
      darker: "#5E1B0A",
    },
    success: {
      // lighter: "#F2FBCC",
      // main: "#82B50C",
      lighter: "#E2FCD9",
      main: "#3FC64F",
    },
    info: {
      // lighter: "#C9FCEE",
      // main: "#00B9C6",
      lighter: "#CAFCFC",
      main: "#009BD3",
    },
    warning: {
      lighter: "#FEFCCC",
      main: "#F9E502",
    },
    error: {
      // lighter: "#FEE1D1",
      // main: "#ED1F1B",
      lighter: "#FEE6D1",
      main: "#F93C1B",
      darker: "#770517",
    },
  },
  typography: {
    // fontFamily: ["Be Vietnam", "sans-serif"],
    fontFamily: ["Lato", "sans-serif"],
    // fontFamily: ["Poppins", "sans-serif"],
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: false,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
