import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DataGrid } from "@mui/x-data-grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "@material-ui/core/Tooltip";

import CloseIcon from "@material-ui/icons/Close";

import { stokMentah } from "../../../../static/stokMentah";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 400,
    maxWidth: 300,
    backgroundColor: theme.palette.background.paper,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
}));

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x ${
    params.getValue(params.id, "width") || ""
  }`;
};

const TambahKomponenProduksi = ({
  open,
  handleClose,
  handleRefresh,
  custId,
  productionId,
}) => {
  const columns = [
    { field: "cust", headerName: "Customer", width: 150 },
    {
      field: "size",
      headerName: "Ukuran bahan",
      width: 150,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 180 },
    { field: "fluteN", headerName: "Flute", width: 120 },
    {
      field: "qty",
      headerName: "Tersedia",
      type: "number",
      width: 130,
      renderCell: ({ row, value }) => (
        <div>
          <Tooltip title={`Dibooking: ${row.qtyBook}`} placement="right">
            <div>{value}</div>
          </Tooltip>
        </div>
      ),
    },
  ];

  const classes = useStyles();
  const [selectionModel, setSelectionModel] = useState([]);
  const [componentName, setComponentName] = useState("");
  const [componentQty, setComponentQty] = useState();
  const [rawQty, setRawQty] = useState(0);
  const [rawInventory, setRawInventory] = useState([]);
  const [rawInvenFilter, setRawInvenFilter] = useState("cust"); // 'cust' and 'all'
  const [fieldsValid, setFieldsValid] = useState({
    rawInvenId: true,
    rawQty: true,
  });

  const handleChangeRawInvenFilter = (event) => {
    setRawInvenFilter(event.target.value);
  };

  const api_getRawInventoryByCustomer = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/rawinventory_by_customer?custId=" +
          custId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setRawInventory([]); // force re-render here
        setRawInventory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getRawInventory = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/rawinventory", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setRawInventory([]); // force re-render here
        setRawInventory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_createProductionDetail = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("name", componentName);
    // newData.append("qty", componentQty);
    newData.append("rawInvenId", selectionModel[0]);
    newData.append("rawInvenQty", rawQty);

    // console.log({ selectionModel });

    // hit the API
    axios
      .post(
        process.env.REACT_APP_MBOXBE_URI +
          "/productions/" +
          productionId +
          "/details",
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setComponentName("");
        setRawQty(0);

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var maxQty = 0;
    if (selectionModel[0] != null) {
      maxQty = rawInventory.find((i) => i.id === selectionModel[0]).qty;
    }
    var valids = {
      rawQty: parseInt(rawQty) > 0 && parseInt(rawQty) <= parseInt(maxQty),
      rawInvenId: selectionModel[0] != null,
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  // componentDidMount
  useEffect(() => {
    if (open) {
      if (rawInvenFilter === "cust" && custId !== "")
        api_getRawInventoryByCustomer();
      else if (rawInvenFilter === "all") api_getRawInventory();
    }
  }, [rawInvenFilter, custId, open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <div className={classes.flexCenter}>
          <div style={{ flexGrow: 1 }}>Tambah komponen penyusun box</div>
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          margin="dense"
          id="componentName"
          label="Nama komponen"
          fullWidth
          value={componentName}
          onChange={(e) => setComponentName(e.target.value)}
        />
        {/* <TextField
          variant="outlined"
          margin="dense"
          id="componentQty"
          label="Jumlah komponen"
          type="number"
          fullWidth
          value={componentQty}
          onChange={(e) => setComponentQty(e.target.value)}
        /> */}
        <Typography variant="h6" style={{ marginTop: 16 }}>
          Pilih bahan *
        </Typography>
        <div className={classes.flexCenter}>
          <FormControl component="fieldset" style={{ flexGrow: 1 }}>
            <div className={classes.flexCenter}>
              <FormLabel component="legend" style={{ marginRight: 16 }}>
                Tampilkan bahan untuk
              </FormLabel>
              <RadioGroup
                row
                aria-label="filter"
                name="rawInvenFilter"
                value={rawInvenFilter}
                onChange={handleChangeRawInvenFilter}
              >
                <FormControlLabel
                  value="cust"
                  control={<Radio color="primary" size="small" />}
                  label="Customer ini"
                />
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" size="small" />}
                  label="Semua customer"
                />
                {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
              </RadioGroup>
            </div>
          </FormControl>
          <TextField
            variant="outlined"
            margin="dense"
            id="rawQty"
            label="Jumlah bahan"
            type="number"
            value={rawQty}
            onChange={(e) => setRawQty(e.target.value)}
            required
            error={!fieldsValid.rawQty}
            helperText={
              !fieldsValid.rawQty
                ? "Jumlah bahan tidak boleh 0 (nol), dan tidak boleh lebih dari stok"
                : null
            }
          />
        </div>

        <div className={classes.flexCenter}>
          {/* <Typography style={{ marginRight: 36 }}>Pilih bahan</Typography> */}
        </div>

        {fieldsValid.rawInvenId ? null : (
          <Typography style={{ marginTop: 8, color: "red" }}>
            Silahkan memilih bahan mentah yang digunakan
          </Typography>
        )}
        <div style={{ height: 400, width: "100%", marginTop: 16 }}>
          <DataGrid
            rows={rawInventory}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Batal</Button>
        <Button onClick={api_createProductionDetail} color="primary">
          Tambah komponen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TambahKomponenProduksi;
