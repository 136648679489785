import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import CloseIcon from "@material-ui/icons/Close";
import SnackbarAlert from "../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
}));

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x
  ${params.getValue(params.id, "width") || ""} x ${
    params.getValue(params.id, "height") || ""
  }`;
};

const DialogNotifProduksi = ({ open, handleClose, handleRefresh, salesId }) => {
  const columns = [
    // { field: "customer", headerName: "Utk customer", width: 160 },
    { field: "name", headerName: "Nama item", width: 160 },
    {
      field: "size",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 160 },
    { field: "flute", headerName: "Flute", width: 120 },
    { field: "qty", headerName: "Jumlah", type: "number", width: 120 },
    {
      field: "urgent",
      headerName: "Urgent",
      width: 120,
      renderCell: (GridCellParams) => (
        <Checkbox
          checked={GridCellParams.row.label === "urgent"}
          onChange={(e) =>
            handleChangeUrgent(GridCellParams.row.id, e.target.checked)
          }
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      ),
    },
  ];

  const classes = useStyles();

  const [salesDetail, setSalesDetail] = useState({});
  const [salesItems, setSalesItems] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const handleChangeUrgent = (id, checked) => {
    var newList = [...salesItems];

    // find the corresponding entry
    var idx = newList.findIndex((item) => item.id === id);
    newList[idx] = { ...newList[idx], label: checked ? "urgent" : "" };

    setSalesItems(newList);
  };

  const api_getSalesDetail = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/sales/" + salesId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSalesDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getSalesItems = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI + "/sales/" + salesId + "/details",
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSalesItems([]);
        var temp = res.data.map((item) => {
          return { ...item, label: "" };
        });

        setSalesItems(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_salesToProductionJobs = () => {
    // prepare the data
    var newData = new FormData();
    var configs = {};
    salesItems.forEach((item) => {
      configs[item.id] = { label: item.label };
    });
    newData.append(
      "configs",
      JSON.stringify(configs)
      // JSON.stringify(
      //   salesItems.map((item) => {
      //     return { id: item.id, label: item.label };
      //   })
      // )
    );

    // hit the API
    axios
      .post(
        process.env.REACT_APP_MBOXBE_URI + "/sales/" + salesId + "/create_jobs",
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message:
            "List pesanan sudah dikirimkan ke bagian produksi, pesanan tidak dapat lagi dirubah.",
        });
        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    if (salesId) {
      api_getSalesDetail();
      api_getSalesItems();
    }
  }, [salesId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Notifikasi pekerjaan ke tim produksi
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Alert severity="info">
            <AlertTitle>
              <strong>
                Mohon periksa kembali daftar pesanan yang akan diproduksi.
              </strong>
            </AlertTitle>
            Setelah informasi pesanan diteruskan ke tim produksi, Anda tidak
            dapat lagi mengubah detail pesanan setelah diteruskan ke produksi.
          </Alert>
          <Typography style={{ marginTop: 16, marginBottom: 16 }}>
            <Grid container spacing={1}>
              {[
                { f: "No. penjualan", v: salesDetail.salesNo },
                { f: "Nama customer", v: salesDetail.cust },
                { f: "List pesanan", v: "" },
              ].map(({ f, v }) => (
                <>
                  <Grid item xs={3}>
                    <strong>{f}</strong>
                  </Grid>
                  <Grid item xs={9}>
                    {v}
                  </Grid>
                </>
              ))}
            </Grid>
          </Typography>
          <div style={{ height: 250, width: "100%" }}>
            <DataGrid
              rows={salesItems}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              rowHeight={35}
              // checkboxSelection
              disableSelectionOnClick
              disableColumnFilter
            />
          </div>
          {/* <DialogContentText id="alert-dialog" style={{ marginTop: 16 }}> */}
          <Alert severity="error" style={{ marginTop: 16 }}>
            <AlertTitle>
              <strong>Apakah anda yakin?</strong>
            </AlertTitle>
          </Alert>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Tidak</Button>
          <Button
            onClick={api_salesToProductionJobs}
            color="primary"
            variant="contained"
          >
            Ya
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default DialogNotifProduksi;
