import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";

// import TabelListBeli from "./TabelListBeli";
// import FormPO from "./FormPO";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const PenagihanView = () => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  return (
    <div>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Penagihan
        </Typography>
        <Button variant="outlined" color="primary" style={{ marginRight: 16 }}>
          Detail
        </Button>
        <Button
          variant="contained"
          color="primary"
          // startIcon={<AddIcon />}
          onClick={handleOpenAdd}
        >
          Download
        </Button>
      </div>
      {/* <FormPO isAdd={true} open={openAdd} handleClose={handleCloseAdd} />
      <TabelListBeli /> */}
    </div>
  );
};

export default PenagihanView;
