import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import NumberFormat from "react-number-format";
import format from "date-fns/format";
import { DataGrid } from "@mui/x-data-grid";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import TambahBahanBaku from "./TambahBahanBaku";
import EditBahanBaku from "./EditBahanBaku";
import { formatWithThousandSeparator } from "../../../utils/helperFunctions";
import RefreshButton from "../../../coreui/RefreshButton";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const formatSizeString = (params) => {
  // return params.getValue(params.id, "panjang");
  return `${params.getValue(params.id, "length") || ""} x ${
    params.getValue(params.id, "width") || ""
  }`;
};

const TabelStokBaku = () => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);
  const [grouped, setGrouped] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [rawInventory, setRawInventory] = useState([]);

  const columns = [
    grouped ? {} : { field: "cust", headerName: "Customer", width: 150 },
    {
      field: "size",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 180 },
    { field: "fluteN", headerName: "Flute", width: 110 },
    {
      field: "qty",
      headerName: "Jumlah",
      type: "number",
      width: 130,
      renderCell: ({ row, value }) => (
        <div>
          <Tooltip title={`Dibooking: ${row.qtyBook}`} placement="right">
            <div>{value}</div>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "price",
      headerName: "Harga/lembar (exc. PPN)",
      type: "number",
      width: 170,
      valueFormatter: (params) => {
        return `Rp${formatWithThousandSeparator(params.value)}`;
      },
    },
    {
      field: "price_ppn",
      headerName: "Harga/lembar (inc. PPN)",
      type: "number",
      width: 150,
      // valueFormatter: (params) => {
      //   return `Rp${formatWithThousandSeparator(params.value)}`;
      // },
      renderCell: ({ row }) => (
        <NumberFormat
          value={row.ppn ? Math.trunc(Number(row.price) * 1.11) : "-"}
          prefix={"Rp"}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      ),
    },
    {
      field: "lastUpdated",
      headerName: "Terakhir di-update",
      width: 150,
      renderCell: ({ value }) => (
        <div>{format(new Date(value), "dd-MMM-yyyy, HH:mm")}</div>
      ),
    },
    grouped
      ? {}
      : {
          field: "detail",
          headerName: " ",
          width: 100,
          renderCell: (GridCellParams) => (
            <Button
              color="primary"
              onClick={() => handleClickOpenEdit(GridCellParams.row.id)}
            >
              Detail
            </Button>
          ),
        },
  ];

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleToggleGroup = () => {
    setGrouped(!grouped);
  };

  const handleClickOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const api_getRawInventory = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/rawinventory", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setRawInventory([]); // force re-render here
        setRawInventory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    api_getRawInventory();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Stok bahan baku
          <RefreshButton
            style={{ marginLeft: 16 }}
            handleClick={api_getRawInventory}
          />
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 16 }}
          onClick={handleToggleGroup}
          disabled
        >
          {grouped ? "Perlihatkan customer" : "Kelompokkan customer"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClickOpenAdd}
        >
          Tambah stok
        </Button>
      </div>
      <div style={{ height: "80%", width: "100%" }}>
        <DataGrid
          // rows={grouped ? rows_grouped : rows}
          rows={rawInventory}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
        />
      </div>
      <TambahBahanBaku
        open={openAdd}
        handleClose={handleCloseAdd}
        handleRefresh={api_getRawInventory}
      />
      <EditBahanBaku
        open={openEdit.status}
        rawInventoryId={openEdit.id}
        handleClose={handleCloseEdit}
        handleRefresh={api_getRawInventory}
      />
    </div>
  );
};

export default TabelStokBaku;
