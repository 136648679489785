import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { PDFViewer } from "@react-pdf/renderer";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import CloseIcon from "@material-ui/icons/Close";

import POMain from "./POMain";

// import ListProdukPO from "./ListProdukPO";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CetakPO = ({ isAdd, open, purchaseId, handleClose, handleRefresh }) => {
  const classes = useStyles();
  const [purchaseData, setPurchaseData] = useState({});

  const api_getPurchaseDetailForPrint = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI + "/purchases_for_print/" + purchaseId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setPurchaseData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // api_getVendors();
    // api_getPurchasers();
    if (purchaseId) api_getPurchaseDetailForPrint();
  }, [purchaseId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <div className={classes.flexCenter}>
            <div style={{ flexGrow: 1 }}>Cetak PO</div>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <PDFViewer width="1000" height="600" className="app">
            <POMain data={purchaseData} />
          </PDFViewer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CetakPO;
