import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import CloseIcon from "@material-ui/icons/Close";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

import ListKirimBarang from "./ListKirimBarang";
import FormKirimBarang from "./FormKirimBarang";
import HistoriKirimBarang from "./HistoriKirimBarang";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const KirimBarang = ({ open, handleClose, salesId }) => {
  const classes = useStyles();

  const [salesNo, setSalesNo] = useState("");
  const [customer, setCustomer] = useState({
    id: "",
    companyName: "",
    picName: "",
    address: "",
  });
  const [deliverySummary, setDeliverySummary] = useState([]);
  const [deliveryHistory, setDeliveryHistory] = useState([]);

  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const api_getDeliveries = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI + "/sales/" + salesId + "/deliveries",
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // setReceiveSummary([]); // force re-render here
        setDeliverySummary(res.data.summary);
        setDeliveryHistory(res.data.history);
        setCustomer(res.data.cust);
        setSalesNo(res.data.salesNo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    if (salesId) api_getDeliveries();
  }, [salesId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen
      // maxWidth="lg"
      // fullWidth
    >
      <AppBar className={classes.dialogTitle} color="default">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Status pengiriman barang
          </Typography>
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            {[
              { f: "Nomor Penjualan", v: salesNo },
              { f: "Nama perusahaan customer", v: customer.companyName },
              { f: "Nama PIC customer", v: customer.picName },
              { f: "Alamat customer", v: customer.address },
            ].map(({ f, v }) => (
              <>
                <Grid item xs={3}>
                  <strong>{f}</strong>
                </Grid>
                <Grid item xs={9}>
                  {v}
                </Grid>
              </>
            ))}
          </Grid>
        </DialogContentText>
        <div className={classes.flexCenter}>
          {/* <div style={{ flexGrow: 1 }} /> */}
          <Typography
            className={classes.formSubsection}
            style={{ flexGrow: 1 }}
          >
            List barang
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<LocalShippingIcon />}
            onClick={handleOpenAdd}
          >
            Kirim barang
          </Button>
        </div>
        <FormKirimBarang
          open={openAdd}
          handleClose={handleCloseAdd}
          handleRefresh={api_getDeliveries}
          custId={customer.id}
          custCompanyName={customer.companyName}
          custAddress={customer.address}
          deliverySummary={deliverySummary}
          salesId={salesId}
        />
        <ListKirimBarang deliverySummary={deliverySummary} />

        <Typography
          className={classes.formSubsection}
          style={{ marginTop: 24 }}
        >
          Daftar pengiriman barang
        </Typography>
        <HistoriKirimBarang deliveryHistory={deliveryHistory} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Tutup</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KirimBarang;
