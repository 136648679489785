import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";

import TabelListPekerjaan from "./TabelListPekerjaan";
// import FormProduksi from "./FormProduksi";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const ListPekerjaanView = () => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  return (
    <div>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          List pekerjaan produksi
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAdd}
        >
          Buat surat pengerjaan
        </Button>
      </div>
      {/* <FormProduksi isAdd={true} open={openAdd} handleClose={handleCloseAdd} /> */}
      <TabelListPekerjaan />
    </div>
  );
};

export default ListPekerjaanView;
