import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Alert from "@material-ui/lab/Alert";

import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  accordionSecondaryHeading: {
    flexBasis: "20%",
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
}));

const ItemFromInventory = ({
  idx,
  values,
  removeItem,
  handleChange,
  boxInventory,
}) => {
  const classes = useStyles();

  return (
    <div id={idx} className={classes.flexCenter}>
      <TextField
        select
        fullWidth
        variant="outlined"
        margin="dense"
        label="Nama box inventory"
        id="demo-simple-select"
        name="boxInvenId"
        value={values.boxInvenId}
        onChange={(e) => handleChange(e, idx)}
      >
        {boxInventory.map(
          ({ id, name, length, width, height, paper, fluteN }) => (
            <MenuItem value={id}>
              {name} {length} x {width} x {height} - {paper} ({fluteN})
            </MenuItem>
          )
        )}
      </TextField>
      <TextField
        variant="outlined"
        margin="dense"
        name="qty"
        label="Jumlah"
        type="number"
        style={{ marginLeft: 8, marginRight: 8 }}
        value={values.qty}
        onChange={(e) => handleChange(e, idx)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              /{" "}
              {boxInventory.filter((i) => i.id === values.boxInvenId).length > 0
                ? boxInventory.filter((i) => i.id === values.boxInvenId)[0].qty
                : 0}
            </InputAdornment>
          ),
        }}
      />
      <Tooltip title="Hapus">
        <IconButton
          color="secondary"
          size="small"
          onClick={() => {
            removeItem(idx);
          }}
          className={classes.iconButtonError}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const ItemToSend = ({ item, handleChangeDeliveryItemDetails, custId }) => {
  const classes = useStyles();
  const { id, name, length, width, height, paper, fluteN, qty, totalSent } =
    item;
  const [itemsFromInventory, setItemsFromInventory] = React.useState([]);
  const [boxInvenFilter, setBoxInvenFilter] = React.useState("cust"); // 'cust' and 'all'
  const [boxInventory, setBoxInventory] = React.useState([]);
  const [checked, setChecked] = React.useState(false);

  const handleChangeBoxInvenFilter = (event) => {
    setBoxInvenFilter(event.target.value);
  };

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const addItemFromIventory = () => {
    const idx = itemsFromInventory.length
      ? itemsFromInventory[itemsFromInventory.length - 1].idx + 1
      : 0;
    setItemsFromInventory((oldArray) => [
      ...oldArray,
      { idx, boxInvenId: "", inventoryName: "", qty: 0 },
    ]);
  };

  const removeItem = (idx) => {
    // console.log("removing id-", id);
    var newArr = itemsFromInventory.filter((item) => item.idx !== idx);
    setItemsFromInventory(newArr);
  };

  const handleChangeItem = (e, idx) => {
    var newArr = itemsFromInventory.map((item) => {
      if (item.idx === idx) item[e.target.name] = e.target.value;
      return item;
    });
    setItemsFromInventory(newArr);
  };

  const api_getBoxInventory = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/boxinventory", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setBoxInventory([]); // force re-render here
        setBoxInventory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getBoxInventoryByCustomer = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/boxinventory_by_customer?custId=" +
          custId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setBoxInventory([]); // force re-render here
        setBoxInventory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    api_getBoxInventory();
  }, []);

  useEffect(() => {
    handleChangeDeliveryItemDetails(id, itemsFromInventory, checked);
  }, [itemsFromInventory, checked]);

  useEffect(() => {
    if (boxInvenFilter === "cust" && custId !== "")
      api_getBoxInventoryByCustomer();
    else if (boxInvenFilter === "all") api_getBoxInventory();
  }, [boxInvenFilter, custId]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className={classes.flexCenter}>
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox checked={checked} onChange={handleChangeChecked} />
            }
          />
          <Typography style={{ flexGrow: 1 }}>
            <strong>
              {name} {length} x {width} x {height}
            </strong>{" "}
            - {paper} ({fluteN})
          </Typography>
          <Typography>
            Terkirim: ({totalSent} / {qty})
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ flexGrow: 1 }}>
          <div>
            <FormControl component="fieldset" style={{ flexGrow: 1 }}>
              <div className={classes.flexCenter} style={{ marginBottom: 8 }}>
                <FormLabel component="legend" style={{ marginRight: 16 }}>
                  Tampilkan inventori untuk
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="filter"
                  name="boxInvenFilter"
                  value={boxInvenFilter}
                  onChange={handleChangeBoxInvenFilter}
                >
                  <FormControlLabel
                    value="cust"
                    control={<Radio color="primary" size="small" />}
                    label="Customer ini"
                  />
                  <FormControlLabel
                    value="all"
                    control={<Radio color="primary" size="small" />}
                    label="Semua customer"
                  />
                </RadioGroup>
              </div>
            </FormControl>
          </div>

          {itemsFromInventory.map((item) => (
            <ItemFromInventory
              idx={item.idx}
              values={item}
              removeItem={removeItem}
              handleChange={(e, idx) => handleChangeItem(e, idx)}
              boxInventory={boxInventory}
            />
          ))}
          <Button
            color="primary"
            variant="outlined"
            size="small"
            startIcon={<AddIcon />}
            style={{ marginTop: 16, alignContent: "center" }}
            onClick={addItemFromIventory}
          >
            tambah item dari inventory
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

// const formatSizeString = (params) => {
//   return `${params.getValue(params.id, "length") || ""} x
//   ${params.getValue(params.id, "width") || ""} x ${
//     params.getValue(params.id, "height") || ""
//   }`;
// };

// const formatTotalRecvReturn = (params) => {
//   return (
//     params.getValue(params.id, "totalRecv") +
//     params.getValue(params.id, "totalReturn")
//   );
// };

const FormKirimBarang = ({
  open,
  handleClose,
  handleRefresh,
  custId,
  custCompanyName,
  custAddress,
  deliverySummary,
  salesId,
}) => {
  const classes = useStyles();

  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [deliveryItemDetails, setDeliveryItemDetails] = useState({});
  const [fieldValid, setFieldValid] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const createDelivery = () => {
    // prepare the data
    var deliveryList = [];
    for (const [key, value] of Object.entries(deliveryItemDetails)) {
      if (value.checked === true) {
        value.boxInventory.forEach((box) => {
          deliveryList.push({
            salesItemId: key,
            boxInvenId: box.boxInvenId,
            qty: box.qty,
          });
        });
      }
    }

    var newData = new FormData();
    newData.append("deliveryDate", deliveryDate.toISOString());
    newData.append("deliveryList", JSON.stringify(deliveryList));

    // validate form
    if (deliveryList === undefined || deliveryList.length === 0) {
      setFieldValid(false);
      console.log(deliveryList);
      return;
    }

    // hit the API
    axios
      .post(
        process.env.REACT_APP_MBOXBE_URI + "/sales/" + salesId + "/deliveries",
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Pengiriman barang berhasil dicatat",
        });
        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });

    // handleClose();
  };

  const handleChangeDeliveryItemDetails = (itemId, boxInventory, checked) => {
    // we want to fill / update the boxInventoryIds array
    var newKeyedArr = { ...deliveryItemDetails };
    newKeyedArr[itemId] = {
      checked: checked,
      boxInventory: boxInventory,
    };
    setDeliveryItemDetails(newKeyedArr);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  useEffect(() => {
    // for each item, create a field for storing list of box inventory IDs
    // since each item from sales can be 'fulfilled' by more than one type of box inventory
    var keyedArr = {};
    deliverySummary.forEach((item) => {
      keyedArr[item.id] = { checked: false, boxInventory: [] };
    });
    setDeliveryItemDetails(keyedArr);
  }, [deliverySummary]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Form pengiriman barang
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            <Typography style={{ marginBottom: 16 }}>
              Silahkan masukkan detail pengiriman barang
            </Typography>
            <Grid container spacing={1}>
              {[
                {
                  f: "Tanggal pengiriman",
                  v: format(deliveryDate, "dd-MMM-yyyy, HH:mm"),
                },
                {
                  f: "Nama perusahaan customer",
                  v: custCompanyName,
                },
                { f: "Alamat customer", v: custAddress },
              ].map(({ f, v }) => (
                <>
                  <Grid item xs={3}>
                    <strong>{f}</strong>
                  </Grid>
                  <Grid item xs={9}>
                    {v}
                  </Grid>
                </>
              ))}
            </Grid>
          </DialogContentText>

          <Typography style={{ marginTop: 24, marginBottom: 16 }}>
            <strong>
              List barang untuk dikirim * <i>(pilih barang dari inventori)</i>
            </strong>
          </Typography>

          {deliverySummary.map((item) => (
            <ItemToSend
              item={item}
              handleChangeDeliveryItemDetails={handleChangeDeliveryItemDetails}
              custId={custId}
            />
          ))}
          {fieldValid ? null : (
            <Alert severity="error" style={{ marginTop: 16 }}>
              Mohon memilih barang untuk dikirimkan dari inventori terlebih dulu
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
          <Button variant="contained" color="primary" onClick={createDelivery}>
            Buat pengiriman barang
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default FormKirimBarang;
