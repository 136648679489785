import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { DataGrid } from "@mui/x-data-grid";

import CloseIcon from "@material-ui/icons/Close";
import { formatWithThousandSeparator } from "../../../../utils/helperFunctions";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const PriceListDialog = ({ open, vendorId, handleClose, handleRefresh }) => {
  const classes = useStyles();
  const [vendor, setVendor] = useState();
  const [uploadedAt, setUploadedAt] = useState();
  const [priceList, setPriceList] = useState([]);
  const columns = [
    { field: "paper", headerName: "Substance", width: 170 },
    {
      field: "SW B",
      headerName: "BF",
      width: 170,
      type: "number",
      valueFormatter: (params) => {
        return `Rp${formatWithThousandSeparator(params.value)}`;
      },
    },
    {
      field: "SW C",
      headerName: "CF",
      width: 170,
      type: "number",
      valueFormatter: (params) => {
        return `Rp${formatWithThousandSeparator(params.value)}`;
      },
    },
    {
      field: "SW E",
      headerName: "EF",
      width: 170,
      type: "number",
      valueFormatter: (params) => {
        return `Rp${formatWithThousandSeparator(params.value)}`;
      },
    },
    {
      field: "DW",
      headerName: "BCF",
      width: 170,
      type: "number",
      valueFormatter: (params) => {
        return `Rp${formatWithThousandSeparator(params.value)}`;
      },
    },
  ];

  const api_getPriceList = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/vendors/" +
          vendorId +
          "/sellpricelist",
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setVendor(res.data.vendor);
        setUploadedAt(res.data.uploadedAt);
        setPriceList(
          res.data.priceList.map((row) => ({
            id: row.paper,
            ...row,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    if (vendorId) api_getPriceList();
  }, [vendorId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"lg"}
        fullWidth
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Daftar Harga Jual
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container>
            <Grid item xs={2}>
              <Typography>Vendor</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>: {vendor}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <Typography>Diunggah pada</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>
                {": "}
                {uploadedAt
                  ? format(new Date(uploadedAt), "dd-MMM-yyyy")
                  : null}
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.formSubsection}>
            Tabel indeks harga
          </Typography>
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={priceList}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              // checkboxSelection
              disableSelectionOnClick
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PriceListDialog;
