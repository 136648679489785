import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";

import { monthlyData } from "../../../../static/incomeStatement";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  chartArea: {
    padding: theme.spacing(1),
    height: 400,
  },
}));

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const IncomeStatementDetail = () => {
  const classes = useStyles();

  var tempDate = new Date();
  tempDate.setHours(tempDate.getHours() - 6);
  const [startDate, setStartDate] = useState(tempDate);
  const [endDate, setEndDate] = useState(new Date());
  // const [startDate, setStartDate] = useState(new Date(2021, 3, 1));
  // const [endDate, setEndDate] = useState(new Date());
  const [dateFilter, setDateFilter] = useState("date");
  const [selectedDate, handleDateChange] = useState(new Date());
  const [detailedData, setDetailedData] = useState(monthlyData);

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleRefreshData = () => {};

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.flexCenter}>
          <Typography style={{ marginRight: 24 }}>
            Detail income statement bulan & tahun
          </Typography>
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>
        <Alert severity="info" style={{ marginTop: 16 }}>
          Statement bulanan dihitung berdasarkan periode tanggal x - x
        </Alert>
      </MuiPickersUtilsProvider>
      <Grid container spacing={1} style={{ marginTop: 24 }}>
        {[
          { fieldName: "Pendapatan", val: detailedData.revenue },
          {
            fieldName: "Harga pokok produksi",
            val: detailedData.materialExpense,
          },
          { fieldName: "Laba bruto", val: detailedData.grossProfit },
          { fieldName: "Gaji karyawan", val: detailedData.salaries },
          { fieldName: "Biaya operasional", val: detailedData.operationalCost },
          {
            fieldName: "Biaya non operasional",
            val: detailedData.nonOperationalCost,
          },
          { fieldName: "Kas kecil", val: detailedData.pettyCash },
          {
            fieldName: "Pemasukan lainnya",
            val: detailedData.additionalRevenue,
          },
          { fieldName: "Laba sebelum pajak", val: detailedData.preTaxProfit },
        ].map(({ fieldName, val }) => (
          <>
            <Grid item xs={2}>
              <strong>{fieldName}</strong>
            </Grid>
            <Grid item xs={1}>
              : Rp
            </Grid>
            <Grid item xs={1} style={{ textAlign: "right" }}>
              {numberWithCommas(val)}
            </Grid>
            <Grid item xs={8} />
          </>
        ))}
      </Grid>
    </>
  );
};

export default IncomeStatementDetail;
