import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import format from "date-fns/format";
import { DataGrid } from "@mui/x-data-grid";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import FormEmployee from "./FormEmployee";
import RefreshButton from "../../../coreui/RefreshButton";
import DialogHapusEmployee from "./DialogHapusEmployee";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  chipColorSuccess: {
    backgroundColor: theme.palette.success.light,
  },
}));

const TabelListEmployee = () => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [openDelete, setOpenDelete] = useState({ status: false, id: null });
  const [employees, setEmployees] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  const columns = [
    { field: "fullName", headerName: "Nama lengkap", width: 200 },
    { field: "address", headerName: "Alamat tinggal", width: 200 },
    { field: "phone", headerName: "No. telp", width: 200 },
    {
      field: "joinedAt",
      headerName: "Tanggal mulai bekerja",
      width: 250,
      renderCell: ({ row }) => (
        <>
          {row.joinedAt ? format(new Date(row.joinedAt), "dd-MMM-yyyy") : null}
        </>
      ),
    },
    { field: "position", headerName: "Jabatan", width: 200 },
    {
      field: "detail",
      headerName: " ",
      width: 100,
      renderCell: ({ row }) => (
        <Button color="primary" onClick={() => handleOpenEdit(row.id)}>
          Detail
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: " ",
      width: 50,
      renderCell: ({ row }) => (
        <IconButton
          size="small"
          disabled={!row.can_delete}
          onClick={() => handleOpenDelete(row.id)}
        >
          <Tooltip title="Hapus data pegawai">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const handleOpenDelete = (id) => {
    setOpenDelete({ status: true, id });
  };

  const handleCloseDelete = () => {
    setOpenDelete({ status: false, id: null });
  };

  const handleRefreshAfterCreate = (newlyCreatedId) => {
    api_getEmployees();
    // handleOpenEdit(newlyCreatedId);
  };

  const api_getEmployees = () => {
    setLoadingTable(true);
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/employees", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setEmployees([]); // force re-render here
        setEmployees(res.data);
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    api_getEmployees();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Daftar karyawan
          <RefreshButton
            style={{ marginLeft: 16 }}
            handleClick={api_getEmployees}
          />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAdd}
        >
          Tambah karyawan
        </Button>
      </div>
      <div style={{ height: "80%", width: "100%" }}>
        <DataGrid
          rows={employees}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
          loading={loadingTable}
        />
      </div>

      <FormEmployee
        isAdd={true}
        open={openAdd}
        handleClose={handleCloseAdd}
        handleRefresh={handleRefreshAfterCreate}
      />
      <FormEmployee
        isAdd={false}
        open={openEdit.status}
        handleClose={handleCloseEdit}
        handleRefresh={api_getEmployees}
        employeeId={openEdit.id}
      />
      <DialogHapusEmployee
        open={openDelete.status}
        handleRefresh={api_getEmployees}
        handleClose={handleCloseDelete}
        employeeId={openDelete.id}
      />
    </div>
  );
};

export default TabelListEmployee;
