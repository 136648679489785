import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import format from "date-fns/format";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

import MessageIcon from "@material-ui/icons/Message";
import ErrorIcon from "@material-ui/icons/Error";
import AddIcon from "@material-ui/icons/Add";

import FormPekerjaan from "./FormPekerjaan";
import CommentDrawer from "./CommentDrawer";
import RefreshButton from "../../../coreui/RefreshButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const TabelListPekerjaan = ({ tokenPayload }) => {
  const classes = useStyles();
  const columns = [
    {
      field: "label",
      headerName: " ",
      width: 50,
      renderCell: ({ value }) =>
        value === "urgent" ? (
          <Tooltip title="Urgent">
            <ErrorIcon color="error" />
          </Tooltip>
        ) : null,
    },
    { field: "salesNo", headerName: "No. penjualan", width: 170 },
    {
      field: "salesDate",
      headerName: "Tanggal pesanan",
      width: 170,
      renderCell: ({ value }) => (
        <div>{format(new Date(value), "dd-MMM-yyyy")}</div>
      ),
    },
    { field: "cust", headerName: "Customer", width: 200 },
    { field: "itemName", headerName: "Nama item", width: 200 },
    {
      field: "size",
      headerName: "Ukuran box",
      width: 160,
      // valueGetter: formatBoxSizeString,
      // sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    // { field: "qty", headerName: "Jumlah", type: "number", width: 120 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: ({ row }) => (
        <strong
          style={{
            color:
              parseInt(row.doneQty) === parseInt(row.qty)
                ? "green"
                : parseInt(row.doneQty) > 0 &&
                  parseInt(row.doneQty) < parseInt(row.qty)
                ? "black"
                : "red",
          }}
        >
          {row.doneQty} / {row.qty}
        </strong>
      ),
    },
    {
      field: "detail",
      headerName: " ",
      width: 100,
      renderCell: (GridCellParams) => (
        <Button
          color="primary"
          onClick={() => handleOpenEdit(GridCellParams.row.id)}
        >
          Detail
        </Button>
      ),
    },
    {
      field: "comment",
      headerName: " ",
      width: 100,
      renderCell: ({ row }) => (
        <Button
          color="primary"
          startIcon={<MessageIcon />}
          onClick={() =>
            handleOpenComment({
              id: row.id,
              itemName: row.itemName,
              boxSize: row.size,
              cust: row.cust,
            })
          }
        >
          Komentar
        </Button>
      ),
    },
  ];

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [openComment, setOpenComment] = useState({
    status: false,
    itemName: "",
    boxSize: "",
    cust: "",
  });
  const [jobs, setJobs] = useState([]);

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const handleOpenComment = ({ id, itemName, boxSize, cust }) => {
    setOpenComment({ status: true, id, itemName, boxSize, cust });
  };

  const handleCloseComment = () => {
    setOpenComment({
      status: false,
      id: "",
      itemName: "",
      boxSize: "",
      cust: "",
    });
  };

  const api_getJobs = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/jobs", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setJobs([]); // force re-render here
        setJobs(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    api_getJobs();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Daftar pekerjaan produksi
          <RefreshButton style={{ marginLeft: 16 }} handleClick={api_getJobs} />
        </Typography>
        <Button color="primary" style={{ marginRight: 16 }}>
          Semua
        </Button>
        <Button color="primary" style={{ marginRight: 16 }}>
          Pending
        </Button>
        <Button color="primary" style={{ marginRight: 36 }}>
          Selesai
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          disabled
          // onClick={handleOpenAdd}
        >
          Tambah pekerjaan
        </Button>
      </div>
      <div style={{ height: "80%", width: "100%" }}>
        <DataGrid
          rows={jobs}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
        />
      </div>
      <FormPekerjaan isAdd={true} open={openAdd} handleClose={handleCloseAdd} />
      <FormPekerjaan
        isAdd={false}
        open={openEdit.status}
        handleClose={handleCloseEdit}
        jobId={openEdit.id}
      />
      <CommentDrawer
        open={openComment.status}
        handleOpenDrawer={handleOpenComment}
        handleCloseDrawer={handleCloseComment}
        jobId={openComment.id}
        itemName={openComment.itemName}
        boxSize={openComment.boxSize}
        cust={openComment.cust}
        userId={tokenPayload.userId}
      />
      {/* <TerimaBarang open={openReceive} handleClose={handleCloseReceive} /> */}
    </div>
  );
};

export default TabelListPekerjaan;
