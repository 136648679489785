import React from "react";
import format from "date-fns/format";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import logoVertical from "../../../../logo-vertical.png";

import { formatDateForMboxInvoice } from "../../../../utils/helperFunctions";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    display: "flex",
    marginTop: 12,
    justifyContent: "space-between",
  },
  reportTitle: {
    // color: "#C47335",
    letterSpacing: 2,
    fontSize: 16,
    // marginBottom: 4,
    // textAlign: "center",
    // textTransform: "uppercase",
  },
  logoRectangle: {
    // marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 96,
    maxHeight: 32,
    marginBottom: 10,
  },
  logoSquare: {
    // marginLeft: "auto",
    // marginRight: "auto",
    maxWidth: 50,
    maxHeight: 50,
    marginRight: 10,
  },
  gridContainer: {
    flexDirection: "row",
    display: "flex",
  },
});

const FakturHeader = ({ salesNo, salesDate, custPONo }) => (
  <View style={styles.titleContainer}>
    {/* <View>
      <Image style={styles.logoRectangle} src={logo} />
      <Text style={{ fontWeight: "bold" }}>PT. Mulia Mitra Boxindo</Text>
      <Text>Corrugated Carton Box Industry</Text>
      <Text>Jalan Radio Palasari 102, Bandung</Text>
      <Text>Telp: (022) 5202922 / 0811237540</Text>
    </View> */}
    <View style={styles.gridContainer}>
      <Image style={styles.logoSquare} src={logoVertical} />
      <View>
        <Text style={{ fontWeight: "bold" }}>PT. Mulia Mitra Boxindo</Text>
        <Text>Corrugated Carton Box Industry</Text>
        <Text>Jalan Radio Palasari 102, Bandung</Text>
        <Text>Telp: (022) 5202922 / 0811237540</Text>
      </View>
    </View>
    <View>
      <Text style={styles.reportTitle}>Invoice</Text>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}> No. sales order</Text>
        <Text>: {salesNo}</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120, textAlign: "left" }}>Tanggal pemesanan</Text>
        <Text>
          {/* : {salesDate ? format(new Date(salesDate), "dd MMM yyyy") : ""} */}
          : {salesDate ? formatDateForMboxInvoice(salesDate) : ""}
        </Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Tanggal invoice</Text>
        {/* <Text>: {format(new Date(), "dd MMM yyyy")}</Text> */}
        <Text>: {salesDate ? formatDateForMboxInvoice(salesDate) : ""}</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>No. PO Customer </Text>
        <Text>: {custPONo}</Text>
      </View>
    </View>
  </View>
);

export default FakturHeader;
