import React from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import PersonIcon from "@material-ui/icons/Person";

import logo from "../logo.png";

import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import Copyright from "../coreui/Copyright";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: drawerWidth,
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.white,
  },
  appBarLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: drawerWidth, // 24 is the Toolbar gutter
    // backgroundColor: theme.palette.primary.main,
  },
  logo: {
    maxWidth: drawerWidth - 20,
    maxHeight: 50,
  },
  appBarNav: {
    flexGrow: 1,
    display: "flex",
    backgroundColor: theme.palette.background.default,
    marginLeft: theme.spacing(3),
  },
  appBarPadding: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    height: "100%",
    padding: theme.spacing(3),
  },
  toolbarButton: {
    // color: "white",
    color: theme.palette.primary.main,
    fontSize: "1rem",
    marginRight: theme.spacing(2),
    // fontWeight: "bold",
  },
  toolbarButtonSelected: {
    // color: "white",
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: "bold",
    borderBottom: "6px solid",
    borderBottomColor: theme.palette.primary.light,
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  avatarIcon: {
    marginLeft: theme.spacing(1),
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
  },
}));

const AppLayout = ({ tokenPayload }) => {
  const classes = useStyles();

  const location = useLocation();

  const isSelected = (currentPath, path) => {
    return currentPath.indexOf(path) !== -1;
  };

  return (
    <div id="AppLayout" className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Toolbar style={{ alignItems: "center", paddingLeft: 0 }}>
          <div className={classes.appBarLogo}>
            <Link to="/app/inventory/stokbaku">
              <img src={logo} alt="muliabox-logo" className={classes.logo} />
              {/* MULIABOX */}
            </Link>
          </div>
          <div className={classes.appBarNav}>
            {[
              {
                text: "Inventory",
                mainpath: "/app/inventory",
                path: "/app/inventory/stokbaku",
                roles: ["owner", "admin", "prod"],
              },
              {
                text: "Beli / Jual",
                mainpath: "/app/sales",
                path: "/app/sales/daftarjual",
                roles: ["owner", "admin"],
              },
              {
                text: "Produksi",
                mainpath: "/app/production",
                path: "/app/production/daftarproduksi",
                roles: ["owner", "admin", "prod"],
              },
              {
                text: "Keuangan",
                mainpath: "/app/finance",
                path: "/app/finance/incomestatement",
                roles: ["owner"],
              },
              {
                text: "Manajemen data",
                mainpath: "/app/management",
                path: "/app/management/customer",
                roles: ["owner", "admin"],
              },
            ].map((menu) =>
              menu.roles.includes(tokenPayload.role) ? (
                <Button
                  size="large"
                  component={Link}
                  to={menu.path}
                  className={
                    isSelected(location.pathname, menu.mainpath)
                      ? classes.toolbarButtonSelected
                      : classes.toolbarButton
                  }
                >
                  {menu.text}
                </Button>
              ) : null
            )}
            <div className={classes.appBarPadding} />
            <UserMenu
              displayName={tokenPayload.name}
              // displayName="Mulia Box"
            />
          </div>
        </Toolbar>
      </AppBar>
      <div id="AppContent" className={classes.content}>
        <Outlet />
        <Copyright />
      </div>
    </div>
  );
};

const UserMenu = ({ displayName }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSignout = () => {
    // console.log(localStorage.getItem("ASID"));
    axios
      .post(
        process.env.REACT_APP_WEBCORE_URI + "/accounts/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        localStorage.setItem("ASID", null);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        localStorage.setItem("ASID", null);
        navigate("/");
      });
  };

  return (
    <div>
      <Button
        size="large"
        // style={{ color: "white" }}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {displayName}
        <Avatar className={classes.avatarIcon}>
          <PersonIcon />
        </Avatar>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem component={Link} to="/app/user/profile">
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleSignout}>Sign out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AppLayout;
