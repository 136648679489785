import React, { useState, useEffect } from "react";
import axios from "axios";
import format from "date-fns/format";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import CloseIcon from "@material-ui/icons/Close";
import SnackbarAlert from "../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
}));

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x
  ${params.getValue(params.id, "width") || ""} x ${
    params.getValue(params.id, "height") || ""
  }`;
};

const DialogHapusEmployee = ({
  open,
  handleClose,
  handleRefresh,
  employeeId,
}) => {
  const classes = useStyles();

  const [employeeDetail, setEmployeeDetail] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const api_getEmployeeDetail = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/employees/" + employeeId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setEmployeeDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_deleteEmployee = () => {
    axios
      .delete(process.env.REACT_APP_MBOXBE_URI + "/employees/" + employeeId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Data pegawai berhasil dihapus",
        });
        handleClose();
        handleRefresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    if (employeeId) {
      api_getEmployeeDetail();
    }
  }, [employeeId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Hapus data pegawai
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Alert severity="error">
            <AlertTitle>
              <strong>
                Apakah Anda yakin untuk menghapus data Pegawai ini?
              </strong>
            </AlertTitle>
          </Alert>
          <Typography style={{ marginTop: 16, marginBottom: 16 }}>
            <Grid container spacing={1}>
              {[
                { f: "Nama karyawan", v: employeeDetail.fullName },
                {
                  f: "Tanggal mulai bekerja",
                  v: employeeDetail.joinedAt
                    ? format(new Date(employeeDetail.joinedAt), "dd-MMM-yyyy")
                    : null,
                },
                // { f: "List pesanan", v: "" },
              ].map(({ f, v }) => (
                <>
                  <Grid item xs={4}>
                    <strong>{f}</strong>
                  </Grid>
                  <Grid item xs={8}>
                    {v}
                  </Grid>
                </>
              ))}
            </Grid>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Tidak</Button>
          <Button
            onClick={api_deleteEmployee}
            color="primary"
            variant="contained"
          >
            Ya
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default DialogHapusEmployee;
