import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from "@material-ui/icons/GetApp";
import IncomeStatementChart from "./IncomeStatementChart";
import IncomeStatementDetail from "./IncomeStatementDetail";

// import TabelListBeli from "./TabelListBeli";
// import FormPO from "./FormPO";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const IncomeStatementView = () => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);
  const [detailMode, setDetailMode] = useState(true);

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleToggleDetailMode = () => {
    setDetailMode(!detailMode);
  };

  return (
    <div>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Income statement
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 16 }}
          onClick={handleToggleDetailMode}
        >
          {detailMode ? "Perlihatkan grafik" : "Perlihatkan detail"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={handleOpenAdd}
        >
          Download
        </Button>
      </div>
      {/* <FormPO isAdd={true} open={openAdd} handleClose={handleCloseAdd} />
      <TabelListBeli /> */}
      {detailMode ? <IncomeStatementDetail /> : <IncomeStatementChart />}
    </div>
  );
};

export default IncomeStatementView;
