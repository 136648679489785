import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import format from "date-fns/format";
import { DataGrid } from "@mui/x-data-grid";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";

import TambahBarangJadi from "./TambahBarangJadi";
import EditBarangJadi from "./EditBarangJadi";
import RefreshButton from "../../../coreui/RefreshButton";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x
  ${params.getValue(params.id, "width") || ""} x ${
    params.getValue(params.id, "height") || ""
  }`;
};

const TabelStokJadi = () => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);
  const [grouped, setGrouped] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [boxInventory, setBoxInventory] = useState([]);

  const columns = [
    grouped ? {} : { field: "cust", headerName: "Customer", width: 150 },
    { field: "name", headerName: "Nama item", width: 170 },
    {
      field: "size",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 180 },
    { field: "fluteN", headerName: "Flute", width: 110 },
    { field: "qty", headerName: "Jumlah", type: "number", width: 120 },
    {
      field: "lastUpdated",
      headerName: "Terakhir di-update",
      width: 150,
      renderCell: ({ value }) => (
        <div>{format(new Date(value), "dd-MMM-yyyy, HH:mm")}</div>
      ),
    },
    grouped
      ? {}
      : {
          field: "detail",
          headerName: " ",
          width: 100,
          renderCell: (GridCellParams) => (
            <Button
              color="primary"
              onClick={() => handleClickOpenEdit(GridCellParams.row.id)}
            >
              Detail
            </Button>
          ),
        },
  ];

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleToggleGroup = () => {
    setGrouped(!grouped);
  };

  const handleClickOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const api_getBoxInventory = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/boxinventory", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setBoxInventory([]); // force re-render here
        setBoxInventory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    api_getBoxInventory();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Stok barang jadi
          <RefreshButton
            style={{ marginLeft: 16 }}
            handleClick={api_getBoxInventory}
          />
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 16 }}
          onClick={handleToggleGroup}
          disabled
        >
          {grouped ? "Perlihatkan customer" : "Kelompokkan customer"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClickOpenAdd}
        >
          Tambah stok
        </Button>
      </div>
      <div style={{ height: "80%", width: "100%" }}>
        <DataGrid
          // rows={grouped ? rows_grouped : rows}
          rows={boxInventory}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
        />
      </div>
      <TambahBarangJadi
        open={openAdd}
        handleClose={handleCloseAdd}
        handleRefresh={api_getBoxInventory}
      />
      <EditBarangJadi
        open={openEdit.status}
        boxInventoryId={openEdit.id}
        handleClose={handleCloseEdit}
        handleRefresh={api_getBoxInventory}
      />
    </div>
  );
};

export default TabelStokJadi;
