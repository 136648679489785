import "./App.css";

import React, { useState, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import VerifyToken from "./utils/jwtUtil";

function checkToken() {
  let { valid, payload } = VerifyToken(localStorage.getItem("ASID"));
  // return token == null ? false : token === "null" ? false : true;
  return { valid, payload };
}

function App() {
  var { valid, payload } = checkToken();
  const [isLoggedIn, setIsLoggedIn] = useState(valid);
  const [tokenPayload, setTokenPayload] = useState(payload);

  useEffect(() => {
    // var { valid, payload } = checkToken();
    // console.log({ valid });
    // console.log({ payload });
    setIsLoggedIn(valid);
    setTokenPayload(payload);
    console.log("state=", isLoggedIn);
    // console.log("storage=", checkToken());
    // console.log("storage=", localStorage.getItem("ASID"));
  }, [valid]);

  const routing = useRoutes(routes(isLoggedIn, tokenPayload));
  return <div style={{ height: "100%" }}>{routing}</div>;
}

export default App;
