import React, { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import { fluteTypesConst, boxTypesConst } from "../../../../constants";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const TambahFavoriteBox = ({ open, favBoxId, handleClose, handleRefresh }) => {
  const classes = useStyles();

  const [itemName, setItemName] = useState("");
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [flute, setFlute] = useState("");
  const [paper, setPaper] = useState("");
  const [fluteTypes, setFluteTypes] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [fieldsValid, setFieldsValid] = useState({
    itemName: true,
    length: true,
    width: true,
    height: true,
    flute: true,
    paper: true,
    // sablon: true,
    // pondKnife: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const handleChangeFlute = (event) => {
    setFlute(event.target.value);
  };

  const handleChangePaper = (event) => {
    setPaper(event.target.value);
  };

  const api_getFavoriteBox = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI + "/favorite_boxinventory/" + favBoxId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setItemName(res.data.name);
        setLength(res.data.length);
        setWidth(res.data.width);
        setHeight(res.data.height);
        setFlute(res.data.flute);
        setPaper(res.data.paper);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_updateFavoriteBox = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("name", itemName);
    newData.append("length", length);
    newData.append("width", width);
    newData.append("height", height);
    newData.append("flute", flute);
    newData.append("paper", paper);

    // hit the API
    axios
      .put(
        process.env.REACT_APP_MBOXBE_URI + "/favorite_boxinventory/" + favBoxId,
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Perubahan pesanan favorit berhasil disimpan",
        });
        setItemName("");
        setLength(0);
        setWidth(0);
        setHeight(0);
        setFlute("");
        setPaper("");

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getFluteTypes = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/flutetypes", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setFluteTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getPaperTypes = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/papertypes", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setPaperTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      itemName: itemName !== "",
      length: length != null,
      width: width != null,
      height: height != null,
      flute: flute !== "",
      paper: paper !== "",
      // sablon: sablon != null,
      // pondKnife: pondKnife != null,
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    api_getFluteTypes();
    api_getPaperTypes();
  }, []);

  useEffect(() => {
    if (favBoxId) {
      api_getFavoriteBox();
    }
  }, [favBoxId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Ubah item penjualan favorit
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            Silahkan mengubah detail item di bawah ini (bila diperlukan)
          </DialogContentText>
          <Typography className={classes.formSubsection}>
            Informasi item
          </Typography>
          <TextField
            variant="outlined"
            margin="dense"
            id="item"
            label="Nama item pesanan"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            fullWidth
            required
            error={!fieldsValid.itemName}
            helperText={
              !fieldsValid.itemName ? "Field ini tidak boleh kosong" : null
            }
          />
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="length"
                label="Panjang (cm)"
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.length}
                helperText={
                  !fieldsValid.length ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="width"
                label="Lebar (cm)"
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.width}
                helperText={
                  !fieldsValid.width ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="height"
                label="Tinggi (cm)"
                type="number"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.height}
                helperText={
                  !fieldsValid.height ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                label="Jenis kertas"
                id="select-paper"
                value={paper}
                onChange={handleChangePaper}
                required
                error={!fieldsValid.paper}
                helperText={
                  !fieldsValid.paper ? "Field ini tidak boleh kosong" : null
                }
              >
                {paperTypes.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                label="Flute"
                id="select-flute"
                value={flute}
                onChange={handleChangeFlute}
                required
                error={!fieldsValid.flute}
                helperText={
                  !fieldsValid.flute ? "Field ini tidak boleh kosong" : null
                }
              >
                {fluteTypes.map((item) => (
                  <MenuItem value={item}>{fluteTypesConst[item]}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button
            variant="outlined"
            onClick={api_updateFavoriteBox}
            color="primary"
          >
            Tambah item
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default TambahFavoriteBox;
