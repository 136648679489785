import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";

import CetakSuratJalan from "./CetakSuratJalan";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(2),
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  cardStyle: {
    marginBottom: 8,
    borderLeft: "4px solid",
    borderColor: theme.palette.primary.light,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const HistoriKirimBarang = ({ deliveryHistory }) => {
  const classes = useStyles();

  const [openPrintSj, setOpenPrintSj] = useState({ status: false, id: null });

  const formatSizeString = (length, width, height) => {
    return ` (${length} x ${width} x ${height})`;
  };

  const handleOpenPrintSj = (id) => {
    setOpenPrintSj({ status: true, id });
  };

  const handleClosePrintSj = () => {
    setOpenPrintSj({ status: false, id: null });
  };

  return (
    <div style={{ marginTop: 16 }}>
      {/* <Typography className={classes.formSubsection}>
        Daftar pengiriman barang
      </Typography> */}
      <CetakSuratJalan
        open={openPrintSj.status}
        deliveryId={openPrintSj.id}
        handleClose={handleClosePrintSj}
      />
      {deliveryHistory.map(
        ({ id, deliveryDate, deliveryDocNumber, details }) => (
          <Card className={classes.cardStyle}>
            <CardContent>
              <div className={classes.flexCenter}>
                <div style={{ marginRight: 40 }}>
                  <Typography>
                    <strong style={{ marginRight: 16 }}>
                      No. Surat Jalan &emsp;&emsp;:
                    </strong>
                    {deliveryDocNumber}
                  </Typography>
                  <Typography>
                    <strong style={{ marginRight: 16 }}>
                      Tanggal pengiriman :
                    </strong>
                    {format(new Date(deliveryDate), "dd-MMM-yyyy, HH:mm")}
                  </Typography>
                </div>
                {/* <Typography color="primary" style={{ marginRight: 24 }}>
                  {deliveryDocNumber} <br />
                  <strong>
                    {format(new Date(deliveryDate), "dd-MMM-yyyy, HH:mm")}
                  </strong>
                </Typography> */}
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenPrintSj(id)}
                  >
                    Cetak surat jalan
                  </Button>
                </div>
              </div>
              <List dense>
                {details.map(
                  ({ id, name, length, width, height, paper, fluteN, qty }) => (
                    <ListItem divider>
                      <ListItemText style={{ flex: 1 }}>
                        {name}
                        {formatSizeString(length, width, height)}
                      </ListItemText>
                      <ListItemText style={{ flex: 3 }}>
                        <Chip label={<>{qty} unit</>} color="primary" />
                      </ListItemText>
                    </ListItem>
                  )
                )}
              </List>
            </CardContent>
          </Card>
        )
      )}
    </div>
  );
};

export default HistoriKirimBarang;
