import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DetailHistoriBaku = ({ id, open, handleClose }) => {
  const classes = useStyles();

  const data = {
    // "masuk", "keluar" --> currently hardcoded with name id
    // so that it is easier when we replace with actual API call /history/{id}
    inout: id,
    supplier: "Supplier A",
    nosurat: "MB/090921/I",
    panjang: 1940,
    lebar: 1350,
    kertas: "125K/125M/125M/125M/125M",
    flute: "BCF",
    qty: 254,
    harga: 1000,
    date: "08-Sep-2021",
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <div className={classes.flexCenter}>
            <div
              style={{
                flexGrow: 1,
                color: data.inout === "masuk" ? "green" : "red",
              }}
            >
              {data.inout === "masuk"
                ? "Detail bahan baku masuk"
                : "Detail bahan baku keluar"}
            </div>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {data.inout === "masuk" ? (
              <DetailMasuk data={data} />
            ) : (
              <DetailKeluar data={data} />
            )}
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

const DetailMasuk = ({ data }) => {
  return (
    <Grid container spacing={1}>
      {[
        { f: "Tanggal barang datang", v: data.date },
        { f: "No surat jalan", v: data.nosurat },
        { f: "Supplier", v: data.supplier },
        { f: "Ukuran", v: `${data.panjang} x ${data.lebar}` },
        { f: "Jenis kertas", v: data.kertas },
        { f: "Gelombang", v: data.flute },
        { f: "Jumlah (lembar)", v: data.qty },
        { f: "Harga/lembar", v: data.harga },
        { f: "Harga total", v: data.qty * data.harga },
        { f: "Beban pajak", v: data.qty * data.harga * 0.1 },
      ].map(({ f, v }) => (
        <>
          <Grid item xs={6}>
            <strong>{f}</strong>
          </Grid>
          <Grid item xs={6}>
            {v}
          </Grid>
        </>
      ))}
    </Grid>
  );
};

const DetailKeluar = ({ data }) => {
  return (
    <Grid container spacing={1}>
      {[
        { f: "Tanggal barang keluar", v: data.date },
        { f: "Untuk pesanan", v: data.supplier },
        { f: "Ukuran", v: `${data.panjang} x ${data.lebar}` },
        { f: "Jenis kertas", v: data.kertas },
        { f: "Gelombang", v: data.flute },
        { f: "Lembar terpakai", v: data.qty },
        { f: "Harga/lembar", v: data.harga },
        { f: "Harga total", v: data.qty * data.harga },
      ].map(({ f, v }) => (
        <>
          <Grid item xs={6}>
            <strong>{f}</strong>
          </Grid>
          <Grid item xs={6}>
            {v}
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default DetailHistoriBaku;
