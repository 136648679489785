import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import axios from "axios";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { revenueData, expenseData } from "../../../../static/incomeStatement";

// am4core.useTheme(am4themes_animated);

const LineChartAM = () => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = am4core.create("incomeStatementTimeSeries", am4charts.XYChart);

    x.paddingRight = 20;
    x.colors.step = 3;

    // Create x axis
    var dateAxis = x.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 100;

    // data grouping for large dataset
    // dateAxis.groupData = true;
    // dateAxis.groupCount = 200;

    // Create y axis
    let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.title.text = configs.yLabel.show ? configs.yLabel.text : "";

    /*
    Functions
    */
    // Create series
    function createSeries(seriesName, data) {
      var series = x.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = "value";
      series.dataFields.dateX = "date";
      series.name = seriesName;
      series.tooltipText = "[bold]{valueY}[/]";
      series.strokeWidth = 1;
      series.data = data;
      series.minBulletDistance = 40;

      var bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.stroke = am4core.color("#fff");
      bullet.circle.strokeWidth = 1;

      return series;
    }

    // createSeries("series name here", sampleData);
    createSeries("Pemasukan", revenueData);
    createSeries("Pengeluaran", expenseData);

    // Set up legends
    x.legend = new am4charts.Legend();
    x.cursor = new am4charts.XYCursor();

    // Add scrollbar
    x.scrollbarX = new am4core.Scrollbar();
    x.scrollbarX.marginBottom = 20;
    x.scrollbarX.marginTop = 0;

    // Set up export
    x.exporting.menu = new am4core.ExportMenu();
    x.exporting.menu.align = "left";
    x.exporting.menu.verticalAlign = "bottom";
    x.exporting.adapter.add("data", function (data, target) {
      // Assemble data from series
      var data = [];
      x.series.each(function (series) {
        for (var i = 0; i < series.data.length; i++) {
          series.data[i].name = series.name;
          data.push(series.data[i]);
        }
      });
      // console.log(data);
      return { data: data };
    });

    chart.current = x;

    return () => {
      x.dispose();
    };
  });

  return (
    <div
      id="incomeStatementTimeSeries"
      style={{ width: "100%", height: "100%" }}
    >
      {/* {console.log("<LineChartAM> rendering")} */}
    </div>
  );
};

export default LineChartAM;
