import React from "react";
import { Page, Document, Image, StyleSheet, Font } from "@react-pdf/renderer";

import POHeader from "./POHeader";
import POBillTo from "./POBillTo";
import POTable from "./POTable";
import POFooter from "./POFooter";

import fontSaxMono from "../../../../constants/saxmono-Font/saxmono.ttf";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

Font.register({
  family: "SaxMono",
  fonts: [{ src: fontSaxMono }],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "SaxMono",
    fontSize: 9,
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.5,
    flexDirection: "column",
  },
});

const POMain = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <POHeader
        purchaseNo={data && data.purchaseNo ? data.purchaseNo : ""}
        purchaseDate={data && data.purchaseDate ? data.purchaseDate : ""}
      />
      <POBillTo
        vendor={data && data.vendor ? data.vendor : ""}
        vendorAddress={data && data.vendorAddress ? data.vendorAddress : ""}
        paymentTerm={data && data.paymentTerm ? data.paymentTerm : ""}
        purchaser={data && data.purchaser ? data.purchaser : ""}
        npwp={data && data.npwp ? data.npwp : ""}
      />
      <POTable
        items={data && data.items && data.items.length > 0 ? data.items : []}
        totalPrice={data && data.totalPrice ? data.totalPrice : 0}
      />
      <POFooter />
    </Page>
  </Document>
);

export default POMain;
