import React, { useState, useEffect } from "react";
import axios from "axios";
import format from "date-fns/format";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { paymentTermsDropdown } from "../../../../static/dropdowns";
import ListProdukJual from "./ListProdukJual";
import FormCustomerLite from "./FormCustomerLite";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const FormJual = ({ isAdd, open, salesId, handleClose, handleRefresh }) => {
  const classes = useStyles();

  const [openAddCust, setOpenAddCust] = useState(false);
  const [salesNo, setSalesNo] = useState("");
  const [salesDate, setSalesDate] = useState(new Date());
  const [cust, setCust] = useState({
    id: "",
    companyName: "",
    picName: "",
    address: "",
    pkp: false,
  });
  // const [mboxAddress, setMboxAddress] = useState("Jl. Terusan Pasirkoja");
  const [paymentTerm, setPaymentTerm] = useState("");
  const [custPoNo, setCustPoNo] = useState("");
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [ppn, setPpn] = useState(false);
  const [notes, setNotes] = useState("");
  const [otherFeeName, setOtherFeeName] = useState("");
  const [otherFee, setOtherFee] = useState(0);
  const [downPayment, setDownPayment] = useState(0);
  const [downPaymentNote, setDownPaymentNote] = useState("");
  const [customers, setCustomers] = useState([]);
  const [fieldsValid, setFieldsValid] = useState({
    salesDate: true,
    cust: true,
    paymentTerm: true,
    deliveryFee: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const handleChangeCustomer = (event) => {
    if (customers.length > 0) {
      var id = event.target.value;
      var cust = customers.find((customer) => customer.id === id);
      setCust(cust);
    }
  };

  const handleOpenAddCust = () => {
    setOpenAddCust(true);
  };

  const handleCloseAddCust = () => {
    setOpenAddCust(false);
  };

  const api_getSalesDetail = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/sales/" + salesId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        var cust = customers.find(
          (customer) => customer.id === res.data.custId
        );
        setCust(cust);
        setSalesNo(res.data.salesNo);
        setSalesDate(new Date(res.data.salesDate));
        setDeliveryFee(res.data.deliveryFee);
        setPpn(res.data.ppn);
        setPaymentTerm(res.data.paymentTerm);
        setCustPoNo(res.data.custPoNo === "null" ? "" : res.data.custPoNo);
        setNotes(res.data.notes);
        setDownPayment(res.data.downPayment);
        setDownPaymentNote(res.data.downPaymentNote);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_createSales = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    // newData.append("salesDate", salesDate.toISOString());
    newData.append("salesDate", format(salesDate, "yyyy-MM-dd"));
    newData.append("custId", cust.id);
    newData.append("deliveryFee", deliveryFee);
    newData.append("ppn", ppn);
    newData.append("paymentTerm", paymentTerm);
    newData.append("custPoNo", custPoNo);
    newData.append("notes", notes);
    newData.append("otherFeeName", otherFeeName);
    newData.append("otherFee", otherFee);
    newData.append("downPayment", downPayment);
    newData.append("downPaymentNote", downPaymentNote);

    // hit the API
    axios
      .post(process.env.REACT_APP_MBOXBE_URI + "/sales", newData, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({ open: true, message: "Sales Order berhasil dibuat" });
        setSalesDate(new Date());
        setCust({
          id: "",
          companyName: "",
          picName: "",
          address: "",
          pkp: false,
        });
        setDeliveryFee(0);
        setPpn("");
        setPaymentTerm("");
        setCustPoNo("");
        setNotes("");

        handleRefresh(res.data.sales);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_updateSales = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    // newData.append("salesDate", salesDate.toISOString());
    newData.append("salesDate", format(salesDate, "yyyy-MM-dd"));
    newData.append("custId", cust.id);
    newData.append("deliveryFee", deliveryFee);
    newData.append("ppn", ppn);
    newData.append("paymentTerm", paymentTerm);
    newData.append("custPoNo", custPoNo);
    newData.append("notes", notes);
    newData.append("otherFeeName", otherFeeName);
    newData.append("otherFee", otherFee);
    newData.append("downPayment", downPayment);
    newData.append("downPaymentNote", downPaymentNote);

    // hit the API
    axios
      .put(process.env.REACT_APP_MBOXBE_URI + "/sales/" + salesId, newData, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Perubahan pada Sales Order berhasil disimpan",
        });
        setSalesDate(new Date());
        setCust({ id: "", companyName: "", picName: "", address: "" });
        setDeliveryFee(0);
        setPpn("");
        setPaymentTerm("");
        setCustPoNo("");
        setNotes("");

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getCustomers = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/customers", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setCustomers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      salesDate: salesDate != null,
      cust: cust.id !== "",
      paymentTerm: paymentTerm !== "",
      deliveryFee: deliveryFee >= 0,
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    // api_getFluteTypes();
    // api_getPaperTypes();
    api_getCustomers();
    if (!isAdd && salesId) api_getSalesDetail();
  }, [salesId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={!isAdd}
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {isAdd
                ? "Buat Penjualan Baru"
                : `Detail Penjualan (No. ${salesNo})`}
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DialogContent>
            <Typography className={classes.formSubsection}>
              Tanggal penjualan
            </Typography>
            <DatePicker
              inputVariant="outlined"
              margin="dense"
              // label="Tanggal penjualan"
              value={salesDate}
              onChange={setSalesDate}
              animateYearScrolling
              format="dd-MMM-yyyy"
              fullWidth
            />
            <div className={classes.flexCenter}>
              <Typography
                className={classes.formSubsection}
                style={{ flexGrow: 1 }}
              >
                Informasi customer
              </Typography>
              <Tooltip title="Tambah customer baru">
                <IconButton
                  size="small"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleOpenAddCust}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </div>
            <FormCustomerLite
              isAdd={true}
              open={openAddCust}
              handleClose={handleCloseAddCust}
              handleRefresh={api_getCustomers}
            />
            <TextField
              select
              fullWidth
              variant="outlined"
              margin="dense"
              label="Nama perusahaan customer"
              id="select-cust"
              value={cust.id}
              onChange={handleChangeCustomer}
              required
              error={!fieldsValid.cust}
              helperText={
                !fieldsValid.cust ? "Field ini tidak boleh kosong" : null
              }
            >
              {customers.map((item) => (
                <MenuItem value={item.id}>
                  {item.companyName} {item.pkp ? "*" : ""}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="outlined"
              margin="dense"
              id="vendorPic"
              label="Nama PIC customer"
              value={cust.picName}
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              id="vendorAddress"
              label="Alamat customer"
              value={cust.address}
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              id="custPoNo"
              label="No. PO customer (opsional)"
              value={custPoNo}
              onChange={(e) => setCustPoNo(e.target.value)}
              fullWidth
            />
            <TextField
              variant="outlined"
              margin="dense"
              id="notes"
              label="Catatan (opsional)"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              fullWidth
            />
            <Typography className={classes.formSubsection}>
              Pembayaran & pengiriman
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <TextField
                  select
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label="Term pembayaran"
                  id="select-vendor"
                  value={paymentTerm}
                  onChange={(e) => setPaymentTerm(e.target.value)}
                  required
                  error={!fieldsValid.paymentTerm}
                  helperText={
                    !fieldsValid.paymentTerm
                      ? "Field ini tidak boleh kosong"
                      : null
                  }
                >
                  {paymentTermsDropdown.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs>
                <NumberFormat
                  variant="outlined"
                  margin="dense"
                  id="deliveryFee"
                  label="Biaya pengiriman"
                  value={deliveryFee}
                  customInput={TextField}
                  prefix={"Rp"}
                  type="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  onValueChange={(values) => {
                    setDeliveryFee(values.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={ppn}
                      onChange={(e) => setPpn(e.target.checked)}
                      name="includePPN"
                      color="primary"
                      size="small"
                    />
                  }
                  label="Tambah PPN (11%)"
                  style={{ marginLeft: 2 }}
                />
              </Grid>
            </Grid>
            <Typography className={classes.formSubsection}>
              Biaya lain-lain
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="otherFeeName"
                  label="Nama biaya"
                  value={otherFeeName}
                  onChange={(e) => setOtherFeeName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs>
                <NumberFormat
                  variant="outlined"
                  margin="dense"
                  id="otherFee"
                  label="Nominal"
                  value={otherFee}
                  customInput={TextField}
                  prefix={"Rp"}
                  type="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  onValueChange={(values) => {
                    setOtherFee(values.value);
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Typography className={classes.formSubsection}>
              Pembayaran DP
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <NumberFormat
                  variant="outlined"
                  margin="dense"
                  id="downPayment"
                  label="Nominal DP"
                  value={downPayment}
                  customInput={TextField}
                  prefix={"Rp"}
                  type="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  onValueChange={(values) => {
                    setDownPayment(values.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  margin="dense"
                  id="downPaymentNote"
                  label="Catatan terkait DP"
                  value={downPaymentNote}
                  onChange={(e) => setDownPaymentNote(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <div style={{ marginBottom: 8 }} />
            {!isAdd && salesId ? (
              <div>
                <Typography className={classes.formSubsection}>
                  Total
                </Typography>
                <ListProdukJual
                  salesId={salesId}
                  deliveryFee={deliveryFee}
                  includePPN={ppn}
                  otherFee={otherFee}
                  custId={cust.id}
                />
              </div>
            ) : null}
          </DialogContent>
        </MuiPickersUtilsProvider>
        <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
          <Button
            onClick={isAdd ? api_createSales : api_updateSales}
            variant="contained"
            color="primary"
          >
            {isAdd ? "Buat Penjualan" : "Simpan"}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default FormJual;
