import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import CloseIcon from "@material-ui/icons/Close";

import { educationsConst } from "../../../../constants";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const FormEmployee = ({
  isAdd,
  open,
  employeeId,
  handleClose,
  handleRefresh,
}) => {
  const classes = useStyles();

  const [fullName, setFullName] = useState("");
  const [joinedAt, setJoinedAt] = useState(new Date());
  const [birthPlace, setBirthPlace] = useState("");
  const [birthDate, setBirthDate] = useState(new Date(1990, 0, 1));
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [education, setEducation] = useState("");
  const [position, setPosition] = useState("");
  const [fieldsValid, setFieldsValid] = useState({
    fullName: true,
    joinedAt: true,
    birthPlace: true,
    birthDate: true,
    address: true,
    phone: true,
    position: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const api_getEmployeeDetail = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/employees/" + employeeId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        console.log(res.data);
        setFullName(res.data.fullName);
        setJoinedAt(res.data.joinedAt ? new Date(res.data.joinedAt) : null);
        setBirthPlace(res.data.birthPlace);
        setBirthDate(res.data.birthDate ? new Date(res.data.birthDate) : null);
        setAddress(res.data.address);
        setPhone(res.data.phone);
        setEducation(res.data.education);
        setPosition(res.data.position);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_createEmployee = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    // newData.append("salesDate", salesDate.toISOString());
    newData.append("fullName", fullName);
    newData.append("joinedAt", joinedAt.toISOString());
    newData.append("birthPlace", birthPlace);
    newData.append("birthDate", birthDate.toISOString());
    newData.append("address", address);
    newData.append("phone", phone);
    newData.append("education", education);
    newData.append("position", position);

    // hit the API
    axios
      .post(process.env.REACT_APP_MBOXBE_URI + "/employees", newData, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Informasi karyawan berhasil ditambahkan",
        });
        setFullName("");
        setJoinedAt(new Date());
        setBirthPlace("");
        setBirthDate(new Date(2000, 0, 1));
        setAddress("");
        setPhone("");
        setEducation("");
        setPosition("");

        handleRefresh(res.data.employees);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_updateEmployee = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("fullName", fullName);
    newData.append("joinedAt", joinedAt.toISOString());
    newData.append("birthPlace", birthPlace);
    newData.append("birthDate", birthDate.toISOString());
    newData.append("address", address);
    newData.append("phone", phone);
    newData.append("education", education);
    newData.append("position", position);

    // hit the API
    axios
      .put(
        process.env.REACT_APP_MBOXBE_URI + "/employees/" + employeeId,
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Perubahan informasi karyawan berhasil disimpan",
        });
        setFullName("");
        setJoinedAt(new Date());
        setBirthPlace("");
        setBirthDate(new Date(2000, 0, 1));
        setAddress("");
        setPhone("");
        setEducation("");
        setPosition("");

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      fullName: fullName !== "",
      joinedAt: joinedAt != null,
      birthPlace: birthPlace !== "",
      birthDate: birthDate != null,
      address: address !== "",
      phone: phone !== "",
      position: position !== "",
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    if (!isAdd && employeeId) api_getEmployeeDetail();
  }, [employeeId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={isAdd ? "sm" : "sm"}
        fullWidth
        // fullScreen={!isAdd}
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {isAdd ? "Tambah Karyawan Baru" : "Edit Karyawan"}
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DialogContent>
            <Typography className={classes.formSubsection}>
              Informasi karyawan
            </Typography>
            <TextField
              variant="outlined"
              margin="dense"
              id="fullName"
              label="Nama karyawan"
              value={fullName || ""}
              fullWidth
              onChange={(e) => setFullName(e.target.value)}
              required
              error={!fieldsValid.fullName}
              helperText={
                !fieldsValid.fullName ? "Field ini tidak boleh kosong" : null
              }
            />
            <DatePicker
              inputVariant="outlined"
              margin="dense"
              label="Tanggal mulai bekerja"
              value={joinedAt}
              onChange={setJoinedAt}
              animateYearScrolling
              format="dd-MMM-yyyy"
              fullWidth
              required
              error={!fieldsValid.joinedAt}
              helperText={
                !fieldsValid.joinedAt ? "Field ini tidak boleh kosong" : null
              }
            />
            <TextField
              variant="outlined"
              margin="dense"
              id="birthPlace"
              label="Tempat lahir"
              value={birthPlace || ""}
              fullWidth
              onChange={(e) => setBirthPlace(e.target.value)}
              required
              error={!fieldsValid.birthPlace}
              helperText={
                !fieldsValid.birthPlace ? "Field ini tidak boleh kosong" : null
              }
            />
            <DatePicker
              inputVariant="outlined"
              margin="dense"
              label="Tanggal lahir"
              value={birthDate}
              onChange={setBirthDate}
              animateYearScrolling
              format="dd-MMM-yyyy"
              fullWidth
              required
              error={!fieldsValid.birthDate}
              helperText={
                !fieldsValid.birthDate ? "Field ini tidak boleh kosong" : null
              }
            />
            <TextField
              variant="outlined"
              margin="dense"
              id="address"
              label="Alamat tinggal"
              value={address || ""}
              fullWidth
              onChange={(e) => setAddress(e.target.value)}
              required
              error={!fieldsValid.address}
              helperText={
                !fieldsValid.address ? "Field ini tidak boleh kosong" : null
              }
            />
            <TextField
              variant="outlined"
              margin="dense"
              id="phone"
              label="No. telp"
              value={phone || ""}
              fullWidth
              onChange={(e) => setPhone(e.target.value)}
              required
              error={!fieldsValid.phone}
              helperText={
                !fieldsValid.phone ? "Field ini tidak boleh kosong" : null
              }
            />
            <TextField
              select
              variant="outlined"
              margin="dense"
              id="education"
              label="Pendidikan terakhir"
              value={education || ""}
              fullWidth
              onChange={(e) => setEducation(e.target.value)}
            >
              {educationsConst.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </TextField>
            <TextField
              variant="outlined"
              margin="dense"
              id="position"
              label="Jabatan"
              value={position || ""}
              fullWidth
              onChange={(e) => setPosition(e.target.value)}
              required
              error={!fieldsValid.position}
              helperText={
                !fieldsValid.position ? "Field ini tidak boleh kosong" : null
              }
            />
          </DialogContent>
        </MuiPickersUtilsProvider>

        <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
          <Button
            onClick={isAdd ? api_createEmployee : api_updateEmployee}
            variant="contained"
            color="primary"
          >
            {isAdd ? "Tambah Karyawan" : "Simpan"}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default FormEmployee;
