import { Navigate } from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
import AppLayout from "./layouts/AppLayout";
import InventoryLayout from "./layouts/InventoryLayout";
import SalesLayout from "./layouts/SalesLayout";
import ProductionLayout from "./layouts/ProductionLayout";
import FinanceLayout from "./layouts/FinanceLayout";
import ManagementLayout from "./layouts/ManagementLayout";

import StokBahanBakuView from "./views/inventory/StokBahanBakuView";
import HistoriBahanBakuView from "./views/inventory/HistoriBahanBakuView";
import StokBarangJadiView from "./views/inventory/StokBarangJadiView";
import HistoriBarangJadiView from "./views/inventory/HistoriBarangJadiView";

import FormPOView from "./views/sales/FormPOView";
import SuratTerimaBarangView from "./views/sales/SuratTerimaBarangView";
import DaftarBeliView from "./views/sales/DaftarBeliView";
import FormJualView from "./views/sales/FormJualView";
import DaftarJualView from "./views/sales/DaftarJualView";

import DaftarProduksiView from "./views/production/DaftarProduksiView";
import ListPekerjaanView from "./views/production/ListPekerjaanView";

import IncomeStatementView from "./views/finance/IncomeStatementView";
import KasKecilView from "./views/finance/KasKecilView";
import PenagihanView from "./views/finance/PenagihanView";
import ManajemenBiayaView from "./views/finance/ManajemenBiayaView";

import ManageVendorView from "./views/management/ManageVendorView";
import ManageCustomerView from "./views/management/ManageCustomerView";
import ManageEmployeeView from "./views/management/ManageEmployeeView";
import SignInView from "./views/login/SignInView";

const routes = (isLoggedIn, tokenPayload) => [
  {
    path: "/",
    element: <MainLayout isLoggedIn={isLoggedIn} tokenPayload={tokenPayload} />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "login", element: <SignInView /> },
    ],
  },
  {
    path: "app",
    element: isLoggedIn ? (
      <AppLayout tokenPayload={tokenPayload} />
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      // { path: "/", element: <Navigate to="/app/inventory/stokbaku" /> },
      {
        path: "inventory",
        element: <InventoryLayout tokenPayload={tokenPayload} />,
        children: [
          { path: "stokbaku", element: <StokBahanBakuView /> },
          { path: "historibaku", element: <HistoriBahanBakuView /> },
          { path: "stokjadi", element: <StokBarangJadiView /> },
          { path: "historijadi", element: <HistoriBarangJadiView /> },
        ],
      },
      {
        path: "sales",
        element:
          tokenPayload &&
          (tokenPayload.role === "owner" || tokenPayload.role === "admin") ? (
            <SalesLayout tokenPayload={tokenPayload} />
          ) : (
            <Navigate to="/app/inventory/stokbaku" />
          ),
        children: [
          { path: "formbeli", element: <FormPOView /> },
          { path: "terimabarang", element: <SuratTerimaBarangView /> },
          { path: "daftarbeli", element: <DaftarBeliView /> },
          { path: "formjual", element: <FormJualView /> },
          { path: "daftarjual", element: <DaftarJualView /> },
        ],
      },
      {
        path: "production",
        element: <ProductionLayout />,
        children: [
          { path: "listpekerjaan", element: <ListPekerjaanView /> },
          {
            path: "daftarproduksi",
            element: <DaftarProduksiView tokenPayload={tokenPayload} />,
          },
        ],
      },
      {
        path: "finance",
        element:
          tokenPayload && tokenPayload.role === "owner" ? (
            <FinanceLayout tokenPayload={tokenPayload} />
          ) : (
            <Navigate to="/app/inventory/stokbaku" />
          ),
        children: [
          { path: "incomestatement", element: <IncomeStatementView /> },
          { path: "manajemenbiaya", element: <ManajemenBiayaView /> },
          { path: "kaskecil", element: <KasKecilView /> },
          { path: "penagihan", element: <PenagihanView /> },
        ],
      },
      {
        path: "management",
        element:
          tokenPayload &&
          (tokenPayload.role === "owner" || tokenPayload.role === "admin") ? (
            <ManagementLayout tokenPayload={tokenPayload} />
          ) : (
            <Navigate to="/app/inventory/stokbaku" />
          ),
        children: [
          {
            path: "vendor",
            element:
              tokenPayload && tokenPayload.role === "owner" ? (
                <ManageVendorView />
              ) : (
                <Navigate to="/app/management/customer" />
              ),
          },
          { path: "customer", element: <ManageCustomerView /> },
          {
            path: "employee",
            element:
              tokenPayload && tokenPayload.role === "owner" ? (
                <ManageEmployeeView />
              ) : (
                <Navigate to="/app/management/customer" />
              ),
          },
        ],
      },
    ],
  },
];

export default routes;
