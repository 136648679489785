import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TabelStokBaku from "./TabelStokBaku";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const StokBahanBakuView = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TabelStokBaku />
    </div>
  );
};

export default StokBahanBakuView;
