import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// lighter: "#FCEFD7",
// main: "#C47335",
const borderColor = "black";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "#FCEFD7",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 16,
    textAlign: "center",
    fontWeight: "bold",
    flexGrow: 1,
  },
  size: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  substance: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  flute: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  price: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: "15%",
  },
  subtotal: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const POTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.size}>Ukuran</Text>
    <Text style={styles.substance}>Substance</Text>
    <Text style={styles.flute}>Flute</Text>
    <Text style={styles.qty}>Jumlah</Text>
    <Text style={styles.price}>Harga Inc PPN</Text>
    <Text style={styles.subtotal}>Subtotal</Text>
  </View>
);

export default POTableHeader;
