import React, { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";

import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import { fluteTypesConst } from "../../../../constants";
import PriceListDialog from "./PriceListDialog";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const EditProdukPO = ({
  open,
  purchaseItemId,
  vendorId,
  handleClose,
  handleRefresh,
  includePPN,
}) => {
  const classes = useStyles();

  const [cust, setCust] = React.useState({
    id: "",
    companyName: "",
    picName: "",
    address: "",
    pkp: false,
  });
  const [length, setLength] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [flute, setFlute] = React.useState("");
  const [paper, setPaper] = React.useState("");
  const [qty, setQty] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [fluteTypes, setFluteTypes] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [generatedPrice, setGeneratedPrice] = React.useState({
    calcPrice: 0,
    priceIndex: 0,
  });
  const [openPriceList, setOpenPriceList] = React.useState(false);
  const [fieldsValid, setFieldsValid] = useState({
    cust: true,
    length: true,
    width: true,
    flute: true,
    paper: true,
    qty: true,
    price: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const handleOpenPriceList = (type) => {
    setOpenPriceList(true);
  };

  const handleClosePriceList = () => {
    setOpenPriceList(false);
  };

  const handleChangeCustomer = (event) => {
    if (customers.length > 0) {
      var id = event.target.value;
      var cust = customers.find((customer) => customer.id === id);
      setCust(cust);
    }
  };

  const handleChangeFlute = (event) => {
    setFlute(event.target.value);
  };

  const handleChangePaper = (event) => {
    setPaper(event.target.value);
  };

  const api_getPurchaseItem = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI + "/purchasedetails/" + purchaseItemId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        var cust = customers.find(
          (customer) => customer.id === res.data.custId
        );
        setCust(cust);
        setLength(res.data.length);
        setWidth(res.data.width);
        setQty(res.data.qty);
        setFlute(res.data.flute);
        setPaper(res.data.paper);
        setPrice(res.data.price);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_updatePurchaseItem = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("custId", cust.id);
    newData.append("length", length);
    newData.append("width", width);
    newData.append("qty", qty);
    newData.append("flute", flute);
    newData.append("paper", paper);
    newData.append("price", price);

    // hit the API
    axios
      .put(
        process.env.REACT_APP_MBOXBE_URI + "/purchasedetails/" + purchaseItemId,
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Perubahan item purchase order berhasil disimpan",
        });
        setCust({
          id: "",
          companyName: "",
          picName: "",
          address: "",
          pkp: false,
        });
        setLength(0);
        setWidth(0);
        setQty(0);
        setFlute("");
        setPaper("");
        setPrice(0);

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getFluteTypes = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/flutetypes", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setFluteTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getPaperTypes = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/papertypes", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setPaperTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getCustomers = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/customers", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setCustomers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_calcBuyPrice = () => {
    var args = encodeURI(
      "pp=" + paper + "&fl=" + flute + "&lg=" + length + "&wd=" + width
    );

    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/vendors/" +
          vendorId +
          "/buypricelist?" +
          args,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setGeneratedPrice(res.data);
        setPrice(res.data.calcPrice);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      cust: cust.id !== "",
      length: length != null,
      width: width != null,
      flute: flute !== "",
      paper: paper !== "",
      qty: qty != null,
      price: price != null,
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    api_getFluteTypes();
    api_getPaperTypes();
    api_getCustomers();
  }, []);

  useEffect(() => {
    if (purchaseItemId) api_getPurchaseItem();
  }, [purchaseItemId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Ubah item pembelian
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            <div className={classes.flexCenter}>
              <div style={{ flexGrow: 1 }}>
                Silahkan mengubah detail pesanan di bawah ini (bila diperlukan)
              </div>
              <Tooltip title="Lihat indeks harga">
                <IconButton onClick={handleOpenPriceList}>
                  <LibraryBooksIcon />
                </IconButton>
              </Tooltip>
            </div>
          </DialogContentText>
          <Typography className={classes.formSubsection}>
            Informasi customer
          </Typography>
          <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Untuk customer"
            id="select-cust"
            value={cust.id}
            onChange={handleChangeCustomer}
            required
            error={!fieldsValid.cust}
            helperText={
              !fieldsValid.cust ? "Field ini tidak boleh kosong" : null
            }
          >
            {customers.map((item) => (
              <MenuItem value={item.id}>
                {item.companyName} {item.pkp ? "*" : ""}
              </MenuItem>
            ))}
          </TextField>
          <Typography className={classes.formSubsection}>
            Informasi item
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="panjang"
                label="Panjang (mm)"
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.length}
                helperText={
                  !fieldsValid.length ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="lebar"
                label="Lebar (mm)"
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.width}
                helperText={
                  !fieldsValid.width ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                label="Jenis Kertas"
                id="select-paper"
                value={paper}
                onChange={handleChangePaper}
                required
                error={!fieldsValid.paper}
                helperText={
                  !fieldsValid.paper ? "Field ini tidak boleh kosong" : null
                }
              >
                {paperTypes.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                label="Flute"
                id="select-flute"
                value={flute}
                onChange={handleChangeFlute}
                required
                error={!fieldsValid.flute}
                helperText={
                  !fieldsValid.flute ? "Field ini tidak boleh kosong" : null
                }
              >
                {fluteTypes.map((item) => (
                  <MenuItem value={item}>{fluteTypesConst[item]}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="dense"
            id="qty"
            label="Jumlah"
            type="number"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            fullWidth
            required
            error={!fieldsValid.qty}
            helperText={
              !fieldsValid.qty ? "Field ini tidak boleh kosong" : null
            }
          />
          <Typography className={classes.formSubsection}>
            Informasi harga
          </Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <NumberFormat
                variant="outlined"
                margin="dense"
                id="price"
                label="Harga/lembar (exc. PPN)"
                value={price}
                customInput={TextField}
                prefix={"Rp"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(values) => {
                  setPrice(values.value);
                }}
                fullWidth
                required
                error={!fieldsValid.price}
                helperText={
                  !fieldsValid.price ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
            <Grid item xs={6}>
              <NumberFormat
                disabled
                variant="outlined"
                margin="dense"
                id="price_ppn"
                label="Harga/lembar (inc. PPN)"
                value={includePPN ? Math.trunc(Number(price) * 1.11) : "-"}
                customInput={TextField}
                prefix={"Rp"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                endIcon={
                  <Tooltip
                    title={
                      <>
                        {generatedPrice.priceIndex === 0
                          ? null
                          : "Indeks harga: " + generatedPrice.priceIndex}
                      </>
                    }
                    placement="right"
                  >
                    <InfoIcon />
                  </Tooltip>
                }
                disabled={
                  !(
                    length !== 0 &&
                    width !== 0 &&
                    flute !== "" &&
                    paper !== "" &&
                    vendorId !== ""
                  )
                }
                onClick={api_calcBuyPrice}
              >
                Generate
              </Button>
            </Grid>
          </Grid>
          <Typography className={classes.formSubsection}>Subtotal</Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <NumberFormat
                variant="outlined"
                margin="dense"
                id="total"
                label="Subtotal (exc. PPN)"
                value={Number(qty) * Number(price)}
                customInput={TextField}
                prefix={"Rp"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <NumberFormat
                disabled
                variant="outlined"
                margin="dense"
                id="total_ppn"
                label="Subtotal (inc. PPN)"
                value={
                  includePPN
                    ? Math.trunc(Number(qty) * Number(price) * 1.11)
                    : "-"
                }
                customInput={TextField}
                prefix={"Rp"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button
            variant="outlined"
            onClick={api_updatePurchaseItem}
            color="primary"
          >
            Simpan pesanan
          </Button>
        </DialogActions>
      </Dialog>
      <PriceListDialog
        open={openPriceList}
        vendorId={vendorId}
        handleClose={handleClosePriceList}
      />
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default EditProdukPO;
