import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import SjTableHeader from "./SjTableHeader";
import SjTableRow from "./SjTableRow";
// import SjTableBlankRow from "./SjTableBlankRow";
// import SjTableFooter from "./SjTableFooter";

// const tableRowsCount = 10;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    borderColor: "black",
  },
});

const SJTable = ({ items, totalPrice }) => (
  <View style={styles.tableContainer}>
    <SjTableHeader />
    {items.map((item) => (
      <SjTableRow item={item} />
    ))}
    {/* <SjTableBlankRow rowsCount={tableRowsCount - items.length} /> */}
    {/* <SjTableFooter items={items} totalPrice={totalPrice} /> */}

    {/* <InvoiceTableRow items={invoice.items} />
        <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
        <InvoiceTableFooter items={invoice.items} /> */}
  </View>
);

export default SJTable;
