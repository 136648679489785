import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import {
  MuiPickersUtilsProvider,
  DateTimePicker,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import RefreshIcon from "@material-ui/icons/Refresh";

import LineChartAM from "./LineChartAM";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  chartArea: {
    padding: theme.spacing(1),
    height: 400,
  },
}));

const IncomeStatementChart = () => {
  const classes = useStyles();

  var tempDate = new Date();
  tempDate.setHours(tempDate.getHours() - 6);
  const [startDate, setStartDate] = useState(tempDate);
  const [endDate, setEndDate] = useState(new Date());
  // const [startDate, setStartDate] = useState(new Date(2021, 3, 1));
  // const [endDate, setEndDate] = useState(new Date());
  const [dateFilter, setDateFilter] = useState("date");
  const [selectedDate, handleDateChange] = useState(new Date());

  const handleDateFilterChange = (e) => {
    setDateFilter(e.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleRefreshData = () => {};

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.flexCenter}>
          <Typography style={{ marginRight: 24 }}>
            Tampilkan income statement tahun
          </Typography>
          <DatePicker
            views={["year"]}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>
      </MuiPickersUtilsProvider>
      <Card style={{ marginTop: 16 }}>
        <CardContent>
          <div className={classes.chartArea}>
            <LineChartAM />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default IncomeStatementChart;
