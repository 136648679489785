import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 8,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  cardPaper: {
    minHeight: 135,
    maxHeight: 135,
  },
  cardTitle: {
    fontWeight: "bold",
  },
}));

const SummaryJual = () => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [loadingData, setLoadingData] = useState(true);
  const [summary, setSummary] = useState({
    totalSales: 0,
    doneDelivered: 0,
    halfDelivered: 0,
    noneDelivered: 0,
  });

  const api_getSalesMonthlySummary = () => {
    setLoadingData(true);
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/sales_monthly_summary?year=" +
          format(selectedDate, "yyyy") +
          "&month=" +
          format(selectedDate, "MM"),
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSummary(res.data);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    api_getSalesMonthlySummary();
  }, [selectedDate]);

  return (
    <div className={classes.root}>
      <div className={classes.flexCenter} style={{ marginBottom: 4 }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Typography variant="h6" style={{ marginRight: 8 }}>
            Ringkasan penjualan bulan
          </Typography>
          <DatePicker
            variant="inline"
            inputVariant="outlined"
            margin="dense"
            openTo="year"
            views={["year", "month"]}
            value={selectedDate}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </div>
      <Grid container spacing={1}>
        <Grid item xs>
          <Card className={classes.cardPaper}>
            <CardContent>
              <Typography variant="h6" className={classes.cardTitle}>
                Invoice
              </Typography>
              <Typography>Jumlah invoice: {summary.totalSales}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card className={classes.cardPaper}>
            <CardContent>
              <Typography variant="h6" className={classes.cardTitle}>
                Pengiriman
              </Typography>
              <Typography>
                Penjualan selesai terkirim: {summary.doneDelivered}
                <br />
                Penjualan terkirim sebagian: {summary.halfDelivered}
                <br /> Penjualan belum terkirim: {summary.noneDelivered}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs />
        {/* <Grid item xs>
          <Card>
            <CardContent>
              <Typography variant="h6" className={classes.cardTitle}>
                Pembayaran
              </Typography>
              Terbayar seluruhnya:
              <br /> Terbayar sebagian:
              <br /> Belum terbayar:
            </CardContent>
          </Card>
        </Grid> */}
        {/* <Grid item xs>
          <Card>
            <CardContent>
              <Typography variant="h6">Produksi</Typography>
              Sudah info produksi:
              <br /> Belum info produksi:
              <br />
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </div>
  );
};

export default SummaryJual;
