import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const SnackbarAlert = ({ open, handleClose, severity, message }) => {
  // return <Alert elevation={6} {...props} />;

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {/* <SnackbarAlert onClose={handleClose} severity={severity}>
          Node succesfully created
        </SnackbarAlert> */}
      <Alert elevation={6} onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
