import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";

import logo from "../logo.png";

import { Link, Outlet, useLocation, Navigate } from "react-router-dom";

import Copyright from "../coreui/Copyright";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    backgroundColor: theme.palette.background.default,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    backgroundColor: theme.palette.primary.white,
  },
  appBarLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: drawerWidth, // 24 is the Toolbar gutter
    // backgroundColor: theme.palette.primary.main,
  },
  logo: {
    maxWidth: drawerWidth - 20,
    maxHeight: 50,
  },
  appBarNav: {
    flexGrow: 1,
    display: "flex",
    backgroundColor: theme.palette.background.default,
    marginLeft: theme.spacing(3),
  },
  appBarPadding: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbarButton: {
    color: "white",
    fontSize: "1rem",
    // fontWeight: "bold",
  },
  toolbarButtonSelected: {
    color: "white",
    fontSize: "1rem",
    fontWeight: "bold",
    borderBottom: "6px solid",
    borderBottomColor: theme.palette.primary.light,
    borderRadius: 0,
  },
}));

const MainLayout = ({ isLoggedIn, tokenPayload }) => {
  const classes = useStyles();

  return isLoggedIn ? (
    tokenPayload.role === "prod" ? (
      <Navigate to="app/production/daftarproduksi" />
    ) : (
      <Navigate to="app/sales/daftarjual" />
    )
  ) : (
    <div id="AppLayout" className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Toolbar style={{ alignItems: "center", paddingLeft: 0 }}>
          <div className={classes.appBarLogo}>
            <Link to="/app/inventory/stokbaku">
              <img src={logo} alt="muliabox-logo" className={classes.logo} />
              {/* MULIABOX */}
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <div id="AppContent" className={classes.content}>
        <Outlet />
        <Copyright />
      </div>
    </div>
  );
};

export default MainLayout;
