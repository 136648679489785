import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import TabelListPekerjaan from "./TabelListPekerjaan";
// import FormPekerjaan from "./FormPekerjaan";
//
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const DaftarProduksiView = ({ tokenPayload }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TabelListPekerjaan tokenPayload={tokenPayload} />
    </div>
  );
};

export default DaftarProduksiView;
