import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x ${
    params.getValue(params.id, "width") || ""
  }`;
};

const formatReceivedQty = (params) => {
  return `${params.getValue(params.id, "totalRecv") || "0"} /
    ${params.getValue(params.id, "qty") || ""}`;
};

const ListTerimaBarang = ({ receiveSummary }) => {
  const columns = [
    { field: "cust", headerName: "Customer", width: 130 },
    {
      field: "size",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 180 },
    { field: "fluteN", headerName: "Flute", width: 120 },
    {
      field: "totalReceived",
      headerName: "Diterima",
      width: 130,
      valueGetter: formatReceivedQty,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    // { field: "qty", headerName: "Jumlah", type: "number", width: 130 },
    // { field: "harga", headerName: "Harga/lembar", type: "number", width: 150 },
    // {
    //   field: "total",
    //   headerName: "Subtotal",
    //   type: "number",
    //   width: 130,
    //   valueGetter: formatTotalPrice,
    // },
    // {
    //   field: "totalRecv",
    //   headerName: "Sudah terima",
    //   width: 160,
    //   type: "number",
    // },
    {
      field: "totalReturn",
      headerName: "Sudah retur",
      width: 160,
      type: "number",
    },
    {
      field: "totalLeft",
      headerName: "Belum terima",
      width: 160,
      type: "number",
      valueGetter: (params) => {
        const { qty, totalRecv, totalReturn } = params.row;
        return qty - totalRecv - totalReturn;
      },
      // renderCell: (GridCellParams) => (
      //   <TextField margin="dense" id="returnQty" type="number" fullWidth />
      // ),
    },
    // { field: "recvDate", headerName: "Tanggal terima", width: 170 },
  ];

  return (
    <div>
      {/* <Typography style={{ marginTop: 16, marginBottom: 8 }}>
        List barang
      </Typography> */}
      <div style={{ height: 300, width: "100%", marginTop: 16 }}>
        <DataGrid
          rows={receiveSummary}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          rowHeight={35}
          isRowSelectable={(params) => !params.row.recvDate}
          disableSelectionOnClick
          disableColumnFilter
        />
      </div>
    </div>
  );
};

export default ListTerimaBarang;
