import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "center",
  },
  gridContainer: {
    flexDirection: "row",
    display: "flex",
  },
});

const FakturFooter = ({ custCompany }) => (
  <View style={styles.titleContainer}>
    {/* <Text style={styles.reportTitle}>Thank you for your business</Text> */}
    <View style={styles.gridContainer}>
      <View style={{ width: "50%" }}>
        <Text>Tertanda,</Text>
        <Text style={{ marginTop: 36 }}>Mulia Box</Text>
      </View>
      <View style={{ width: "50%" }}>
        <Text>Tertanda,</Text>
        <Text style={{ marginTop: 36 }}>{custCompany}</Text>
      </View>
    </View>
    <Text style={{ marginTop: 10 }}>
      * Dokumen ini dicetak otomatis oleh sistem
    </Text>
  </View>
);

export default FakturFooter;
