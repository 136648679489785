import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListSubheader from "@material-ui/core/ListSubheader";

import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import StoreIcon from "@material-ui/icons/Store";
import PeopleIcon from "@material-ui/icons/People";

import { Link, Outlet, useLocation } from "react-router-dom";

const drawerWidth = 240;
const drawerFontSize = 14;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.lighter,
    // backgroundColor: theme.palette.background.default,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  list: {
    backgroundColor: "transparent",
  },
  listSelected: {
    backgroundColor: theme.palette.primary.light,
    // borderRight: "3px solid",
    // borderRightColor: theme.palette.primary.light,
    color: "white",
  },
  listTextSelected: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: drawerFontSize,
  },
  listText: {
    color: theme.palette.primary.main,
    fontSize: drawerFontSize,
  },
}));

const ManagementLayout = ({ tokenPayload }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(true);

  // react-router location tracker
  let location = useLocation();

  const isSelected = (currentPath, path) => {
    return currentPath.indexOf(path) !== -1;
  };

  // const handleDrawerToggle = () => {
  //   setOpenDrawer((state) => !state);
  // };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        })}
        classes={{
          paper: clsx(classes.drawerPaper, {
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer,
          }),
        }}
      >
        <Toolbar />
        <div>
          <List>
            <ListSubheader component="div">Manajemen data</ListSubheader>
            {[
              {
                text: "Data customer",
                logo: <PeopleIcon />,
                mainpath: "/app/management/customer",
                path: "/app/management/customer",
                roles: ["owner", "admin"],
              },
              {
                text: "Data vendor",
                logo: <StoreIcon />,
                mainpath: "/app/management/vendor",
                path: "/app/management/vendor",
                roles: ["owner"],
              },
              {
                text: "Data pegawai",
                logo: <SupervisedUserCircleIcon />,
                mainpath: "/app/management/employee",
                path: "/app/management/employee",
                roles: ["owner"],
              },
            ].map((menu, index) =>
              menu.roles.includes(tokenPayload.role) ? (
                <div>
                  <ListItem
                    button={!isSelected(location.pathname, menu.mainpath)}
                    component={Link}
                    to={menu.path}
                    className={
                      isSelected(location.pathname, menu.mainpath)
                        ? classes.listSelected
                        : classes.list
                    }
                  >
                    <ListItemIcon>{menu.logo}</ListItemIcon>
                    <ListItemText primary={menu.text} />
                  </ListItem>
                </div>
              ) : null
            )}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Outlet />
      </main>
    </div>
  );
};

export default ManagementLayout;
