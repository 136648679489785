import React, { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CloseIcon from "@material-ui/icons/Close";

import { fluteTypesConst } from "../../../constants";
import SnackbarAlert from "../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const TambahBahanBaku = ({ open, handleClose, handleRefresh }) => {
  const classes = useStyles();
  const [cust, setCust] = useState({ id: "", companyName: "" });
  const [flute, setFlute] = useState("");
  const [paper, setPaper] = useState("");
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);
  const [ppn, setPpn] = useState(false);
  const [fluteTypes, setFluteTypes] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [fieldsValid, setFieldsValid] = useState({
    cust: true,
    length: true,
    width: true,
    flute: true,
    paper: true,
    qty: true,
    price: true,
    ppn: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const handleChangeCustomer = (event) => {
    if (customers.length > 0) {
      var id = event.target.value;
      var companyName = customers.find((customer) => customer.id === id);
      setCust({ id, companyName });
    }
  };

  const handleChangeFlute = (event) => {
    setFlute(event.target.value);
  };

  const handleChangePaper = (event) => {
    setPaper(event.target.value);
  };

  const api_createRawInventory = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("custId", cust.id);
    newData.append("flute", flute);
    newData.append("paper", paper);
    newData.append("length", length);
    newData.append("width", width);
    newData.append("qty", qty);
    newData.append("price", price);
    newData.append("ppn", ppn);

    // hit the API
    axios
      .post(process.env.REACT_APP_MBOXBE_URI + "/rawinventory", newData, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Bahan baku berhasil ditambahkan",
        });
        setCust({ id: "", companyName: "" });
        setFlute("");
        setPaper("");
        setLength(0);
        setWidth(0);
        setQty(0);
        setPrice(0);
        setPpn(false);

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getFluteTypes = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/flutetypes", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setFluteTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getPaperTypes = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/papertypes", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setPaperTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getCustomers = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/customers", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setCustomers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      cust: cust.id !== "",
      length: length != null,
      width: width != null,
      flute: flute !== "",
      paper: paper !== "",
      qty: qty != null,
      price: price != null,
      ppn: ppn != null,
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    api_getFluteTypes();
    api_getPaperTypes();
    api_getCustomers();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Tambah stok bahan baku
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            Silahkan memasukkan detail bahan baku
          </DialogContentText>
          <Typography className={classes.formSubsection}>
            Informasi customer
          </Typography>
          <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            id="select-customer"
            label="Untuk customer"
            value={cust.id}
            onChange={handleChangeCustomer}
            required
            error={!fieldsValid.cust}
            helperText={
              !fieldsValid.cust ? "Field ini tidak boleh kosong" : null
            }
          >
            {customers.map((item) => (
              <MenuItem value={item.id}>{item.companyName}</MenuItem>
            ))}
          </TextField>
          <Typography className={classes.formSubsection}>
            Informasi bahan baku
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="length"
                label="Panjang"
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.length}
                helperText={
                  !fieldsValid.length ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="width"
                label="Lebar"
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.width}
                helperText={
                  !fieldsValid.width ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                label="Jenis kertas"
                id="select-paper"
                value={paper}
                onChange={handleChangePaper}
                required
                error={!fieldsValid.paper}
                helperText={
                  !fieldsValid.paper ? "Field ini tidak boleh kosong" : null
                }
              >
                {paperTypes.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                label="Flute"
                id="select-flute"
                value={flute}
                onChange={handleChangeFlute}
                required
                error={!fieldsValid.flute}
                helperText={
                  !fieldsValid.flute ? "Field ini tidak boleh kosong" : null
                }
              >
                {fluteTypes.map((item) => (
                  <MenuItem value={item}>{fluteTypesConst[item]}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="dense"
            id="qty"
            label="Jumlah"
            type="number"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            fullWidth
            required
            error={!fieldsValid.qty}
            helperText={
              !fieldsValid.qty ? "Field ini tidak boleh kosong" : null
            }
          />
          <div className={classes.flexCenter}>
            <Typography
              className={classes.formSubsection}
              style={{ flexGrow: 1 }}
            >
              Informasi biaya
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ppn}
                  onChange={(e) => setPpn(e.target.checked)}
                  name="includePPN"
                  color="primary"
                  size="small"
                />
              }
              label="Tambah PPN (11%)"
            />
          </div>
          <Grid container spacing={1}>
            <Grid item xs>
              <Typography style={{ marginBottom: 4, color: "grey" }}>
                Harga exc. PPN
              </Typography>
              <NumberFormat
                variant="outlined"
                margin="dense"
                id="price"
                label="Harga/lembar"
                value={price}
                customInput={TextField}
                prefix={"Rp"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(values) => {
                  setPrice(values.value);
                }}
                fullWidth
                required
                error={!fieldsValid.price}
                helperText={
                  !fieldsValid.price ? "Field ini tidak boleh kosong" : null
                }
              />
              <NumberFormat
                variant="outlined"
                margin="dense"
                id="totalprice"
                label="Harga total"
                value={price * qty}
                customInput={TextField}
                prefix={"Rp"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <Typography style={{ marginBottom: 4, color: "grey" }}>
                Harga inc. PPN
              </Typography>
              <NumberFormat
                variant="outlined"
                margin="dense"
                id="price_incppn"
                label="Harga/lembar"
                value={ppn ? Number(price) * 1.11 : "-"}
                customInput={TextField}
                prefix={"Rp"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                fullWidth
              />
              <NumberFormat
                variant="outlined"
                margin="dense"
                id="totalprice_incppn"
                label="Harga total"
                value={
                  ppn ? Math.trunc(Number(price) * Number(qty) * 1.11) : "-"
                }
                customInput={TextField}
                prefix={"Rp"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button
            onClick={api_createRawInventory}
            variant="contained"
            color="primary"
          >
            Tambah
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default TambahBahanBaku;
