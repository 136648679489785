import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "black";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    // borderBottomColor: borderColor,
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 14,
    // fontWeight: "bold",
  },
  description: {
    width: "48%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  price: {
    width: "11%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  discount: {
    width: "14%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "17%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const FkTableRow = ({ item }) => {
  // break name into lines if it is too long, assume max of 2 lines
  var itemName = item.name;
  var itemNameSecondLine = "";
  const breakpoint = 48;
  if (item.name.length > breakpoint) {
    itemName = item.name.slice(0, breakpoint);
    itemNameSecondLine = item.name.slice(breakpoint, item.name.length);
  }

  return (
    <View>
      <View style={styles.row} key={item.id}>
        <Text style={styles.description}>{itemName}</Text>
        <Text style={styles.qty}>{item.qty}</Text>
        <Text style={styles.price}>{item.price}</Text>
        <Text style={styles.discount}>{item.discountRaw}</Text>
        <Text style={styles.amount}>{item.subtotal}</Text>
      </View>
      {itemNameSecondLine !== "" ? (
        <View style={styles.row} key={item.id}>
          <Text style={styles.description}>{itemNameSecondLine}</Text>
          <Text style={styles.qty}>&nbsp;</Text>
          <Text style={styles.price}>&nbsp;</Text>
          <Text style={styles.discount}>&nbsp;</Text>
          <Text style={styles.amount}>&nbsp;</Text>
        </View>
      ) : null}
    </View>
  );
};

export default FkTableRow;
