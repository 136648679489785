import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import format from "date-fns/format";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import TambahProduksi from "./TambahProduksi";
import RefreshButton from "../../../../coreui/RefreshButton";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ListProduksi = ({ isAdd, open, handleClose, jobId, status }) => {
  const columns = [
    {
      field: "productionDate",
      headerName: "Tanggal produksi",
      width: 250,
      renderCell: ({ value }) => (
        <div>{format(new Date(value), "dd-MMM-yyyy, HH:mm")}</div>
      ),
    },
    {
      field: "qty",
      headerName: "Jumlah diproduksi",
      type: "number",
      width: 200,
    },
    {
      field: "done",
      headerName: "Status",
      width: 150,
      renderCell: ({ value }) => <div>{value ? "Selesai" : "-"}</div>,
    },
    {
      field: "notes",
      headerName: "Catatan",
      width: 150,
    },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      renderCell: (GridCellParams) => (
        <div>
          <Tooltip title="Edit" placement="right">
            <IconButton onClick={() => handleOpenEdit(GridCellParams.row.id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Hapus" placement="right">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const classes = useStyles();

  const [openAdd, setOpenAdd] = React.useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [productions, setProductions] = React.useState([]);

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const handleRefreshAfterCreate = (newlyCreatedId) => {
    api_getProductionsInJob();
    handleOpenEdit(newlyCreatedId);
  };

  const api_getProductionsInJob = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI + "/jobs/" + jobId + "/productions",
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setProductions([]); // force re-render here
        setProductions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    api_getProductionsInJob();
  }, []);

  return (
    <div>
      <Typography style={{ marginTop: 16, fontWeight: "bold" }}>
        Daftar produksi
        <RefreshButton
          style={{ marginLeft: 16 }}
          handleClick={api_getProductionsInJob}
        />
        <Tooltip title="Tambah informasi produksi" placement="right">
          <IconButton
            color="primary"
            onClick={handleOpenAdd}
            disabled={status === "done"}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <TambahProduksi
        isAdd={true}
        open={openAdd}
        handleClose={handleCloseAdd}
        handleRefresh={handleRefreshAfterCreate}
        jobId={jobId}
      />
      <TambahProduksi
        isAdd={false}
        open={openEdit.status}
        handleClose={handleCloseEdit}
        handleRefresh={api_getProductionsInJob}
        productionId={openEdit.id}
        jobId={jobId}
      />
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          rows={productions}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};

export default ListProduksi;
