import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    display: "flex",
    marginTop: 6,
    // justifyContent: "space-between",
  },
  sectionTitle: {
    // color: "#C47335",
    fontWeight: "bold",
    marginBottom: 2,
  },
  gridContainer: {
    flexDirection: "row",
    display: "flex",
  },
  sectionLeft: {
    flex: 7,
  },
  sectionRight: {
    flex: 3,
  },
});

const FakturBillTo = ({
  custPic,
  custCompany,
  custAddress,
  paymentTerm,
  notes,
  deliveryDocNumbers,
}) => (
  <View style={styles.titleContainer}>
    <View style={styles.sectionLeft}>
      <Text style={styles.sectionTitle}>## Penerima ##</Text>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Nama</Text>
        <Text>: {custPic}</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Perusahaan</Text>
        <Text>: {custCompany}</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Alamat</Text>
        <Text>: {custAddress}</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ fontWeight: "bold", width: 120 }}>Term Pembayaran</Text>
        <Text style={{ fontWeight: "bold" }}>: {paymentTerm}</Text>
      </View>
      {/* <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Catatan</Text>
        <Text>: {notes}</Text>
      </View> */}
    </View>
    <View style={styles.sectionRight}>
      <Text style={styles.sectionTitle}>## No. Surat Jalan ##</Text>
      <Text style={{ marginBottom: 4 }}>
        {deliveryDocNumbers.length > 0
          ? deliveryDocNumbers.map(
              (number, idx) =>
                number + (idx < deliveryDocNumbers.length - 1 ? ", " : "")
            )
          : "-"}
      </Text>
      <Text style={styles.sectionTitle}>## Catatan ##</Text>
      <Text>{notes}</Text>
    </View>
    {/* <View>
      <Text style={styles.sectionTitle}>Dikirim dan ditagihkan ke</Text>
      <Text>{purchaser}</Text>
      <Text>Jalan Radio Palasari 102, Bandung</Text>
      <Text>Telp: (022) 5202922 / 0811237540</Text>
    </View> */}
  </View>
);

export default FakturBillTo;
