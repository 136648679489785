import React from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import TambahKomponenProduksi from "./TambahKomponenProduksi";
import RefreshButton from "../../../../coreui/RefreshButton";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ListKomponenProduksi = ({
  isAdd,
  open,
  handleClose,
  handleRefresh,
  componentDetails,
  custId,
  productionId,
  status,
}) => {
  const columns = [
    { field: "name", headerName: "Nama komponen", width: 210 },
    // { field: "qty", headerName: "Jumlah", type: "number", width: 150 },
    { field: "rawInvenString", headerName: "Bahan baku", width: 300 },
    {
      field: "rawQty",
      headerName: "Bahan terpakai",
      type: "number",
      width: 200,
    },
    // {
    //   field: "harga",
    //   headerName: "Harga bahan/lembar",
    //   type: "number",
    //   width: 150,
    // },
    {
      field: "actions",
      headerName: " ",
      width: 120,
      renderCell: (GridCellParams) => (
        <div>
          {/* <Tooltip
            title="Edit komponen"
            placement="right"
            onClick={handleOpenEdit}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip> */}
          <Tooltip title="Hapus komponen" placement="right">
            <IconButton
              onClick={() => api_deleteProductionDetail(GridCellParams.row.id)}
              disabled={status}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const classes = useStyles();

  const [openAdd, setOpenAdd] = React.useState(false);

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const api_deleteProductionDetail = (id) => {
    axios
      .delete(process.env.REACT_APP_MBOXBE_URI + "/productiondetails/" + id, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        handleRefresh();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Typography style={{ marginTop: 16, fontWeight: "bold" }}>
        Daftar komponen
        <RefreshButton style={{ marginLeft: 16 }} handleClick={handleRefresh} />
        <Tooltip title="Tambah komponen" placement="right">
          <IconButton color="primary" onClick={handleOpenAdd} disabled={status}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <TambahKomponenProduksi
        isAdd={true}
        open={openAdd}
        handleClose={handleCloseAdd}
        handleRefresh={handleRefresh}
        custId={custId}
        productionId={productionId}
      />
      {/* <TambahKomponenProduksi
        isAdd={false}
        open={openAdd}
        handleClose={handleCloseAdd}
      /> */}
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          rows={componentDetails}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};

export default ListKomponenProduksi;
