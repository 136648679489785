const revenueData = [
  {
    date: "2021-01-27",
    value: 13000000,
  },
  {
    date: "2021-02-27",
    value: 11000000,
  },
  {
    date: "2021-03-27",
    value: 15000000,
  },
  {
    date: "2021-04-27",
    value: 16000000,
  },
  {
    date: "2021-05-27",
    value: 18000000,
  },
  {
    date: "2021-06-27",
    value: 13000000,
  },
  {
    date: "2021-07-27",
    value: 22000000,
  },
  {
    date: "2021-08-27",
    value: 23000000,
  },
  {
    date: "2021-09-27",
    value: 20000000,
  },
  {
    date: "2021-10-27",
    value: 17000000,
  },
  {
    date: "2021-11-27",
    value: 16000000,
  },
  {
    date: "2021-12-27",
    value: 16000000,
  },
];

const expenseData = [
  {
    date: "2021-01-27",
    value: 1300000,
  },
  {
    date: "2021-02-27",
    value: 1100000,
  },
  {
    date: "2021-03-27",
    value: 1500000,
  },
  {
    date: "2021-04-27",
    value: 1600000,
  },
  {
    date: "2021-05-27",
    value: 4500000,
  },
  {
    date: "2021-06-27",
    value: 2200000,
  },
  {
    date: "2021-07-27",
    value: 4000000,
  },
  {
    date: "2021-08-27",
    value: 5300000,
  },
  {
    date: "2021-09-27",
    value: 3000000,
  },
  {
    date: "2021-10-27",
    value: 1700000,
  },
  {
    date: "2021-11-27",
    value: 5000000,
  },
  {
    date: "2021-12-27",
    value: 6000000,
  },
];

const monthlyData = {
  revenue: 36000000,
  avgHpp: 2000,
  materialExpense: 10000000,
  grossProfit: 26000000,
  salaries: 5000000,
  operationalCost: 5000000,
  nonOperationalCost: 500000,
  pettyCash: 3200000,
  additionalRevenue: 1000000,
  preTaxProfit: 11300000,
};

export { revenueData, expenseData, monthlyData };
