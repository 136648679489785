import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import POTableHeader from "./POTableHeader";
import POTableRow from "./POTableRow";
import POTableBlankRow from "./POTableBlankRow";
import POTableFooter from "./POTableFooter";

const tableRowsCount = 10;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    borderColor: "black",
  },
});

const POTable = ({ items, totalPrice }) => (
  <View style={styles.tableContainer}>
    <POTableHeader />
    {items.map((item) => (
      <POTableRow item={item} />
    ))}
    {/* <POTableBlankRow rowsCount={tableRowsCount - items.length} /> */}
    <POTableFooter items={items} totalPrice={totalPrice} />

    {/* <InvoiceTableRow items={invoice.items} />
        <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
        <InvoiceTableFooter items={invoice.items} /> */}
  </View>
);

export default POTable;
