import React, { useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Chip from "@material-ui/core/Chip";

import CloseIcon from "@material-ui/icons/Close";

// import TambahProduksi from "./TambahProduksi";
import ListProduksi from "./ListProduksi";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const customers = [
  { name: "Untuk stok", pic: "", address: "" },
  { name: "Customer X", pic: "Xania", address: "Asia Afrika, Bandung" },
  { name: "Customer Y", pic: "Yovita", address: "Cimbeleuit, Bandung" },
  { name: "Customer Z", pic: "Zanash", address: "Kopo, Bandung" },
];

const FormPekerjaan = ({ isAdd, open, handleClose, jobId }) => {
  const classes = useStyles();
  const [salesNo, setSalesNo] = React.useState("");
  const [salesDate, setSalesDate] = React.useState(new Date());
  const [itemName, setItemName] = React.useState("");
  const [cust, setCust] = React.useState("");
  const [note, setNote] = React.useState("");
  const [fluteN, setFluteN] = React.useState("");
  const [paper, setPaper] = React.useState("");
  const [length, setLength] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [qty, setQty] = React.useState(0);
  const [doneQty, setDoneQty] = React.useState(0);
  const [status, setStatus] = React.useState("");

  const api_getJobDetail = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/jobs/" + jobId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        // var cust = customers.find(
        //   (customer) => customer.id === res.data.custId
        // );
        setCust(res.data.cust);
        setSalesNo(res.data.salesNo);
        setSalesDate(new Date(res.data.salesDate));
        setItemName(res.data.itemName);
        setFluteN(res.data.fluteN);
        setPaper(res.data.paper);
        setLength(res.data.length);
        setWidth(res.data.width);
        setHeight(res.data.height);
        setQty(res.data.qty);
        setNote(res.data.note);
        setDoneQty(res.data.doneQty);
        setStatus(res.data.status);
        // setPpn(res.data.ppn);
        // setPaymentTerm(res.data.paymentTerm);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    if (!isAdd && jobId) api_getJobDetail();
  }, [jobId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Status pekerjaan produksi
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Typography style={{ marginBottom: 16, fontWeight: "bold" }}>
            <div className={classes.flexCenter}>
              Status:&nbsp;&nbsp;
              <Chip
                label={
                  <strong
                    style={{
                      color:
                        status === "done"
                          ? "green"
                          : status === "progress"
                          ? "black"
                          : "red",
                    }}
                  >
                    {doneQty} / {qty}
                  </strong>
                }
              />
            </div>
          </Typography>
          {/* <Typography style={{ marginBottom: 16, fontWeight: "bold" }}>
            Data barang untuk diproduksi
          </Typography> */}
          <Typography className={classes.formSubsection}>
            Informasi umum pesanan
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="salesNo"
                label="Dari No. penjualan"
                value={salesNo}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="salesDate"
                label="Tanggal pesanan"
                value={format(salesDate, "dd-MMM-yyyy")}
                fullWidth
              />
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="dense"
            id="cust"
            label="Customer"
            fullWidth
            value={cust}
            // onChange={(e) => setCust(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="note"
            label="Catatan tambahan (opsional)"
            value={note}
            // onChange={(e) => setNote(e.target.value)}
            fullWidth
          />
          <Typography className={classes.formSubsection}>
            Detail box untuk dibuat
          </Typography>
          <TextField
            variant="outlined"
            margin="dense"
            id="itemName"
            label="Nama barang yang diproduksi"
            value={itemName}
            // onChange={(e) => setItemName(e.target.value)}
            fullWidth
          />
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="dense"
                id="length"
                label="Panjang"
                type="number"
                value={length}
                fullWidth
                // onChange={(e) => setLength(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="dense"
                id="width"
                label="Lebar"
                type="number"
                value={width}
                fullWidth
                // onChange={(e) => setWidth(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant="outlined"
                margin="dense"
                id="height"
                label="Tinggi"
                type="number"
                value={height}
                fullWidth
                // onChange={(e) => setHeight(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="paper"
                label="Jenis kertas"
                value={paper}
                // onChange={(e) => setItemName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="flute"
                label="Flute"
                value={fluteN}
                // onChange={(e) => setItemName(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="dense"
            id="qty"
            label="Jumlah"
            type="number"
            fullWidth
            value={qty}
            // onChange={(e) => setQty(e.target.value)}
          />
          {!isAdd && jobId ? (
            <ListProduksi jobId={jobId} status={status} />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            disabled={!isAdd && status === "done"}
          >
            {isAdd ? "Tambah" : "Simpan"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormPekerjaan;
