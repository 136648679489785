import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import Button from "@material-ui/core/Button";

// import FormProduksi from "./FormProduksi";
// import TerimaBarang from "./TerimaBarang";
import { listProduksi } from "../../../static/listProduksi";

const rows = listProduksi;

const formatBoxSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x ${
    params.getValue(params.id, "width") || ""
  } x ${params.getValue(params.id, "height") || ""}`;
};

const TabelListPekerjaan = () => {
  const columns = [
    { field: "prodDate", headerName: "Tanggal produksi", width: 200 },
    { field: "itemName", headerName: "Nama item", width: 200 },
    {
      field: "ukuranbox",
      headerName: "Ukuran box",
      width: 200,
      valueGetter: formatBoxSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "qty", headerName: "Jumlah item", width: 200 },
    { field: "note", headerName: "Catatan", width: 200 },
    // { field: "kertas", headerName: "Jenis kertas", width: 210 },
    // { field: "flute", headerName: "Flute bahan", width: 130 },
    // {
    //   field: "ukuran",
    //   headerName: "Ukuran bahan",
    //   width: 130,
    //   valueGetter: formatSizeString,
    //   sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    // },
    // {
    //   field: "rawQty",
    //   headerName: "Bahan terpakai",
    //   type: "number",
    //   width: 130,
    // },
    // {
    //   field: "harga",
    //   headerName: "Harga bahan/lembar",
    //   type: "number",
    //   width: 150,
    // },
    {
      field: "detail",
      headerName: " ",
      width: 100,
      renderCell: (GridCellParams) => (
        <Button color="primary" onClick={handleOpenEdit}>
          Detail
        </Button>
      ),
    },
  ];

  const [openEdit, setOpenEdit] = useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  return (
    <div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
        />
      </div>
      {/* <FormProduksi
        isAdd={false}
        open={openEdit}
        handleClose={handleCloseEdit}
      /> */}
      {/* <TerimaBarang open={openReceive} handleClose={handleCloseReceive} /> */}
    </div>
  );
};

export default TabelListPekerjaan;
