import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

// lighter: "#FCEFD7",
// main: "#C47335",
const borderColor = "black";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    backgroundColor: "#FCEFD7",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 16,
    textAlign: "center",
    fontWeight: "bold",
    flexGrow: 1,
  },
  description: {
    width: "70%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  size: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  substance: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  flute: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "30%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  price: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  amount: {
    width: "20%",
  },
});

const FkTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.description}>Item</Text>
    <Text style={styles.qty}>Jumlah</Text>
  </View>
);

export default FkTableHeader;
