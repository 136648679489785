import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));
const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x
  ${params.getValue(params.id, "width") || ""} x ${
    params.getValue(params.id, "height") || ""
  }`;
};

const formatSentQty = (params) => {
  return `${params.getValue(params.id, "totalSent") || "0"} /
  ${params.getValue(params.id, "qty") || ""}`;
};

const ListKirimBarang = ({ deliverySummary }) => {
  const classes = useStyles();
  const columns = [
    // { field: "customer", headerName: "Utk customer", width: 160 },
    { field: "name", headerName: "Item", width: 160 },
    {
      field: "ukuran",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 180 },
    { field: "fluteN", headerName: "Flute", width: 120 },
    {
      field: "sentQty",
      headerName: "Terkirim",
      width: 130,
      valueGetter: formatSentQty,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    {
      field: "totalLeft",
      headerName: "Belum kirim",
      width: 160,
      type: "number",
      valueGetter: (params) => {
        const { qty, totalSent } = params.row;
        return qty - totalSent;
      },
    },
  ];

  return (
    <div>
      {/* <Typography className={classes.formSubsection}>List barang</Typography> */}
      <div style={{ height: 350, width: "100%", marginTop: 16 }}>
        <DataGrid
          rows={deliverySummary}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          rowHeight={45}
          // isRowSelectable={(params) => !params.row.recvDate}
          disableSelectionOnClick
          disableColumnFilter
        />
      </div>
    </div>
  );
};

export default ListKirimBarang;
