import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AssessmentIcon from "@material-ui/icons/Assessment";

const RefreshButton = ({ handleClick, ...props }) => {
  return (
    <Tooltip title="Tampilkan ringkasan" {...props}>
      <IconButton color="primary" size="small" onClick={handleClick}>
        <AssessmentIcon />
      </IconButton>
    </Tooltip>
  );
};

export default RefreshButton;
