import React from "react";
import { Page, Document, StyleSheet, Font } from "@react-pdf/renderer";

import SJHeader from "./SJHeader";
import SJBillTo from "./SJBillTo";
import SJTable from "./SJTable";
import SJFooter from "./SJFooter";

import fontSaxMono from "../../../../../constants/saxmono-Font/saxmono.ttf";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

Font.register({
  family: "SaxMono",
  fonts: [{ src: fontSaxMono }],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "SaxMono",
    fontSize: 9,
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.5,
    flexDirection: "column",
  },
});

const SJMain = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <SJHeader
        deliveryDocNumber={
          data && data.deliveryDocNumber ? data.deliveryDocNumber : ""
        }
        deliveryDate={data && data.deliveryDate ? data.deliveryDate : ""}
        salesNo={data && data.salesNo ? data.salesNo : ""}
      />
      <SJBillTo
        custPic={data && data.custPic ? data.custPic : ""}
        custCompany={data && data.custCompany ? data.custCompany : ""}
        custAddress={data && data.custAddress ? data.custAddress : ""}
      />
      <SJTable
        items={
          data && data.details && data.details.length > 0 ? data.details : []
        }
        totalPrice={data && data.totalPrice ? data.totalPrice : 0}
      />
      <SJFooter
        custCompany={data && data.custCompany ? data.custCompany : ""}
      />
    </Page>
  </Document>
);

export default SJMain;
