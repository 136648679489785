// import pubkey from "./pubkey.js";
const jwt = require("jsonwebtoken");

// verify client-sent token
const VerifyToken = (token) => {
  var verifyOptions = {
    algorithm: ["RS256"],
  };
  try {
    return {
      valid: true,
      payload: jwt.verify(token, process.env.REACT_APP_PUBKEY, verifyOptions),
    };
  } catch (err) {
    return { valid: false, payload: {} };
  }
};

export default VerifyToken;
