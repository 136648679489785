import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const HistoriBarangJadiView = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4" className={classes.pageTitle}>
        Histori Barang Jadi
      </Typography>
    </div>
  );
};

export default HistoriBarangJadiView;
