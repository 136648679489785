import React, { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import SearchIcon from "@material-ui/icons/Search";

import { fluteTypesConst, boxTypesConst } from "../../../../constants";
import PriceListDialog from "./PriceListDialog";
import FormPilihProdukFavorit from "./FormPilihProdukFavorit";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const TambahProdukJual = ({
  open,
  salesId,
  custId,
  handleClose,
  handleRefresh,
}) => {
  const classes = useStyles();

  const [itemName, setItemName] = useState("");
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [flute, setFlute] = useState("");
  const [paper, setPaper] = useState("");
  const [qty, setQty] = useState(0);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [sablon, setSablon] = useState(0);
  const [pondKnife, setPondKnife] = useState(0);
  const [fluteTypes, setFluteTypes] = useState([]);
  const [paperTypes, setPaperTypes] = useState([]);
  const [boxType, setBoxType] = useState("");
  const [vendors, setVendors] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [generatedPrice, setGeneratedPrice] = useState({
    calcPrice: 0,
    priceIndex: 0,
  });
  const [openPriceList, setOpenPriceList] = useState(false);
  const [openFavoriteBoxes, setOpenFavoriteBoxes] = useState(false);
  const [saveToFavorite, setSaveToFavorite] = useState(false);
  const [fieldsValid, setFieldsValid] = useState({
    itemName: true,
    length: true,
    width: true,
    height: true,
    flute: true,
    paper: true,
    qty: true,
    price: true,
    discount: true,
    // sablon: true,
    // pondKnife: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const handleOpenPriceList = (type) => {
    setOpenPriceList(true);
  };

  const handleClosePriceList = () => {
    setOpenPriceList(false);
  };

  const handleOpenFavoriteBoxes = (type) => {
    setOpenFavoriteBoxes(true);
  };

  const handleCloseFavoriteBoxes = () => {
    setOpenFavoriteBoxes(false);
  };

  const handleChangeFlute = (event) => {
    setFlute(event.target.value);
  };

  const handleChangeBoxType = (event) => {
    setBoxType(event.target.value);
  };

  const handleChangePaper = (event) => {
    setPaper(event.target.value);
  };

  const handleChangeVendor = (event) => {
    setVendorId(event.target.value);
  };

  const calculateSubTotal = () => {
    // return qty * (price - discount * 1 + sablon * 1 + pondKnife * 1);
    return qty * (price * 1) - calculateDiscountRp();
  };

  const calculateDiscountRp = () => {
    return qty * 1 * (price * 1) * ((discount * 1) / 100);
  };

  const api_createSalesItem = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("itemName", itemName);
    newData.append("length", length);
    newData.append("width", width);
    newData.append("height", height);
    newData.append("qty", qty);
    newData.append("flute", flute);
    newData.append("paper", paper);
    newData.append("price", price);
    newData.append("discount", discount);
    newData.append("sablon", sablon);
    newData.append("pondKnife", pondKnife);
    newData.append("saveFav", saveToFavorite);

    // hit the API
    axios
      .post(
        process.env.REACT_APP_MBOXBE_URI + "/sales/" + salesId + "/details",
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Item sales order berhasil ditambahkan",
        });
        setItemName("");
        setLength(0);
        setWidth(0);
        setHeight(0);
        setQty(0);
        setFlute("");
        setPaper("");
        setPrice(0);
        setDiscount(0);
        setSablon(0);
        setPondKnife(0);
        setSaveToFavorite(false);

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getFluteTypes = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/flutetypes", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setFluteTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getPaperTypes = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/papertypes", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setPaperTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getVendors = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/vendors", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setVendors(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_calcSellPrice = () => {
    var args =
      "pp=" + paper + "&fl=" + flute + "&lg=" + length + "&wd=" + width;
    args = args + "&ht=" + height + "&box=" + boxType;
    args = encodeURI(args);

    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/vendors/" +
          vendorId +
          "/sellpricelist?" +
          args,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setGeneratedPrice(res.data);
        setPrice(res.data.calcPrice);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getFavoriteBox = (favBoxId) => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI + "/favorite_boxinventory/" + favBoxId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setItemName(res.data.name);
        setLength(res.data.length);
        setWidth(res.data.width);
        setHeight(res.data.height);
        setFlute(res.data.flute);
        setPaper(res.data.paper);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      itemName: itemName !== "",
      length: length != null,
      width: width != null,
      height: height != null,
      flute: flute !== "",
      paper: paper !== "",
      qty: qty != null,
      price: price != null,
      discount: discount != null,
      // sablon: sablon != null,
      // pondKnife: pondKnife != null,
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    api_getFluteTypes();
    api_getPaperTypes();
    api_getVendors();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Tambah item penjualan
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            Silahkan masukkan detail item yang dipesan customer
          </DialogContentText>
          <div className={classes.flexCenter}>
            <Typography className={classes.formSubsection}>
              Informasi item
            </Typography>
            <Tooltip title="Lihat pesanan favorit customer">
              <IconButton onClick={handleOpenFavoriteBoxes}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            {/* <div style={{ flexGrow: 1 }} /> */}
          </div>
          <TextField
            variant="outlined"
            margin="dense"
            id="item"
            label="Nama item pesanan"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            fullWidth
            required
            error={!fieldsValid.itemName}
            helperText={
              !fieldsValid.itemName ? "Field ini tidak boleh kosong" : null
            }
          />
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="length"
                label="Panjang (cm)"
                type="number"
                value={length}
                onChange={(e) => setLength(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.length}
                helperText={
                  !fieldsValid.length ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="width"
                label="Lebar (cm)"
                type="number"
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.width}
                helperText={
                  !fieldsValid.width ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                id="height"
                label="Tinggi (cm)"
                type="number"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                fullWidth
                required
                error={!fieldsValid.height}
                helperText={
                  !fieldsValid.height ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                label="Jenis kertas"
                id="select-paper"
                value={paper}
                onChange={handleChangePaper}
                required
                error={!fieldsValid.paper}
                helperText={
                  !fieldsValid.paper ? "Field ini tidak boleh kosong" : null
                }
              >
                {paperTypes.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                label="Flute"
                id="select-flute"
                value={flute}
                onChange={handleChangeFlute}
                required
                error={!fieldsValid.flute}
                helperText={
                  !fieldsValid.flute ? "Field ini tidak boleh kosong" : null
                }
              >
                {fluteTypes.map((item) => (
                  <MenuItem value={item}>{fluteTypesConst[item]}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="dense"
            id="qty"
            label="Jumlah"
            type="number"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            fullWidth
            required
            error={!fieldsValid.qty}
            helperText={
              !fieldsValid.qty ? "Field ini tidak boleh kosong" : null
            }
          />
          <div className={classes.flexCenter}>
            <Typography className={classes.formSubsection}>
              Informasi harga
            </Typography>
            <Tooltip title="Lihat indeks harga">
              <IconButton onClick={handleOpenPriceList}>
                <LibraryBooksIcon />
              </IconButton>
            </Tooltip>
          </div>
          <NumberFormat
            variant="outlined"
            margin="dense"
            id="price"
            label="Harga/unit"
            value={price}
            customInput={TextField}
            prefix={"Rp"}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(values) => {
              setPrice(values.value);
            }}
            fullWidth
            required
            error={!fieldsValid.price}
            helperText={
              !fieldsValid.price ? "Field ini tidak boleh kosong" : null
            }
          />
          <Grid container spacing={1} alignItems="center">
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                id="select-price-vendor"
                label="Pilih vendor bahan"
                value={vendorId}
                onChange={handleChangeVendor}
              >
                {vendors.map((item) => (
                  <MenuItem value={item.id}>{item.companyName}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                select
                fullWidth
                variant="outlined"
                margin="dense"
                id="select-box-type"
                label="Pilih jenis box"
                value={boxType}
                onChange={handleChangeBoxType}
              >
                {Object.keys(boxTypesConst).map((item) => (
                  <MenuItem value={item}>{boxTypesConst[item]}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                endIcon={
                  <Tooltip
                    title={
                      <>
                        {generatedPrice.priceIndex === 0
                          ? null
                          : "Indeks harga: " + generatedPrice.priceIndex}
                      </>
                    }
                    placement="right"
                  >
                    <InfoIcon />
                  </Tooltip>
                }
                disabled={
                  !(
                    length !== 0 &&
                    width !== 0 &&
                    height !== 0 &&
                    flute !== "" &&
                    paper !== "" &&
                    vendorId !== "" &&
                    boxType !== ""
                  )
                }
                onClick={api_calcSellPrice}
              >
                Generate
              </Button>
            </Grid>
          </Grid>
          <Typography className={classes.formSubsection}>
            Potongan harga
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <NumberFormat
                variant="outlined"
                margin="dense"
                id="discount"
                // label="Diskon/unit"
                label="Diskon (%)"
                value={discount}
                customInput={TextField}
                // prefix={"Rp"}
                suffix={"%"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(values) => {
                  setDiscount(values.floatValue);
                }}
                fullWidth
                required
                error={!fieldsValid.discount}
                helperText={
                  !fieldsValid.discount ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid>
            {/* <Grid item xs>
              <NumberFormat
                variant="outlined"
                margin="dense"
                id="discount"
                label="Biaya sablon/unit"
                value={sablon}
                customInput={TextField}
                prefix={"Rp"}
                type="text"
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(values) => {
                  setSablon(values.value);
                }}
                fullWidth
                required
                error={!fieldsValid.sablon}
                helperText={
                  !fieldsValid.sablon ? "Field ini tidak boleh kosong" : null
                }
              />
            </Grid> */}
            {/* <Grid item xs></Grid> */}
          </Grid>
          {/* <NumberFormat
            variant="outlined"
            margin="dense"
            id="pondKnife"
            label="Biaya pisau pond/unit"
            // helperText="Isi dengan tanda (-) bila biaya pisau ditanggung Mulia Box. contoh: -100"
            value={pondKnife}
            customInput={TextField}
            prefix={"Rp"}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(values) => {
              setPondKnife(values.value);
            }}
            fullWidth
            required
            error={!fieldsValid.pondKnife}
            helperText={
              !fieldsValid.pondKnife
                ? "Field ini tidak boleh kosong"
                : "Isi dengan tanda (-) bila biaya pisau ditanggung Mulia Box. contoh: -100"
            }
          /> */}
          <Typography className={classes.formSubsection}>Subtotal</Typography>
          <NumberFormat
            disabled
            variant="outlined"
            margin="dense"
            id="discount_rp"
            label="Total diskon"
            value={calculateDiscountRp()}
            customInput={TextField}
            prefix={"Rp"}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            fullWidth
          />
          <NumberFormat
            variant="outlined"
            margin="dense"
            id="total"
            label="Subtotal setelah diskon"
            value={calculateSubTotal()}
            customInput={TextField}
            prefix={"Rp"}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={saveToFavorite}
                onChange={(e) => setSaveToFavorite(e.target.checked)}
                name="saveToFavorite"
                color="secondary"
              />
            }
            label="Simpan sebagai pesanan favorit"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button
            variant="outlined"
            onClick={api_createSalesItem}
            color="primary"
          >
            Tambah item
          </Button>
        </DialogActions>
      </Dialog>
      <PriceListDialog
        open={openPriceList}
        vendorId={vendorId}
        handleClose={handleClosePriceList}
      />
      <FormPilihProdukFavorit
        open={openFavoriteBoxes}
        custId={custId}
        handleClose={handleCloseFavoriteBoxes}
        handleRefresh={api_getFavoriteBox}
      />
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default TambahProdukJual;
