import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import format from "date-fns/format";
import { DataGrid } from "@mui/x-data-grid";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import FormCustomer from "./FormCustomer";
import RefreshButton from "../../../coreui/RefreshButton";
import DialogHapusCustomer from "./DialogHapusCustomer";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  chipColorSuccess: {
    backgroundColor: theme.palette.success.light,
  },
}));

const TabelListVendor = () => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [openDelete, setOpenDelete] = useState({ status: false, id: null });
  const [customers, setCustomers] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  const columns = [
    { field: "companyName", headerName: "Nama perusahaan", width: 170 },
    { field: "picName", headerName: "Nama PIC", width: 170 },
    { field: "address", headerName: "Alamat", width: 170 },
    { field: "phone", headerName: "No. telp", width: 170 },
    { field: "industry", headerName: "Industri", width: 170 },
    // { field: "city", headerName: "Industri", width: 170 },
    {
      field: "pkp",
      headerName: "PKP",
      width: 110,
      renderCell: ({ row }) => (
        <>
          {row.pkp ? (
            <Chip label="Ya" className={classes.chipColorSuccess} />
          ) : (
            <Chip label="Tidak" />
          )}
        </>
      ),
    },
    // {
    //   field: "paymentTerm",
    //   headerName: "Term pembayaran",
    //   width: 120,
    //   renderCell: ({ row }) => <>{paymentTermsConst[row.paymentTerm]}</>,
    // },
    {
      field: "detail",
      headerName: " ",
      width: 100,
      renderCell: ({ row }) => (
        <Button color="primary" onClick={() => handleOpenEdit(row.id)}>
          Detail
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: " ",
      width: 50,
      renderCell: ({ row }) => (
        <IconButton
          size="small"
          disabled={!row.can_delete}
          onClick={() => handleOpenDelete(row.id)}
        >
          <Tooltip title="Hapus data customer">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const handleOpenDelete = (id) => {
    setOpenDelete({ status: true, id });
  };

  const handleCloseDelete = () => {
    setOpenDelete({ status: false, id: null });
  };

  const handleRefreshAfterCreate = (newlyCreatedId) => {
    api_getCustomers();
    // handleOpenEdit(newlyCreatedId);
  };

  const api_getCustomers = () => {
    setLoadingTable(true);
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/customers", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setCustomers([]); // force re-render here
        setCustomers(res.data);
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    api_getCustomers();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Daftar customer
          <RefreshButton
            style={{ marginLeft: 16 }}
            handleClick={api_getCustomers}
          />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAdd}
        >
          Tambah customer
        </Button>
      </div>
      <div style={{ height: "80%", width: "100%" }}>
        <DataGrid
          rows={customers}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
          loading={loadingTable}
        />
      </div>

      <FormCustomer
        isAdd={true}
        open={openAdd}
        handleClose={handleCloseAdd}
        handleRefresh={handleRefreshAfterCreate}
      />
      <FormCustomer
        isAdd={false}
        open={openEdit.status}
        handleClose={handleCloseEdit}
        handleRefresh={api_getCustomers}
        customerId={openEdit.id}
      />
      <DialogHapusCustomer
        open={openDelete.status}
        handleRefresh={api_getCustomers}
        handleClose={handleCloseDelete}
        customerId={openDelete.id}
      />
    </div>
  );
};

export default TabelListVendor;
