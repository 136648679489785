import React, { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import TambahProdukPO from "./TambahProdukPO";
import EditProdukPO from "./EditProdukPO";
import { formatWithThousandSeparator } from "../../../../utils/helperFunctions";
import RefreshButton from "../../../../coreui/RefreshButton";

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x ${
    params.getValue(params.id, "width") || ""
  }`;
};

const formatTotalPrice = (params) => {
  return (
    params.getValue(params.id, "qty") * params.getValue(params.id, "price")
  );
};

const ListProdukPO = ({ purchaseId, vendorId, includePPN }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [purchaseItems, setPurchaseItems] = useState([]);

  const columns = [
    {
      field: "cust",
      headerName: "Utk customer",
      width: 160,
      renderCell: (GridCellParams) => (
        <div>
          {GridCellParams.row.cust} {GridCellParams.row.custPkp ? "*" : ""}
        </div>
      ),
    },
    {
      field: "size",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 180 },
    { field: "fluteN", headerName: "Flute", width: 120 },
    { field: "qty", headerName: "Jumlah", type: "number", width: 120 },
    {
      field: "price",
      headerName: "Harga/lembar (exc. PPN)",
      type: "number",
      width: 150,
      valueFormatter: (params) => {
        return `Rp${formatWithThousandSeparator(params.value)}`;
      },
    },
    {
      field: "total",
      headerName: "Subtotal (exc. PPN)",
      type: "number",
      width: 130,
      valueGetter: formatTotalPrice,
      valueFormatter: (params) => {
        return `Rp${formatWithThousandSeparator(params.value)}`;
      },
    },
    {
      field: "price_ppn",
      headerName: "Harga/lembar (inc. PPN)",
      type: "number",
      width: 150,
      // valueFormatter: (params) => {
      //   return `Rp${formatWithThousandSeparator(params.value)}`;
      // },
      renderCell: ({ row }) => (
        <NumberFormat
          value={includePPN ? Math.trunc(Number(row.price) * 1.11) : "-"}
          prefix={"Rp"}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      ),
    },
    {
      field: "total_ppn",
      headerName: "Subtotal (inc. PPN)",
      type: "number",
      width: 130,
      valueGetter: formatTotalPrice,
      // valueFormatter: (params) => {
      //   return `Rp${formatWithThousandSeparator(params.value)}`;
      // },
      renderCell: ({ row }) => (
        <NumberFormat
          value={
            includePPN
              ? Math.trunc(Number(row.qty) * Number(row.price) * 1.11)
              : "-"
          }
          prefix={"Rp"}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 100,
      renderCell: (GridCellParams) => (
        <div>
          <Tooltip
            title="Edit pesanan"
            placement="right"
            onClick={() => handleOpenEdit(GridCellParams.row.id)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Hapus pesanan"
            placement="right"
            onClick={() => api_deletePurchaseItem(GridCellParams.row.id)}
          >
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const getTotalPayment = () => {
    var sum = 0;
    purchaseItems.forEach(({ qty, price }) => {
      sum += Number(qty) * Number(price);
    });

    return sum;
  };

  const getTotalPaymentPPN = () => {
    var sum = 0;
    purchaseItems.forEach(({ qty, price }) => {
      sum += Number(qty) * Number(price);
    });

    sum = sum * 1.11;
    return sum;
  };

  const api_getPurchaseItems = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/purchases/" +
          purchaseId +
          "/details",
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setPurchaseItems([]);
        setPurchaseItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_deletePurchaseItem = (purchaseItemId) => {
    axios
      .delete(
        process.env.REACT_APP_MBOXBE_URI + "/purchasedetails/" + purchaseItemId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        api_getPurchaseItems(); // handleRefresh
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (purchaseId) {
      api_getPurchaseItems();
    }
  }, [purchaseId]);

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs>
          <NumberFormat
            variant="outlined"
            margin="dense"
            id="totalprice"
            label="Harga total (exc. PPN)"
            value={getTotalPayment()}
            customInput={TextField}
            prefix={"Rp"}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            fullWidth
          />
        </Grid>
        <Grid item xs>
          <NumberFormat
            disabled
            variant="outlined"
            margin="dense"
            id="totalprice"
            label="Harga total (inc. PPN)"
            value={includePPN ? Math.trunc(getTotalPaymentPPN()) : "-"}
            customInput={TextField}
            prefix={"Rp"}
            type="text"
            thousandSeparator="."
            decimalSeparator=","
            fullWidth
          />
        </Grid>
      </Grid>
      <Typography style={{ marginTop: 16, fontWeight: "bold" }}>
        List pembelian
        <RefreshButton
          style={{ marginLeft: 16 }}
          handleClick={api_getPurchaseItems}
        />
        <Tooltip title="Tambah pesanan" placement="right">
          <IconButton color="primary" onClick={handleOpenAdd}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <TambahProdukPO
        open={openAdd}
        purchaseId={purchaseId}
        vendorId={vendorId}
        handleClose={handleCloseAdd}
        handleRefresh={api_getPurchaseItems}
        includePPN={includePPN}
      />
      <EditProdukPO
        open={openEdit.status}
        purchaseItemId={openEdit.id}
        vendorId={vendorId}
        handleClose={handleCloseEdit}
        handleRefresh={api_getPurchaseItems}
        includePPN={includePPN}
      />
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          rows={purchaseItems}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          rowHeight={35}
          // checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
        />
      </div>
    </div>
  );
};

export default ListProdukPO;
