import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import CloseIcon from "@material-ui/icons/Close";

// import TambahKomponenProduksi from "./TambahKomponenProduksi";
import ListKomponenProduksi from "./ListKomponenProduksi";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
}));

const TambahProduksi = ({
  isAdd,
  open,
  handleClose,
  handleRefresh,
  jobId,
  productionId,
}) => {
  const classes = useStyles();
  const [custId, setCustId] = useState("");
  const [prodDate, setProdDate] = React.useState(new Date());
  const [qty, setQty] = React.useState(0);
  const [notes, setNotes] = React.useState("");
  const [componentDetails, setComponentDetails] = React.useState([]);
  const [status, setStatus] = React.useState(false); // just to emulate
  const [fieldsValid, setFieldsValid] = useState({
    prodDate: true,
    qty: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const api_getProductionDetail = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/productions/" + productionId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setCustId(res.data.custId);
        setProdDate(new Date(res.data.productionDate));
        setQty(res.data.qty);
        setNotes(res.data.notes);
        setComponentDetails(res.data.componentDetails);
        setStatus(res.data.done);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_createProduction = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("productionDate", prodDate.toISOString());
    newData.append("qty", qty);
    newData.append("notes", notes);

    // hit the API
    axios
      .post(
        process.env.REACT_APP_MBOXBE_URI + "/jobs/" + jobId + "/productions",
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Informasi batch produksi berhasil ditambahkan",
        });
        setQty(0);
        setNotes("");

        handleRefresh(res.data.production);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_updateProduction = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("productionDate", prodDate.toISOString());
    newData.append("qty", qty);
    newData.append("notes", notes);

    // hit the API
    axios
      .put(
        process.env.REACT_APP_MBOXBE_URI + "/productions/" + productionId,
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Perubahan informasi batch produksi berhasil disimpan",
        });
        handleRefresh(res.data.production);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_markProductionFinish = () => {
    // prepare the data
    var newData = new FormData();

    // hit the API
    axios
      .post(
        process.env.REACT_APP_MBOXBE_URI +
          "/productions/" +
          productionId +
          "/finish",
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        handleRefresh(productionId);
        api_getProductionDetail();
        // handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      prodDate: prodDate != null,
      qty: parseInt(qty) > 0,
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    if (!isAdd && productionId) api_getProductionDetail();
  }, [productionId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        // fullScreen={!isAdd}
        // fullWidth={isAdd}
        maxWidth={isAdd ? "sm" : "lg"}
        fullWidth
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {isAdd ? "Formulir pencatatan produksi" : "Ubah detail produksi"}
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {isAdd ? null : (
            <div className={classes.flexCenter}>
              <Typography style={{ marginRight: 24 }}>
                <strong>
                  Status:{" "}
                  {status ? (
                    <strong style={{ color: "green" }}>Selesai</strong>
                  ) : (
                    <strong style={{ color: "red" }}>Belum selesai</strong>
                  )}
                </strong>
              </Typography>
              <Button
                color="primary"
                variant="outlined"
                onClick={api_markProductionFinish}
                disabled={status}
              >
                Selesaikan produksi
              </Button>
            </div>
          )}
          <TextField
            variant="outlined"
            margin="dense"
            id="prodDate"
            label="Tanggal produksi"
            value={format(prodDate, "dd-MMM-yyyy")}
            fullWidth
            required
            error={!fieldsValid.prodDate}
            helperText={
              !fieldsValid.prodDate ? "Field ini tidak boleh kosong" : null
            }
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="qty"
            label="Jumlah"
            type="number"
            fullWidth
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            required
            error={!fieldsValid.qty}
            helperText={!fieldsValid.qty ? "Jumlah tidak boleh 0 (nol)" : null}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="notes"
            label="Catatan tambahan (opsional)"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
          />
          {!isAdd ? (
            <ListKomponenProduksi
              componentDetails={componentDetails}
              custId={custId}
              productionId={productionId}
              handleRefresh={api_getProductionDetail}
              status={status}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button
            onClick={isAdd ? api_createProduction : api_updateProduction}
            variant="outlined"
            color="primary"
            disabled={!isAdd && status}
          >
            {isAdd ? "Tambah" : "Simpan"}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default TambahProduksi;
