import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import format from "date-fns/format";
import { DataGrid } from "@mui/x-data-grid";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import FormPO from "./FormPO";
import TerimaBarang from "./TerimaBarang";
import CetakPO from "./CetakPO";
import RefreshButton from "../../../coreui/RefreshButton";
import DialogHapusPembelian from "./DialogHapusPembelian";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const TabelListBeli = () => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [openReceive, setOpenReceive] = useState({ status: false, id: null });
  const [openPrintPO, setOpenPrintPO] = useState({ status: false, id: null });
  const [openDelete, setOpenDelete] = useState({ status: false, id: null });
  const [purchases, setPurchases] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  const columns = [
    { field: "purchaseNo", headerName: "No. Pembelian", width: 150 },
    { field: "vendor", headerName: "Nama vendor", width: 180 },
    {
      field: "purchaseDate",
      headerName: "Tanggal pembelian",
      width: 170,
      renderCell: ({ value }) => (
        <div>{format(new Date(value), "dd-MMM-yyyy")}</div>
      ),
    },
    // { field: "purchaser", headerName: "Atas Nama", width: 170 },
    // { field: "cust", headerName: "Untuk customer", width: 200 },
    {
      field: "quickDetails",
      headerName: "Pesanan",
      width: 400,
      renderCell: ({ row, value }) => (
        <Tooltip title={"Pembelian atas nama: " + row.purchaser}>
          <div>
            {value.map((item) => (
              <Typography variant="body2">{item}</Typography>
            ))}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "recvStatus",
      headerName: "Status terima",
      width: 160,
      renderCell: ({ row }) => (
        <div className={classes.flexCenter}>
          <Tooltip
            title={
              <div>
                Terima: {row.recvQty}
                <br />
                Retur: {row.returnQty}
              </div>
            }
            placement="right"
          >
            {/* <InfoIcon fontSize="small" color="primary" /> */}
            <strong
              style={{
                color:
                  parseInt(row.recvQty) + parseInt(row.returnQty) ===
                  parseInt(row.totalQty)
                    ? "green"
                    : parseInt(row.recvQty) + parseInt(row.returnQty) > 0 &&
                      parseInt(row.recvQty) + parseInt(row.returnQty) <
                        parseInt(row.totalQty)
                    ? "black"
                    : "red",
              }}
            >
              {parseInt(row.recvQty) + parseInt(row.returnQty)} / {row.totalQty}
            </strong>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "detail",
      headerName: " ",
      width: 100,
      renderCell: ({ row }) => (
        <Button color="primary" onClick={() => handleOpenEdit(row.id)}>
          Detail
        </Button>
      ),
    },
    {
      field: "receive",
      headerName: " ",
      width: 100,
      renderCell: ({ row }) => (
        <Button color="primary" onClick={() => handleOpenReceive(row.id)}>
          Penerimaan
        </Button>
      ),
    },
    {
      field: "print",
      headerName: " ",
      width: 100,
      renderCell: ({ row }) => (
        <Button color="primary" onClick={() => handleOpenPrintPO(row.id)}>
          Cetak PO
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: " ",
      width: 50,
      renderCell: ({ row }) => (
        <IconButton
          size="small"
          // disabled={row.recvQty > 0 || row.returnQty > 0}
          disabled={!row.can_delete}
          onClick={() => handleOpenDelete(row.id)}
        >
          <Tooltip title="Hapus purchase order">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const handleOpenReceive = (id) => {
    setOpenReceive({ status: true, id });
  };

  const handleCloseReceive = () => {
    setOpenReceive({ status: false, id: null });
  };

  const handleOpenPrintPO = (id) => {
    setOpenPrintPO({ status: true, id });
  };

  const handleClosePrintPO = () => {
    setOpenPrintPO({ status: false, id: null });
  };

  const handleOpenDelete = (id) => {
    setOpenDelete({ status: true, id });
  };

  const handleCloseDelete = () => {
    setOpenDelete({ status: false, id: null });
  };

  const handleRefreshAfterCreate = (newlyCreatedId) => {
    api_getPurchases();
    handleOpenEdit(newlyCreatedId);
  };

  const api_getPurchases = () => {
    setLoadingTable(true);
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/purchases", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setPurchases([]); // force re-render here
        setPurchases(res.data);
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    api_getPurchases();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Daftar pembelian
          <RefreshButton
            style={{ marginLeft: 16 }}
            handleClick={api_getPurchases}
          />
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAdd}
        >
          Buat formulir PO
        </Button>
      </div>
      <div style={{ height: "80%", width: "100%" }}>
        <DataGrid
          rows={purchases}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
          rowHeight={82}
          loading={loadingTable}
        />
      </div>
      <FormPO
        isAdd={true}
        open={openAdd}
        handleClose={handleCloseAdd}
        handleRefresh={handleRefreshAfterCreate}
      />
      <FormPO
        isAdd={false}
        open={openEdit.status}
        purchaseId={openEdit.id}
        handleClose={handleCloseEdit}
        handleRefresh={api_getPurchases}
      />
      <TerimaBarang
        open={openReceive.status}
        purchaseId={openReceive.id}
        handleClose={handleCloseReceive}
      />
      <CetakPO
        open={openPrintPO.status}
        purchaseId={openPrintPO.id}
        handleClose={handleClosePrintPO}
      />
      <DialogHapusPembelian
        open={openDelete.status}
        handleRefresh={api_getPurchases}
        handleClose={handleCloseDelete}
        purchaseId={openDelete.id}
      />
    </div>
  );
};

export default TabelListBeli;
