import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "center",
  },
});

const POFooter = () => (
  <View style={styles.titleContainer}>
    {/* <Text style={styles.reportTitle}>Thank you for your business</Text> */}
    <Text>Tertanda,</Text>
    <Text style={{ marginTop: 10 }}>Mulia Box</Text>
    <Text style={{ marginTop: 10 }}>
      * Dokumen ini dicetak otomatis oleh sistem
    </Text>
  </View>
);

export default POFooter;
