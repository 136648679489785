import React, { useEffect, useState } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import TambahFavoriteBox from "./TambahFavoriteBox";
import EditFavoriteBox from "./EditFavoriteBox";
import RefreshButton from "../../../../coreui/RefreshButton";

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x
  ${params.getValue(params.id, "width") || ""} x ${
    params.getValue(params.id, "height") || ""
  }`;
};

const ListFavoriteBox = ({ custId }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [favoriteBoxes, setFavoriteBoxes] = useState([]);

  const columns = [
    { field: "name", headerName: "Nama item", width: 160 },
    {
      field: "size",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 160 },
    { field: "fluteN", headerName: "Flute", width: 110 },
    {
      field: "actions",
      headerName: " ",
      width: 100,
      renderCell: (GridCellParams) => (
        <div>
          <Tooltip
            title="Edit item"
            placement="right"
            onClick={() => handleOpenEdit(GridCellParams.row.id)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Hapus item"
            placement="right"
            onClick={() => api_deleteFavoriteBox(GridCellParams.row.id)}
          >
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const api_getFavoriteBoxes = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/favorite_boxinventory?custId=" +
          custId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setFavoriteBoxes([]);
        setFavoriteBoxes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_deleteFavoriteBox = (favBoxId) => {
    axios
      .delete(
        process.env.REACT_APP_MBOXBE_URI + "/favorite_boxinventory/" + favBoxId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        api_getFavoriteBoxes(); // handleRefresh
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (custId) {
      api_getFavoriteBoxes();
    }
  }, [custId]);

  return (
    <div>
      <Typography style={{ marginTop: 16, fontWeight: "bold" }}>
        List item penjualan favorit
        <RefreshButton
          style={{ marginLeft: 16 }}
          handleClick={api_getFavoriteBoxes}
        />
        <Tooltip title="Tambah item" placement="right">
          <IconButton color="primary" onClick={handleOpenAdd}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <TambahFavoriteBox
        open={openAdd}
        custId={custId}
        handleClose={handleCloseAdd}
        handleRefresh={api_getFavoriteBoxes}
      />
      <EditFavoriteBox
        open={openEdit.status}
        favBoxId={openEdit.id}
        handleClose={handleCloseEdit}
        handleRefresh={api_getFavoriteBoxes}
      />
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          rows={favoriteBoxes}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          rowHeight={35}
          // checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
        />
      </div>
    </div>
  );
};

export default ListFavoriteBox;
