import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "black";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    // borderBottomColor: borderColor,
    // borderBottomWidth: 1,
    alignItems: "center",
    height: 14,
    // fontWeight: "bold",
  },
  size: {
    width: "20%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  substance: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  flute: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  price: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  amount: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
  subtotal: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const POTableRow = ({ item }) => {
  return (
    <View style={styles.row} key={item.id}>
      <Text style={styles.size}>{item.size}</Text>
      <Text style={styles.substance}>{item.paper}</Text>
      <Text style={styles.flute}>{item.flute}</Text>
      <Text style={styles.qty}>{item.qty}</Text>
      <Text style={styles.price}>{item.price}</Text>
      <Text style={styles.subtotal}>{item.subtotal}</Text>
      {/* <Text style={styles.amount}>{(item.qty * item.price).toFixed(2)}</Text> */}
    </View>
  );
};

export default POTableRow;
