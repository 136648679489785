import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import Button from "@material-ui/core/Button";

// import FormPO from "./FormPO";
// import TerimaBarang from "./TerimaBarang";

const rows = [
  {
    id: 1,
    date: "Juni 2021",
    totalBiaya: 15000000,
  },
  {
    id: 2,
    date: "Juli 2021",
    totalBiaya: 18000000,
  },
  {
    id: 3,
    date: "Agustus 2021",
    totalBiaya: 15500000,
  },
  {
    id: 4,
    date: "September 2021",
    totalBiaya: 17300000,
  },
];

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const TabelListBiayaBulanan = () => {
  const columns = [
    { field: "date", headerName: "Periode", width: 150 },
    { field: "totalBiaya", headerName: "Total biaya", width: 150 },
    {
      field: "detail",
      headerName: " ",
      width: 100,
      renderCell: (GridCellParams) => (
        <Button color="primary" onClick={handleOpenEdit}>
          Detail
        </Button>
      ),
    },
  ];

  const [openEdit, setOpenEdit] = useState(false);
  const [openReceive, setOpenReceive] = useState(false);

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenReceive = () => {
    setOpenReceive(true);
  };

  const handleCloseReceive = () => {
    setOpenReceive(false);
  };

  return (
    <div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
        />
      </div>
      {/* <FormPO isAdd={false} open={openEdit} handleClose={handleCloseEdit} />
      <TerimaBarang open={openReceive} handleClose={handleCloseReceive} /> */}
    </div>
  );
};

export default TabelListBiayaBulanan;
