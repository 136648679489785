import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import CloseIcon from "@material-ui/icons/Close";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
}));

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x ${
    params.getValue(params.id, "width") || ""
  }`;
};

const formatReceivedQty = (params) => {
  return `${params.getValue(params.id, "totalRecv") || "0"} /
    ${params.getValue(params.id, "qty") || ""}`;
};

const FormTerimaBarang = ({
  open,
  handleClose,
  handleRefresh,
  carNumbers,
  receiveSummary,
  purchaseId,
}) => {
  const columns = [
    { field: "cust", headerName: "Customer", width: 130 },
    {
      field: "size",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 180 },
    { field: "fluteN", headerName: "Flute", width: 120 },
    {
      field: "toReceive",
      headerName: "Terima",
      width: 130,
      renderCell: (GridCellParams) => (
        <TextField
          margin="dense"
          id="recvQty"
          type="number"
          fullWidth
          disabled={
            GridCellParams.row.qty <=
            GridCellParams.row.totalRecv + GridCellParams.row.totalReturn
          }
          onChange={(e) =>
            handleChangeToReceive(GridCellParams.row.id, e.target.value)
          }
        />
      ),
    },
    {
      field: "toReturn",
      headerName: "Retur",
      width: 130,
      renderCell: (GridCellParams) => (
        <TextField
          margin="dense"
          id="returnQty"
          type="number"
          fullWidth
          disabled={
            GridCellParams.row.qty <=
            GridCellParams.row.totalRecv + GridCellParams.row.totalReturn
          }
          onChange={(e) =>
            handleChangeToReturn(GridCellParams.row.id, e.target.value)
          }
        />
      ),
    },
    {
      field: "totalReceived",
      headerName: "Diterima",
      width: 130,
      valueGetter: formatReceivedQty,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    {
      field: "totalReturn",
      headerName: "Sudah retur",
      width: 160,
      type: "number",
    },
    {
      field: "totalLeft",
      headerName: "Belum terima",
      width: 160,
      type: "number",
      valueGetter: (params) => {
        const { qty, totalRecv, totalReturn } = params.row;
        return qty - totalRecv - totalReturn;
      },
    },
  ];

  const classes = useStyles();

  const [employees, setEmployees] = useState([]);
  const [recvDate, setRecvDate] = useState(new Date());
  const [vendorDocNumber, setVendorDocNumber] = useState("");
  const [vendorCourier, setVendorCourier] = useState("");
  const [vendorCarNumber, setVendorCarNumber] = useState("");
  const [recipientId, setRecipientId] = useState("");
  const [receiveList, setReceiveList] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]); // table selection
  const [fieldsValid, setFieldsValid] = useState({
    recvDate: true,
    vendorCourier: true,
    recipientId: true,
    receiveList: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const handleChangeToReceive = (id, recvQty) => {
    var newList = [...receiveList];

    // find the corresponding entry
    var idx = newList.findIndex((item) => item.itemId === id);
    newList[idx] = { ...newList[idx], recvQty };

    setReceiveList(newList);
  };

  const handleChangeToReturn = (id, returnQty) => {
    var newList = [...receiveList];

    // find the corresponding entry
    var idx = newList.findIndex((item) => item.itemId === id);
    newList[idx] = { ...newList[idx], returnQty };

    setReceiveList(newList);
  };

  const api_getEmployees = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/employees", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_createReceive = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("receiveDate", recvDate.toISOString());
    newData.append("vendorDocNumber", vendorDocNumber);
    newData.append("vendorCourier", vendorCourier);
    newData.append("vendorCarNumber", vendorCarNumber);
    newData.append("recipientId", recipientId);

    var selectedIDs = new Set(selectionModel);
    newData.append(
      "receiveList",
      JSON.stringify(receiveList.filter((row) => selectedIDs.has(row.itemId)))
    );

    // hit the API
    axios
      .post(
        process.env.REACT_APP_MBOXBE_URI +
          "/purchases/" +
          purchaseId +
          "/receives",
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Penerimaan barang berhasil dicatat",
        });
        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      recvDate: recvDate != null,
      vendorCourier: vendorCourier !== "",
      recipientId: recipientId !== "",
      receiveList: true,
    };

    // validate the receiveList JSON array
    var selectedIDs = new Set(selectionModel);
    var arr = receiveList.filter((row) => selectedIDs.has(row.itemId));
    if (arr === undefined || arr.length === 0) valids.receiveList = false;

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  useEffect(() => {
    api_getEmployees();

    // prepare the array for updating list of received items
    if (receiveSummary.length > 0) {
      setReceiveList(
        receiveSummary.map((item) => ({
          itemId: item.id,
          recvQty: 0,
          returnQty: 0,
        }))
      );
    }
  }, [receiveSummary]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Form penerimaan barang
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Silahkan masukkan detail penerimaan barang <br /> <br />
              <strong>Tanggal terima</strong>: &nbsp;&nbsp;{" "}
              {format(recvDate, "dd-MMM-yyyy, HH:mm")}
            </Typography>
          </DialogContentText>
          <TextField
            variant="outlined"
            margin="dense"
            id="noSuratJalan"
            label="Nomor surat jalan vendor"
            value={vendorDocNumber}
            onChange={(e) => setVendorDocNumber(e.target.value)}
            fullWidth
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="courierName"
            label="Nama kurir"
            value={vendorCourier}
            onChange={(e) => setVendorCourier(e.target.value)}
            fullWidth
            required
            error={!fieldsValid.vendorCourier}
            helperText={
              !fieldsValid.vendorCourier ? "Field ini tidak boleh kosong" : null
            }
          />
          <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Nomor polisi kendaraan kurir"
            id="select-length"
            value={vendorCarNumber}
            onChange={(e) => setVendorCarNumber(e.target.value)}
          >
            {/* vendor.vehiclesNo */}
            {carNumbers.map((name) => (
              <MenuItem value={name}>{name}</MenuItem>
            ))}
          </TextField>
          <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Nama penerima (karyawan Mulia Box)"
            id="select-length"
            value={recipientId}
            onChange={(e) => setRecipientId(e.target.value)}
            required
            error={!fieldsValid.recipientId}
            helperText={
              !fieldsValid.recipientId ? "Field ini tidak boleh kosong" : null
            }
          >
            {employees.map(({ id, fullName }) => (
              <MenuItem value={id}>{fullName}</MenuItem>
            ))}
          </TextField>
          <Typography
            style={{ marginTop: 16, marginBottom: 8, fontWeight: "bold" }}
          >
            List barang untuk diterima *
          </Typography>
          {fieldsValid.receiveList ? null : (
            <Typography style={{ color: "red" }}>
              Silahkan mengisi list barang yang diterima dan jumlahnya
            </Typography>
          )}
          <div style={{ height: 300, width: "100%", marginTop: 16 }}>
            <DataGrid
              rows={receiveSummary.filter(
                (item) => item.qty > item.totalRecv + item.totalReturn
              )}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              rowHeight={35}
              checkboxSelection
              // isRowSelectable={(params) =>
              //   params.row.qty > params.row.totalRecv + params.row.totalReturn
              // }
              disableSelectionOnClick
              disableColumnFilter
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              selectionModel={selectionModel}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={api_createReceive}
          >
            Terima barang
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default FormTerimaBarang;
