import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "black";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    // fontWeight: "bold",
    color: "white",
  },
  size: {
    width: "20%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  substance: {
    width: "40%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  flute: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  qty: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  price: {
    width: "20%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  amount: {
    width: "15%",
  },
});

const POTableBlankRow = ({ rowsCount }) => {
  const blankRows = Array(rowsCount).fill(0);
  const rows = blankRows.map((x, i) => (
    <View style={styles.row} key={`BR${i}`}>
      <Text style={styles.size}></Text>
      <Text style={styles.substance}></Text>
      <Text style={styles.flute}></Text>
      <Text style={styles.qty}></Text>
      <Text style={styles.price}></Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default POTableBlankRow;
