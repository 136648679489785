import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    display: "flex",
    marginTop: 6,
    justifyContent: "space-between",
  },
  sectionTitle: {
    // color: "#C47335",
    fontWeight: "bold",
    marginBottom: 2,
  },
  gridContainer: {
    flexDirection: "row",
    display: "flex",
  },
});

const FakturBillTo = ({ custPic, custCompany, custAddress, paymentTerm }) => (
  <View style={styles.titleContainer}>
    <View>
      <Text style={styles.sectionTitle}>## Pengiriman untuk ##</Text>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Nama</Text>
        <Text>: {custPic}</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Perusahaan</Text>
        <Text>: {custCompany}</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Alamat</Text>
        <Text>: {custAddress}</Text>
      </View>
    </View>
    {/* <View>
      <Text style={styles.sectionTitle}>Dikirim dan ditagihkan ke</Text>
      <Text>{purchaser}</Text>
      <Text>Jalan Radio Palasari 102, Bandung</Text>
      <Text>Telp: (022) 5202922 / 0811237540</Text>
    </View> */}
  </View>
);

export default FakturBillTo;
