import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import FkTableHeader from "./FkTableHeader";
import FkTableRow from "./FkTableRow";
// import FkTableBlankRow from "./FkTableBlankRow";
import FkTableFooter from "./FkTableFooter";

// const tableRowsCount = 10;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    borderColor: "black",
  },
});

const POTable = ({
  items,
  totalPrice,
  otherFee,
  otherFeeName,
  deliveryFee,
  downPayment,
}) => (
  <View style={styles.tableContainer}>
    <FkTableHeader />
    {items.map((item) => (
      <FkTableRow item={item} />
    ))}
    {/* <FkTableBlankRow rowsCount={tableRowsCount - items.length} /> */}
    <FkTableFooter
      items={items}
      totalPrice={totalPrice}
      otherFee={otherFee}
      otherFeeName={otherFeeName}
      deliveryFee={deliveryFee}
      downPayment={downPayment}
    />

    {/* <InvoiceTableRow items={invoice.items} />
        <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
        <InvoiceTableFooter items={invoice.items} /> */}
  </View>
);

export default POTable;
