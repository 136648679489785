import React from "react";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    display: "flex",
    marginTop: 6,
    justifyContent: "space-between",
  },
  sectionTitle: {
    // color: "#C47335",
    fontWeight: "bold",
    marginBottom: 2,
  },
  gridContainer: {
    flexDirection: "row",
    display: "flex",
  },
});

const POBillTo = ({ vendor, vendorAddress, paymentTerm, purchaser, npwp }) => (
  <View style={styles.titleContainer}>
    <View>
      <Text style={styles.sectionTitle}>## Vendor ##</Text>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Nama</Text>
        <Text>: {vendor}</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}>Alamat vendor</Text>
        <Text>: {vendorAddress}</Text>
      </View>

      <View style={styles.gridContainer}>
        <Text style={{ width: 120, fontWeight: "bold" }}>Term pembayaran</Text>
        <Text style={{ fontWeight: "bold" }}>: {paymentTerm}</Text>
      </View>
    </View>
    <View>
      <Text style={styles.sectionTitle}>## Dikirim dan ditagihkan ke ##</Text>
      <View style={styles.gridContainer}>
        <Text style={{ width: 60 }}>Nama</Text>
        <Text>: {purchaser}</Text>
      </View>
      {/* <View style={styles.gridContainer}>
        <Text style={{ width: 60 }}>No. NPWP</Text>
        <Text>: {npwp}</Text>
      </View> */}
      <View style={styles.gridContainer}>
        <Text style={{ width: 60 }}>Alamat</Text>
        <Text>: Jalan Radio Palasari 102, Bandung</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 60 }}>Telp</Text>
        <Text>: (022) 5202922 / 0811237540</Text>
      </View>
    </View>
  </View>
);

export default POBillTo;
