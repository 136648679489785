import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "black";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    height: 16,
    // fontSize: 12,
    fontWeight: "bold",
  },
  rowNaked: {
    flexDirection: "row",
    alignItems: "center",
    height: 16,
    // fontSize: 12,
    fontWeight: "bold",
  },
  rowTop: {
    flexDirection: "row",
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderTopColor: borderColor,
    borderTopWidth: 1,
    alignItems: "center",
    height: 16,
    // fontSize: 12,
    fontWeight: "bold",
  },
  description: {
    width: "83%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
  },
  total: {
    width: "17%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const FkTableFooter = ({
  items,
  totalPrice,
  otherFee,
  otherFeeName,
  deliveryFee,
  downPayment,
}) => {
  // const total = items
  //   .map((item) => item.qty * item.price)
  //   .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return (
    <View>
      <View style={styles.rowTop}>
        <Text style={styles.description}>Biaya pengiriman</Text>
        <Text style={styles.total}>{deliveryFee}</Text>
      </View>
      <View style={styles.rowNaked}>
        <Text style={styles.description}>Biaya lain-lain</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>{otherFeeName}</Text>
        <Text style={styles.total}>{otherFee}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>DP</Text>
        <Text style={styles.total}>(-) {downPayment}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>TOTAL</Text>
        <Text style={styles.total}>Rp {totalPrice}</Text>
        {/* <Text style={styles.total}>{Number.parseFloat(total).toFixed(2)}</Text> */}
      </View>
    </View>
  );
};

export default FkTableFooter;
