import React, { useState } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useNavigate } from "react-router-dom";

import VerifyToken from "../../utils/jwtUtil";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignInView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [loginResp, setLoginResp] = useState({ color: "", message: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // prepare the data
    var newData = new FormData();
    newData.append("email", email);
    newData.append("password", pwd);

    // set loading
    setLoading(true);

    // hit the API
    axios
      .post(process.env.REACT_APP_MBOXBE_URI + "/accounts/signin", newData)
      .then((res) => {
        setLoading(true);
        // console.log(res.data);
        localStorage.setItem("ASID", res.data.accTkn);

        var { valid, payload } = VerifyToken(res.data.accTkn);
        // return token == null ? false : token === "null" ? false : true;
        console.log({ payload });
        // navigate("/app/sales/daftarjual");

        if (payload.role === "owner") navigate("/app/sales/daftarjual");
        else if (payload.role === "admin") navigate("/app/sales/daftarjual");
        else if (payload.role === "prod")
          navigate("/app/production/daftarproduksi");
      })
      .catch((err) => {
        setLoading(false);
        const { status } = err.response;
        switch (status) {
          case 400:
            setLoginResp({
              color: "info",
              message:
                "Sorry, we are having some problem, please try again later.",
            });
            break;
          case 401:
            setLoginResp({
              color: "error",
              message: "Wrong username or password, please try again.",
            });
            break;
          default:
            setLoginResp({
              color: "info",
              message:
                "Sorry, we are having some problem, please try again later.",
            });
            break;
        }
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Login ke akun Anda
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    color="primary"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {loginResp.message === "" ? null : (
            <Alert severity={loginResp.color}>{loginResp.message}</Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Login
          </Button>
          {loading ? (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : null}
          {/* <Grid container justifyContent="center">
            <Grid item>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
    </Container>
  );
};

export default SignInView;
