import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 16,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "center",
  },
  gridContainer: {
    flexDirection: "row",
    display: "flex",
  },
});

const FakturFooter = ({ custPkp, custCompany }) => (
  <View style={styles.titleContainer}>
    {/* <Text style={styles.reportTitle}>Thank you for your business</Text> */}
    <View style={styles.gridContainer}>
      <View style={{ width: "25%" }}>
        <Text>Tertanda,</Text>
        <Text style={{ marginTop: 36 }}>Mulia Box</Text>
      </View>
      <View style={{ width: "25%" }}>
        <Text>Tertanda,</Text>
        <Text style={{ marginTop: 36 }}>{custCompany}</Text>
      </View>
      <View style={{ width: "50%", marginLeft: 6 }}>
        <Text>Pembayaran bisa dilakukan melalui transfer ke</Text>
        <View>
          {custPkp ? (
            <Text>BCA (676-575-0222) a.n. MULIA CV</Text>
          ) : (
            <Text>BCA (063-575-0222) a.n. MULIA MITRA BOXINDO PT</Text>
          )}
        </View>
        <Text style={{ marginTop: 10 }}>
          * Dokumen ini dicetak otomatis oleh sistem
        </Text>
      </View>
    </View>
    {/* <Text style={{ marginTop: 20 }}>
      Pembayaran bisa dilakukan melalui transfer ke
    </Text>
    <View>
      {custPkp ? (
        <Text>1) BCA (6765750222) a.n. MULIA CV</Text>
      ) : (
        <Text>
          1) BCA (8100236533) a.n. MARTHA MEILITA{"\n"}
          2) OCBC NISP (70800001284) a.n. MARTHA MEILITA ATAU CHRISTINA
        </Text>
      )}
    </View> */}
  </View>
);

export default FakturFooter;
