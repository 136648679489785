import React from "react";
import { Page, Document, StyleSheet, Font } from "@react-pdf/renderer";

import FakturHeader from "./FakturHeader";
import FakturBillTo from "./FakturBillTo";
import FakturTable from "./FakturTable";
import FakturFooter from "./FakturFooter";

// import fontConsolas from "../../../../constants/Consolas-Font/CONSOLA.TTF";
// import fontConsolasBold from "../../../../constants/Consolas-Font/CONSOLAB.TTF";
// import fontFakeReceipt from "../../../../constants/fake_receipt-Font/fakereceipt.ttf";
import fontSaxMono from "../../../../constants/saxmono-Font/saxmono.ttf";
// import fontEpson1 from "../../../../constants/epson-Font/Epson1/Epson1.ttf";
// import fontEpson2 from "../../../../constants/epson-Font/Epson2/Epson2.ttf";

// Epson LPX 310 - HSD, Draft, Roman, Sans Serif
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

// Font.register({
//   family: "Consolas",
//   fonts: [{ src: fontConsolas }, { src: fontConsolasBold, fontWeight: 600 }],
// });

// Font.register({
//   family: "FakeReceipt",
//   fonts: [{ src: fontFakeReceipt }],
// });

Font.register({
  family: "SaxMono",
  fonts: [{ src: fontSaxMono }],
});

// Font.register({
//   family: "Epson1",
//   fonts: [{ src: fontEpson1 }],
// });

// Font.register({
//   family: "Epson2",
//   fonts: [{ src: fontEpson2 }],
// });

const styles = StyleSheet.create({
  page: {
    fontFamily: "SaxMono",
    fontSize: 9,
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 1.5,
    flexDirection: "column",
  },
});

const FakturMain = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <FakturHeader
        salesNo={data && data.salesNo ? data.salesNo : ""}
        salesDate={data && data.salesDate ? data.salesDate : ""}
        custPONo={data && data.custPONo ? data.custPONo : ""}
      />
      <FakturBillTo
        custPic={data && data.custPic ? data.custPic : ""}
        custCompany={data && data.custCompany ? data.custCompany : ""}
        custAddress={data && data.custAddress ? data.custAddress : ""}
        paymentTerm={data && data.paymentTerm ? data.paymentTerm : ""}
        notes={data && data.notes ? data.notes : ""}
        deliveryDocNumbers={
          data && data.deliveryDocNumbers ? data.deliveryDocNumbers : []
        }
      />
      <FakturTable
        items={data && data.items && data.items.length > 0 ? data.items : []}
        totalPrice={data && data.totalPrice ? data.totalPrice : 0}
        otherFee={data && data.otherFee ? data.otherFee : 0}
        otherFeeName={data && data.otherFeeName ? data.otherFeeName : " "}
        deliveryFee={data && data.deliveryFee ? data.deliveryFee : 0}
        downPayment={data && data.downPayment ? data.downPayment : 0}
      />
      <FakturFooter
        custPkp={data && data.custPkp ? data.custPkp : ""}
        custCompany={data && data.custCompany ? data.custCompany : ""}
      />
    </Page>
  </Document>
);

export default FakturMain;
