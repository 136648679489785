import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

import CloseIcon from "@material-ui/icons/Close";

import { indonesianCities } from "../../../../constants";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";
import ListFavoriteBox from "./ListFavoriteBox";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const FormCustomer = ({
  isAdd,
  open,
  customerId,
  handleClose,
  handleRefresh,
}) => {
  const classes = useStyles();

  const [companyName, setCompanyName] = useState("");
  const [picName, setPicName] = useState("");
  const [address, setAddress] = useState("");
  const [industry, setIndustry] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [pkp, setPkp] = useState(false);
  const [phone, setPhone] = useState("");
  const [fieldsValid, setFieldsValid] = useState({
    companyName: true,
    address: true,
    phone: true,
    pkp: true,
  });
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const api_getCustomerDetail = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/customers/" + customerId, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setCompanyName(res.data.companyName);
        setPicName(res.data.picName);
        setAddress(res.data.address);
        setPhone(res.data.phone);
        setIndustry(res.data.industry);
        setProvince(res.data.province);
        setCity(res.data.city);
        setPkp(res.data.pkp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_createCustomer = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    // newData.append("salesDate", salesDate.toISOString());
    newData.append("companyName", companyName);
    newData.append("picName", picName);
    newData.append("address", address);
    newData.append("phone", phone);
    newData.append("industry", industry);
    newData.append("province", province);
    newData.append("city", city);
    newData.append("pkp", pkp);

    // hit the API
    axios
      .post(process.env.REACT_APP_MBOXBE_URI + "/customers", newData, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Informasi customer berhasil ditambahkan",
        });
        setCompanyName("");
        setPicName("");
        setAddress("");
        setPhone("");
        setIndustry("");
        setProvince("");
        setCity("");
        setPkp("");

        handleRefresh(res.data.customers);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_updateCustomer = () => {
    // validate form
    if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("companyName", companyName);
    newData.append("picName", picName);
    newData.append("address", address);
    newData.append("phone", phone);
    newData.append("industry", industry);
    newData.append("province", province);
    newData.append("city", city);
    newData.append("pkp", pkp);

    // hit the API
    axios
      .put(
        process.env.REACT_APP_MBOXBE_URI + "/customers/" + customerId,
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSnackbarOpen({
          open: true,
          message: "Perubahan informasi customer berhasil disimpan",
        });
        setCompanyName("");
        setPicName("");
        setAddress("");
        setPhone("");
        setIndustry("");
        setProvince("");
        setCity("");
        setPkp("");

        handleRefresh();
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateForm = () => {
    // validate each fields
    var valids = {
      companyName: companyName !== "",
      address: address !== "",
      phone: phone !== "",
      pkp: pkp != null,
    };

    // check if form can be submitted (1 invalid field = invalid form)
    var allValid = true;
    for (const [key, value] of Object.entries(valids)) {
      if (value === false) {
        allValid = false;
        break;
      }
    }

    // take action
    setFieldsValid(valids);
    return allValid;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  // componentDidMount
  useEffect(() => {
    if (!isAdd && customerId) api_getCustomerDetail();
  }, [customerId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth={isAdd ? "sm" : "md"}
        fullWidth
        // fullScreen={!isAdd}
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {isAdd ? "Tambah Customer Baru" : "Edit Customer"}
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Typography className={classes.formSubsection}>
            Informasi Customer
          </Typography>
          <TextField
            variant="outlined"
            margin="dense"
            id="companyName"
            label="Nama perusahaan customer"
            value={companyName}
            fullWidth
            onChange={(e) => setCompanyName(e.target.value)}
            required
            error={!fieldsValid.companyName}
            helperText={
              !fieldsValid.companyName ? "Field ini tidak boleh kosong" : null
            }
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="picName"
            label="Nama PIC customer"
            value={picName}
            fullWidth
            onChange={(e) => setPicName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="dense"
            id="address"
            label="Alamat customer"
            value={address}
            fullWidth
            onChange={(e) => setAddress(e.target.value)}
            required
            error={!fieldsValid.address}
            helperText={
              !fieldsValid.address ? "Field ini tidak boleh kosong" : null
            }
          />
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                select
                variant="outlined"
                margin="dense"
                id="province"
                label="Provinsi"
                value={province}
                fullWidth
                onChange={(e) => setProvince(e.target.value)}
              >
                {Object.keys(indonesianCities).map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs>
              <TextField
                select
                variant="outlined"
                margin="dense"
                id="city"
                label="Kota"
                value={city}
                fullWidth
                onChange={(e) => setCity(e.target.value)}
              >
                {province === "" || province == null || province === "null" ? (
                  <MenuItem disabled>Silahkan pilih provinsi</MenuItem>
                ) : (
                  indonesianCities[province].map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))
                )}
              </TextField>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            margin="dense"
            id="phone"
            label="No. telp customer"
            value={phone}
            fullWidth
            onChange={(e) => setPhone(e.target.value)}
            required
            error={!fieldsValid.phone}
            helperText={
              !fieldsValid.phone ? "Field ini tidak boleh kosong" : null
            }
          />
          <TextField
            select
            variant="outlined"
            margin="dense"
            id="pkp"
            label="PKP"
            value={pkp}
            fullWidth
            onChange={(e) => setPkp(e.target.value)}
            required
            error={!fieldsValid.pkp}
            helperText={
              !fieldsValid.pkp ? "Field ini tidak boleh kosong" : null
            }
          >
            {[
              { key: true, val: "Ya" },
              { key: false, val: "Tidak" },
            ].map((item) => (
              <MenuItem value={item.key}>{item.val}</MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            margin="dense"
            id="industry"
            label="Industri"
            value={industry}
            fullWidth
            onChange={(e) => setIndustry(e.target.value)}
          />
          <div style={{ marginBottom: 8 }} />
          {!isAdd && customerId ? (
            <ListFavoriteBox custId={customerId} />
          ) : null}
          {/* <TextField
            select
            variant="outlined"
            margin="dense"
            id="industry"
            label="Industri"
            value={industry}
            fullWidth
            onChange={(e) => setIndustry(e.target.value)}
          >
            {custIndustriesConst.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </TextField> */}
          {/* <TextField
            select
            fullWidth
            variant="outlined"
            margin="dense"
            label="Term pembayaran"
            id="paymentTerm"
            value={paymentTerm}
            onChange={(e) => setPaymentTerm(e.target.value)}
          >
            {paymentTermsDropdown.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </TextField> */}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Tutup</Button>
          <Button
            onClick={isAdd ? api_createCustomer : api_updateCustomer}
            variant="contained"
            color="primary"
          >
            {isAdd ? "Tambah Customer" : "Simpan"}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default FormCustomer;
