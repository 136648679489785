// import indonesianCities from "./indonesianCities";
import indonesianCities from "./indonesianCities_2";

const fluteTypesConst = {
  "SW B": "BF",
  "SW C": "CF",
  "SW E": "EF",
  DW: "CBF",
};

const boxTypesConst = {
  reguler: "Reguler",
  pizza: "Pizza",
  mailer: "Mailer",
  gable: "Gable",
};

const paymentTermsConst = {
  "0D": "Langsung",
  "30D": "30 hari",
  "45D": "45 hari",
};

const custIndustriesConst = ["Elektronik", "Musik", "Makanan", "Lain-lain"];

const educationsConst = ["SD", "SMP", "SMA", "SMK", "D3", "S1", "S2"];

export {
  fluteTypesConst,
  boxTypesConst,
  paymentTermsConst,
  custIndustriesConst,
  indonesianCities,
  educationsConst,
};
