const formatWithThousandSeparator = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

// receive string in "yyyy-mm-dd" number format
const formatDateForMboxInvoice = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const parsedDate = dateString.split("-");
  return (
    parsedDate[2] +
    " " +
    months[parseInt(parsedDate[1]) - 1] +
    " " +
    parsedDate[0]
  );
};

export { formatWithThousandSeparator, formatDateForMboxInvoice };
