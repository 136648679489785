import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import CloseIcon from "@material-ui/icons/Close";
import SnackbarAlert from "../../../../coreui/SnackbarAlert";
import RefreshButton from "../../../../coreui/RefreshButton";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x
  ${params.getValue(params.id, "width") || ""} x ${
    params.getValue(params.id, "height") || ""
  }`;
};

const TambahFavoriteBox = ({ open, custId, handleClose, handleRefresh }) => {
  const classes = useStyles();

  const [favoriteBoxes, setFavoriteBoxes] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const columns = [
    { field: "name", headerName: "Nama item", width: 160 },
    {
      field: "size",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 160 },
    { field: "fluteN", headerName: "Flute", width: 110 },
  ];
  const [snackbarOpen, setSnackbarOpen] = useState({
    open: false,
    message: "",
  });

  const api_getFavoriteBoxes = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/favorite_boxinventory?custId=" +
          custId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setFavoriteBoxes([]);
        setFavoriteBoxes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen({ open: false, message: "" });
  };

  const handleUseFavorite = () => {
    handleRefresh(selectionModel[0]);
    handleClose();
  };

  // componentDidMount
  useEffect(() => {
    if (custId) {
      api_getFavoriteBoxes();
    }
  }, [custId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <AppBar className={classes.dialogTitle} color="default">
          <Toolbar>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Tambah item penjualan favorit
            </Typography>
            <IconButton size="small" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText>
            Pilih satu item pesanan favorit customer, dan klik 'Gunakan'
          </DialogContentText>
          <Typography className={classes.formSubsection}>
            List pesanan favorit customer
            <RefreshButton
              style={{ marginLeft: 16 }}
              handleClick={api_getFavoriteBoxes}
            />
          </Typography>
          <div style={{ height: 300, width: "100%" }}>
            <DataGrid
              rows={favoriteBoxes}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[20]}
              rowHeight={35}
              // checkboxSelection
              disableColumnFilter
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Batal</Button>
          <Button
            variant="outlined"
            onClick={handleUseFavorite}
            color="primary"
          >
            Gunakan
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarAlert
        open={snackbarOpen.open}
        handleClose={handleCloseSnackbar}
        severity="success"
        message={snackbarOpen.message}
      />
    </div>
  );
};

export default TambahFavoriteBox;
