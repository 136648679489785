import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import TabelHistoriBaku from "./TabelHistoriBaku";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  toggleButton: {
    color: theme.palette.primary.main,
    textTransform: "lowercase",
  },
}));

const HistoriBahanBakuView = () => {
  const classes = useStyles();

  const [filter, setFilter] = useState("semua");

  const handleChangeFilter = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <div>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Histori perubahan bahan baku
        </Typography>
        <Button color="primary" onClick={() => handleChangeFilter("semua")}>
          Semua
        </Button>
        <Button color="primary" onClick={() => handleChangeFilter("masuk")}>
          Masuk
        </Button>
        <Button color="primary" onClick={() => handleChangeFilter("keluar")}>
          Keluar
        </Button>
      </div>

      <TabelHistoriBaku />
    </div>
  );
};

export default HistoriBahanBakuView;
