import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import CloseIcon from "@material-ui/icons/Close";
import CallReceivedIcon from "@material-ui/icons/CallReceived";

import ListTerimaBarang from "./ListTerimaBarang";
import FormTerimaBarang from "./FormTerimaBarang";
import HistoriTerimaBarang from "./HistoriTerimaBarang";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 240,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogTitle: {
    position: "relative",
    marginBottom: 8,
  },
  formSubsection: {
    fontWeight: "bold",
    marginTop: 8,
    marginBottom: 8,
    color: theme.palette.primary.main,
  },
}));

const TerimaBarang = ({ open, handleClose, purchaseId }) => {
  const classes = useStyles();

  const [purchaseNo, setPurchaseNo] = useState("");
  const [vendor, setVendor] = useState({
    companyName: "",
    address: "",
    carNumbers: "",
  });
  const [receiveSummary, setReceiveSummary] = useState([]);
  const [receiveHistory, setReceiveHistory] = useState([]);

  const [openAdd, setOpenAdd] = useState(false);

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const api_getReceives = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/purchases/" +
          purchaseId +
          "/receives",
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        // setReceiveSummary([]); // force re-render here
        setReceiveSummary(res.data.summary);
        setReceiveHistory(res.data.history);
        setVendor(res.data.vendor);
        setPurchaseNo(res.data.purchaseNo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    if (purchaseId) api_getReceives();
  }, [purchaseId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullScreen
      // maxWidth="lg"
      // fullWidth
    >
      <AppBar className={classes.dialogTitle} color="default">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Status penerimaan barang
          </Typography>
          <IconButton size="small" color="inherit" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DialogContentText>
          <Grid container spacing={1}>
            {[
              { f: "Nomor PO", v: purchaseNo },
              { f: "Nama vendor", v: vendor.companyName },
            ].map(({ f, v }) => (
              <>
                <Grid item xs={3}>
                  <strong>{f}</strong>
                </Grid>
                <Grid item xs={9}>
                  {v}
                </Grid>
              </>
            ))}
          </Grid>
        </DialogContentText>
        <div className={classes.flexCenter}>
          <Typography
            className={classes.formSubsection}
            style={{ flexGrow: 1 }}
          >
            Daftar barang
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CallReceivedIcon />}
            onClick={handleOpenAdd}
          >
            Terima barang
          </Button>
        </div>

        <FormTerimaBarang
          open={openAdd}
          handleClose={handleCloseAdd}
          handleRefresh={api_getReceives}
          carNumbers={vendor.carNumbers ? vendor.carNumbers.split(",") : []}
          receiveSummary={receiveSummary}
          purchaseId={purchaseId}
        />
        <ListTerimaBarang receiveSummary={receiveSummary} />

        <Typography
          className={classes.formSubsection}
          style={{ marginTop: 24 }}
        >
          <strong>Daftar penerimaan barang</strong>
        </Typography>
        <HistoriTerimaBarang receiveHistory={receiveHistory} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Tutup</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TerimaBarang;
