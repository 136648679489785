import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import format from "date-fns/format";
import { DataGrid } from "@mui/x-data-grid";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import FormJual from "./FormJual";
import KirimBarang from "./KirimBarang";
import DialogNotifProduksi from "./DialogNotifProduksi";
import CetakFaktur from "./CetakFaktur";
import RefreshButton from "../../../coreui/RefreshButton";
import SummaryButton from "../../../coreui/SummaryButton";
import DialogHapusPenjualan from "./DialogHapusPenjualan";
import SummaryJual from "./SummaryJual";

const useStyles = makeStyles((theme) => ({
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const deliveryStatusDropdown = [
  { value: "all", text: "Semua" },
  { value: "half", text: "Sudah sebagian" },
  { value: "none", text: "Belum sama sekali" },
];

const TabelListJual = () => {
  const classes = useStyles();

  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [openSend, setOpenSend] = useState({ status: false, id: null });
  const [openNotifProd, setOpenNotifProd] = useState({
    status: false,
    id: null,
  });
  const [openPrintFaktur, setOpenPrintFaktur] = useState({
    status: false,
    id: null,
  });
  const [openDelete, setOpenDelete] = useState({ status: false, id: null });
  const [sales, setSales] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);

  var today = new Date();
  const [filterStartDate, setFilterStartDate] = useState(
    new Date().setDate(today.getDate() - 30)
  );
  const [filterEndDate, setFilterEndDate] = useState(today);
  const [filterDeliveryStatus, setFilterDeliveryStatus] = useState("all");
  const [filterQuery, setFilterQuery] = useState("");
  const [showSummary, setShowSummary] = useState(false);

  const columns = [
    {
      field: "salesDate",
      headerName: "Tanggal penjualan",
      width: 150,
      filterable: false,
      renderCell: ({ value }) => (
        <div>{format(new Date(value), "dd-MMM-yyyy")}</div>
      ),
    },
    { field: "salesNo", headerName: "No. penjualan", width: 170 },
    {
      field: "cust",
      headerName: "Nama customer",
      width: 200,
    },
    {
      field: "custPkp",
      headerName: "PKP",
      width: 110,
      filterable: false,
      renderCell: ({ row }) => <>{row.custPkp ? "*" : ""}</>,
    },
    {
      field: "deliveryStatus",
      headerName: "Status kirim",
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <strong
          style={{
            color:
              parseInt(row.delivQty) === parseInt(row.totalQty)
                ? "green"
                : parseInt(row.delivQty) > 0 &&
                  parseInt(row.delivQty) < parseInt(row.totalQty)
                ? "black"
                : "red",
          }}
        >
          {row.delivQty} / {row.totalQty}
        </strong>
      ),
    },
    {
      field: "notes",
      headerName: "Catatan",
      width: 130,
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <div>
            {value ? value.slice(0, 15) + (value.length > 15 ? "..." : "") : ""}
          </div>
        </Tooltip>
      ),
    },
    {
      field: "detail",
      headerName: " ",
      width: 100,
      renderCell: ({ row }) => (
        <Button color="primary" onClick={() => handleOpenEdit(row.id)}>
          Detail
        </Button>
      ),
    },
    {
      field: "produce",
      headerName: " ",
      width: 120,
      renderCell: ({ row }) => (
        <Button
          color="primary"
          onClick={() => handleOpenNotifProd(row.id)}
          disabled={row.notifiedProd || row.totalQty <= 0}
        >
          {row.notifiedProd
            ? "Sudah notifikasi"
            : row.totalQty > 0
            ? "Notifikasi produksi"
            : "-"}
        </Button>
      ),
    },
    {
      field: "send",
      headerName: " ",
      width: 100,
      renderCell: ({ row }) => (
        <Button color="primary" onClick={() => handleOpenSend(row.id)}>
          Pengiriman
        </Button>
      ),
    },
    {
      field: "print",
      headerName: " ",
      width: 100,
      renderCell: ({ row }) => (
        <Button color="primary" onClick={() => handleOpenPrintFaktur(row.id)}>
          Cetak faktur
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: " ",
      width: 50,
      renderCell: ({ row }) => (
        <IconButton
          size="small"
          // disabled={row.notifiedProd || row.delivQty > 0}
          disabled={!row.can_delete}
          onClick={() => handleOpenDelete(row.id)}
        >
          <Tooltip title="Hapus sales order">
            <DeleteIcon />
          </Tooltip>
        </IconButton>
      ),
    },
  ];

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const handleOpenSend = (id) => {
    setOpenSend({ status: true, id });
  };

  const handleCloseSend = () => {
    setOpenSend({ status: false, id: null });
  };

  const handleOpenNotifProd = (id) => {
    setOpenNotifProd({ status: true, id });
  };

  const handleCloseNotifProd = () => {
    setOpenNotifProd({ status: false, id: null });
  };

  const handleOpenPrintFaktur = (id) => {
    setOpenPrintFaktur({ status: true, id });
  };

  const handleClosePrintFaktur = () => {
    setOpenPrintFaktur({ status: false, id: null });
  };

  const handleOpenDelete = (id) => {
    setOpenDelete({ status: true, id });
  };

  const handleCloseDelete = () => {
    setOpenDelete({ status: false, id: null });
  };

  const handleRefreshAfterCreate = (newlyCreatedId) => {
    api_getSales();
    handleOpenEdit(newlyCreatedId);
  };

  const handleToggleShowSummary = () => {
    setShowSummary(!showSummary);
    console.log({ showSummary });
  };

  const api_getSales = () => {
    setLoadingTable(true);
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/sales?deliv=" +
          filterDeliveryStatus +
          "&startDate=" +
          format(filterStartDate, "yyyy-MM-dd") +
          "&endDate=" +
          format(filterEndDate, "yyyy-MM-dd") +
          "&q=" +
          filterQuery,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSales([]); // force re-render here
        setSales(res.data);
        setLoadingTable(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_exportToCsv = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI +
          "/sales_export_csv?startDate=" +
          format(filterStartDate, "yyyy-MM-dd") +
          "&endDate=" +
          format(filterEndDate, "yyyy-MM-dd"),
        {
          responseType: "blob",
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        console.log(res.headers);
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        var fileName = res.headers["content-disposition"].split("filename=")[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // componentDidMount
  useEffect(() => {
    api_getSales();
  }, []);

  return (
    <div style={{ height: "100%" }}>
      <div className={classes.flexCenter}>
        <Typography variant="h4" className={classes.pageTitle}>
          Daftar penjualan
          <RefreshButton
            style={{ marginLeft: 16 }}
            handleClick={api_getSales}
          />
          <SummaryButton
            style={{ marginLeft: 8 }}
            handleClick={handleToggleShowSummary}
          />
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<GetAppIcon />}
          style={{ marginRight: 8 }}
          onClick={api_exportToCsv}
        >
          Unduh data
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAdd}
        >
          Buat penjualan
        </Button>
      </div>

      {/* Summary section */}
      {showSummary ? <SummaryJual /> : null}

      {/* Filters section */}
      <Grid container style={{ marginBottom: 2 }} spacing={1}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item className={classes.flexCenter}>
            <DateRangeIcon style={{ marginRight: 6, color: "grey" }} />
            <DatePicker
              variant="inline"
              inputVariant="outlined"
              label="Dari tanggal"
              margin="dense"
              // label="Tanggal penjualan"
              value={filterStartDate}
              onChange={setFilterStartDate}
              animateYearScrolling
              format="dd-MMM-yyyy"
              // fullWidth
            />
          </Grid>
          <Grid item className={classes.flexCenter}>
            <DateRangeIcon style={{ marginRight: 6, color: "grey" }} />
            <DatePicker
              variant="inline"
              inputVariant="outlined"
              label="Sampai tanggal"
              margin="dense"
              // label="Tanggal penjualan"
              value={filterEndDate}
              onChange={setFilterEndDate}
              animateYearScrolling
              format="dd-MMM-yyyy"
              // fullWidth
            />
          </Grid>
          <Grid item className={classes.flexCenter}>
            <LocalShippingIcon style={{ marginRight: 6, color: "grey" }} />
            <TextField
              select
              // fullWidth
              style={{ minWidth: 200 }}
              variant="outlined"
              margin="dense"
              label="Status pengiriman"
              id="select-deliv-status"
              value={filterDeliveryStatus}
              onChange={(e) => setFilterDeliveryStatus(e.target.value)}
            >
              {deliveryStatusDropdown.map((item) => (
                <MenuItem value={item.value}>{item.text}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item className={classes.flexCenter}>
            <Button variant="outlined" color="primary" onClick={api_getSales}>
              Filter
            </Button>
          </Grid>
          <Grid item style={{ flexGrow: 1 }} />
          <Grid item>
            <TextField
              style={{ maxWidth: 350 }}
              variant="outlined"
              margin="dense"
              placeholder="Cari nama customer"
              id="searchbox"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filterQuery !== "" ? (
                      <IconButton
                        size="small"
                        onClick={() => setFilterQuery("")}
                      >
                        <CloseIcon />
                      </IconButton>
                    ) : null}
                    <IconButton size="small" onClick={api_getSales}>
                      <SearchIcon color="primary" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={filterQuery}
              onChange={(e) => setFilterQuery(e.target.value)}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>

      {/* Data grid / table section */}
      <div style={{ height: "80%", width: "100%" }}>
        <DataGrid
          rows={sales}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
          loading={loadingTable}
        />
      </div>
      <FormJual
        isAdd={true}
        open={openAdd}
        handleClose={handleCloseAdd}
        handleRefresh={handleRefreshAfterCreate}
      />
      <FormJual
        isAdd={false}
        open={openEdit.status}
        handleClose={handleCloseEdit}
        handleRefresh={api_getSales}
        salesId={openEdit.id}
      />
      <KirimBarang
        open={openSend.status}
        handleClose={handleCloseSend}
        salesId={openSend.id}
      />
      <DialogNotifProduksi
        open={openNotifProd.status}
        handleRefresh={api_getSales}
        handleClose={handleCloseNotifProd}
        salesId={openNotifProd.id}
      />
      <CetakFaktur
        open={openPrintFaktur.status}
        purchaseId={openPrintFaktur.id}
        handleClose={handleClosePrintFaktur}
      />
      <DialogHapusPenjualan
        open={openDelete.status}
        handleRefresh={api_getSales}
        handleClose={handleCloseDelete}
        salesId={openDelete.id}
      />
    </div>
  );
};

export default TabelListJual;
