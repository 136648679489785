import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { io } from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";

const drawerWidth = 500;

// const socket = io(process.env.REACT_APP_MBOX_SOCKETIO_URI);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    flexDirection: "column",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
    justifyContent: "flex-start",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  chatArea: {
    flexGrow: 1,
    overflowX: "auto",
  },
  bottomPush: {
    position: "relative",
    bottom: 0,
    padding: 12,
  },
}));

const CommentDrawer = ({
  open,
  handleDrawerOpen,
  handleCloseDrawer,
  jobId,
  itemName,
  boxSize,
  cust,
  userId,
}) => {
  const classes = useStyles();
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);

  const api_createComment = () => {
    // validate form
    // if (validateForm() === false) return;

    // prepare the data
    var newData = new FormData();
    newData.append("userId", userId);
    newData.append("msg", newComment);

    // hit the API
    axios
      .post(
        process.env.REACT_APP_MBOXBE_URI + "/jobs/" + jobId + "/comments",
        newData,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setNewComment("");

        // handleRefresh();
        // handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_getJobComments = () => {
    axios
      .get(process.env.REACT_APP_MBOXBE_URI + "/jobs/" + jobId + "/comments", {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
        // },
      })
      .then((res) => {
        // console.log(res.data);
        setComments([]); // force re-render here
        setComments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // // standard socket events
    // socket.on("connect", () => {
    //   console.log(socket.id); // x8WIv7-mJelg7on_ALbx
    // });
    // socket.on("disconnect", () => {
    //   console.log(socket.id); // undefined
    // });
    // socket.on("error", (error) => {
    //   console.log("can't connect");
    // });
    // // close the socket io
    // return () => socket.close();
  }, []);

  useEffect(() => {
    if (jobId) {
      api_getJobComments();

      // // custom socket events - for chatting inside jobs
      // socket.on("job_comments/" + jobId, (msg) => {
      //   // console.log({ msg });
      //   setComments((oldArray) => [...oldArray, msg]);
      // });
    }
  }, [jobId]);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleCloseDrawer} style={{ marginRight: 16 }}>
          <CloseIcon />
        </IconButton>
        <div>
          <Typography variant="h6" color="primary">
            Produksi {itemName} ({boxSize})
          </Typography>
          <Typography variant="subtitle1">{cust}</Typography>
        </div>
      </div>
      <Divider />
      <div
        // style={{
        //   flex: 1,
        //   flexGrow: 1,
        //   display: "flex",
        //   flexDirection: "column",
        //   overflowX: "auto",
        // }}
        className={classes.chatArea}
      >
        <List>
          {/* {productionComments.map(({ user, date, msg }) => ( */}
          {comments.map(({ user, createdAt, msg }) => (
            <ListItem button>
              <ListItemText
                primary={
                  <div className={classes.flexCenter}>
                    <Typography style={{ flexGrow: 1 }}>{user}</Typography>
                    <Typography component="div" variant="body2" color="primary">
                      {/* {format(date, "dd-MMM-yyyy, HH:mm")} */}
                      {format(new Date(createdAt), "dd-MMM-yyyy, HH:mm")}
                    </Typography>
                  </div>
                }
                secondary={msg}
              />
            </ListItem>
          ))}
        </List>
      </div>
      <div className={classes.bottomPush}>
        <TextField
          variant="outlined"
          placeholder="Tulis komentar di sini"
          multiline
          rows={3}
          fullWidth
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="send comments">
                  <SendIcon
                    color="primary"
                    size="small"
                    onClick={api_createComment}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </Drawer>
  );
};

export default CommentDrawer;
