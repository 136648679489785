import React from "react";
import format from "date-fns/format";
import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import logo from "../../../../logo.png";
import logoVertical from "../../../../logo-vertical.png";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    display: "flex",
    marginTop: 12,
    justifyContent: "space-between",
  },
  reportTitle: {
    // color: "#C47335",
    letterSpacing: 2,
    fontSize: 16,
    // marginBottom: 4,
    // textAlign: "center",
    // textTransform: "uppercase",
  },
  logoRectangle: {
    // marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 96,
    maxHeight: 32,
    marginBottom: 10,
  },
  logoSquare: {
    // marginLeft: "auto",
    // marginRight: "auto",
    maxWidth: 50,
    maxHeight: 50,
    marginRight: 10,
  },
  gridContainer: {
    flexDirection: "row",
    display: "flex",
  },
});

const POHeader = ({ purchaseNo, purchaseDate }) => (
  <View style={styles.titleContainer}>
    <View style={styles.gridContainer}>
      <Image style={styles.logoSquare} src={logoVertical} />
      <View>
        <Text style={{ fontWeight: "bold" }}>PT. Mulia Mitra Boxindo</Text>
        <Text>Corrugated Carton Box Industry</Text>
        <Text>Jalan Radio Palasari 102, Bandung</Text>
        <Text>Telp: (022) 5202922 / 0811237540</Text>
      </View>
    </View>
    <View>
      <Text style={styles.reportTitle}>Purchase Order</Text>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}> No. purchase order</Text>
        <Text>: {purchaseNo}</Text>
      </View>
      <View style={styles.gridContainer}>
        <Text style={{ width: 120 }}> Tanggal pemesanan</Text>
        <Text>
          : {purchaseDate ? format(new Date(purchaseDate), "dd MMM yyyy") : ""}
        </Text>
      </View>
    </View>
  </View>
);

export default POHeader;
