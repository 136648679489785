import React, { useEffect, useState } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import TambahProdukJual from "./TambahProdukJual";
import EditProdukJual from "./EditProdukJual";
import RefreshButton from "../../../../coreui/RefreshButton";

const formatSizeString = (params) => {
  return `${params.getValue(params.id, "length") || ""} x
  ${params.getValue(params.id, "width") || ""} x ${
    params.getValue(params.id, "height") || ""
  }`;
};

const formatTotalPrice = (params) => {
  var qty = Number(params.getValue(params.id, "qty"));
  var price = Number(params.getValue(params.id, "price"));
  var discount = Number(params.getValue(params.id, "discount"));
  return qty * price * (1 - discount / 100);
  // return (
  //   params.getValue(params.id, "qty") * params.getValue(params.id, "price")
  // );
};

const ListProdukJual = ({
  salesId,
  deliveryFee,
  includePPN,
  otherFee,
  custId,
}) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState({ status: false, id: null });
  const [salesItems, setSalesItems] = useState([]);

  const columns = [
    // { field: "customer", headerName: "Utk customer", width: 160 },
    { field: "name", headerName: "Nama item", width: 160 },
    {
      field: "size",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "paper", headerName: "Jenis kertas", width: 160 },
    { field: "fluteN", headerName: "Flute", width: 110 },
    { field: "qty", headerName: "Jumlah", type: "number", width: 120 },
    {
      field: "price",
      headerName: "Harga/unit",
      type: "number",
      width: 150,
      // valueFormatter: (params) => {
      //   return `Rp${formatWithThousandSeparator(params.value)}`;
      // },
      renderCell: ({ value }) => (
        <NumberFormat
          value={value}
          prefix={"Rp"}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      ),
    },
    {
      field: "discountRaw",
      headerName: "Diskon",
      width: 130,
      renderCell: ({ value }) => (
        <NumberFormat
          value={value}
          prefix={"Rp"}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      ),
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      type: "number",
      width: 130,
      valueGetter: formatTotalPrice,
      // valueFormatter: (params) => {
      //   return `Rp${formatWithThousandSeparator(params.value)}`;
      // },
      renderCell: ({ value }) => (
        <NumberFormat
          value={value}
          prefix={"Rp"}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
        />
      ),
    },
    {
      field: "actions",
      headerName: " ",
      width: 100,
      renderCell: (GridCellParams) => (
        <div>
          <Tooltip
            title="Edit item"
            placement="right"
            onClick={() => handleOpenEdit(GridCellParams.row.id)}
          >
            <IconButton>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Hapus item"
            placement="right"
            onClick={() => api_deleteSalesItem(GridCellParams.row.id)}
          >
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleOpenEdit = (id) => {
    setOpenEdit({ status: true, id });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: null });
  };

  const getTotalPayment = () => {
    var sum = 0;
    salesItems.forEach(({ qty, price, discount }) => {
      sum += Number(qty) * Number(price) * (1 - Number(discount) / 100);
    });
    sum = sum * (includePPN ? 1.11 : 1);
    sum = sum + Number(deliveryFee);
    sum = sum + Number(otherFee);
    return Math.trunc(sum);
  };

  const api_getSalesItems = () => {
    axios
      .get(
        process.env.REACT_APP_MBOXBE_URI + "/sales/" + salesId + "/details",
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setSalesItems([]);
        setSalesItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const api_deleteSalesItem = (salesItemId) => {
    axios
      .delete(
        process.env.REACT_APP_MBOXBE_URI + "/salesdetails/" + salesItemId,
        {
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem("ASID")}`,
          // },
        }
      )
      .then((res) => {
        // console.log(res.data);
        api_getSalesItems(); // handleRefresh
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (salesId) {
      api_getSalesItems();
    }
  }, [salesId]);

  return (
    <div>
      <NumberFormat
        variant="outlined"
        margin="dense"
        id="totalprice"
        label="Harga total"
        value={getTotalPayment()}
        customInput={TextField}
        prefix={"Rp"}
        type="text"
        thousandSeparator="."
        decimalSeparator=","
        fullWidth
      />
      <Typography style={{ marginTop: 16, fontWeight: "bold" }}>
        List penjualan
        <RefreshButton
          style={{ marginLeft: 16 }}
          handleClick={api_getSalesItems}
        />
        <Tooltip title="Tambah item" placement="right">
          <IconButton color="primary" onClick={handleOpenAdd}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <TambahProdukJual
        open={openAdd}
        salesId={salesId}
        custId={custId}
        handleClose={handleCloseAdd}
        handleRefresh={api_getSalesItems}
      />
      <EditProdukJual
        open={openEdit.status}
        salesItemId={openEdit.id}
        handleClose={handleCloseEdit}
        handleRefresh={api_getSalesItems}
      />
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          rows={salesItems}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          rowHeight={35}
          // checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
        />
      </div>
    </div>
  );
};

export default ListProdukJual;
