import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import Button from "@material-ui/core/Button";
import DetailHistoriBaku from "./DetailHistoriBaku";

import { historiMentah } from "../../../static/historiMentah";
const rows = historiMentah;

const formatSizeString = (params) => {
  // return params.getValue(params.id, "panjang");
  return `${params.getValue(params.id, "panjang") || ""} x ${
    params.getValue(params.id, "lebar") || ""
  }`;
};

const TabelHistoriBaku = () => {
  const columns = [
    {
      field: "inout",
      headerName: " ",
      width: 90,
      renderCell: ({ value }) => (
        <strong
          style={{
            color:
              value === "masuk"
                ? "green"
                : value === "keluar"
                ? "red"
                : "black",
          }}
        >
          {value}
        </strong>
      ),
    },
    { field: "date", headerName: "Tanggal", width: 130 },
    { field: "supplier", headerName: "Supplier", width: 130 },
    { field: "cust", headerName: "Customer", width: 140 },
    {
      field: "ukuran",
      headerName: "Ukuran",
      width: 130,
      valueGetter: formatSizeString,
      sortComparator: (v1, v2) => v1.toString().localeCompare(v2.toString()),
    },
    { field: "flute", headerName: "Flute", width: 120 },
    { field: "kertas", headerName: "Jenis kertas", width: 210 },
    { field: "qty", headerName: "Jumlah", type: "number", width: 130 },
    { field: "harga", headerName: "Harga/lembar", type: "number", width: 170 },
    {
      field: "detail",
      headerName: " ",
      width: 100,
      renderCell: (GridCellParams) => (
        <Button
          color="primary"
          onClick={() => handleOpenEdit(GridCellParams.row.inout)}
        >
          Detail
        </Button>
      ),
    },
  ];

  const [openEdit, setOpenEdit] = useState({ status: false, id: null });

  const handleOpenEdit = (id) => {
    setOpenEdit({
      status: true,
      id: id,
    });
  };

  const handleCloseEdit = () => {
    setOpenEdit({ status: false, id: openEdit.id });
  };

  return (
    <div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={20}
          rowsPerPageOptions={[20]}
          // checkboxSelection
          disableSelectionOnClick
        />
      </div>
      <DetailHistoriBaku
        id={openEdit.id}
        open={openEdit.status}
        handleClose={handleCloseEdit}
      />
    </div>
  );
};

export default TabelHistoriBaku;
