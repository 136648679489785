import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import TableListCustomer from "./TableListCustomer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

const ManageCustomerView = () => {
  const classes = useStyles();
  return <div className={classes.root}>{<TableListCustomer />}</div>;
};

export default ManageCustomerView;
